import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { ErrorCodes, useCheckPortInFeasibilityIIFixed, useModifyPhoneNumberFixedProduct } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyFixed.context";
export var usePortabilityFlow = function() {
    var ref = useCheckout(), portability = ref.portability, setPortability = ref.setPortability, portabilityStatus = ref.portabilityStatus, setPortabilityStatus = ref.setPortabilityStatus;
    var ref1 = useState(""), modifiedNumber = ref1[0], setModifiedNumber = ref1[1];
    var ref2 = useState(""), firstCode = ref2[0], setFirstCode = ref2[1];
    var ref3 = useState(""), firstOperator = ref3[0], setFirstOperator = ref3[1];
    var ref4 = useState(""), secondCode = ref4[0], setSecondCode = ref4[1];
    var ref5 = useState(""), secondOperator = ref5[0], setSecondOperator = ref5[1];
    var ref6 = useState(""), operators = ref6[0], setOperators = ref6[1];
    var isStartFinalPortIn = portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isStartFinalPortIn;
    var ref7 = useCheckPortInFeasibilityIIFixed(), mutatePortIn = ref7.mutate, checkPortInData = ref7.data, isErrorPortIn = ref7.isError, isLoadingPortIn = ref7.isLoading, isSuccessPortIn = ref7.isSuccess, resetPortIn = ref7.reset;
    var ref8 = useModifyPhoneNumberFixedProduct(), mutateModify = ref8.mutate, isErrorModify = ref8.isError, isSuccessModify = ref8.isSuccess;
    var checkIsValidRestart = function(portabilityObject) {
        var checkIsDifferent = function() {
            var portabilityMigration = portabilityObject.portabilityMigration;
            var isMultipleOperators = (portabilityObject === null || portabilityObject === void 0 ? void 0 : portabilityObject.portabilityOperators) === "double" || false;
            var ref = portabilityMigration || {}, fOperator = ref.firstOperator, fCode = ref.firstCode, sOperator = ref.secondOperator, sCode = ref.secondCode, noMigCode = ref.noCode;
            if (noMigCode) {
                if (isMultipleOperators) {
                    return firstOperator !== fOperator || secondOperator !== sOperator;
                }
                return firstOperator !== fOperator;
            }
            if (isMultipleOperators) {
                return firstOperator !== fOperator || secondOperator !== sOperator || firstCode !== fCode || secondCode !== sCode;
            }
            return firstOperator !== fOperator || firstCode !== fCode;
        };
        return checkIsDifferent();
    };
    useEffect(function() {
        if ((portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isStartPortIn) && ((portability === null || portability === void 0 ? void 0 : portability.portabilityNumber) !== modifiedNumber || (portability === null || portability === void 0 ? void 0 : portability.portabilityOperators) !== operators || checkIsValidRestart(portability))) {
            var ref;
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isStartPortIn: false,
                isLoadingCheck: true,
                isErrorCheck: false,
                isSuccessCheck: false
            }));
            setPortability(_object_spread({}, portability));
            mutatePortIn(_object_spread_props(_object_spread({}, portability), {
                isMultipleOperator: (portability === null || portability === void 0 ? void 0 : portability.portabilityOperators) === "double" || false,
                hasInternetService: (portability === null || portability === void 0 ? void 0 : (ref = portability.portabilityInfo) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "telint"
            }));
        }
    }, [
        portability,
        portabilityStatus
    ]);
    useEffect(function() {
        if (isSuccessPortIn && !isLoadingPortIn && !isErrorPortIn) {
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isSuccessCheck: true,
                isLoadingCheck: false,
                isErrorCheck: false
            }));
        }
    }, [
        isSuccessPortIn
    ]);
    useEffect(function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6;
        var dataPortIn = getFromLocalStorageByKey("checkPortInFeasibilityIIQuery");
        if ((isSuccessPortIn && checkPortInData || ((ref = dataPortIn.resultDescription) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "success") && dataPortIn && !isErrorPortIn && ((ref1 = dataPortIn) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.resultDescription) === null || ref2 === void 0 ? void 0 : ref2.toLowerCase()) !== "failure" && !((ref3 = dataPortIn) === null || ref3 === void 0 ? void 0 : ref3.error) && isStartFinalPortIn && !(portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isLoading) && !(portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isError) && !(portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isSuccess)) {
            var ref7;
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isLoadingCheck: false,
                isSuccessCheck: true,
                isLoading: true,
                isError: false,
                isSuccess: false
            }));
            mutateModify(_object_spread_props(_object_spread({}, portability), {
                isMultipleOperator: (portability === null || portability === void 0 ? void 0 : portability.portabilityOperators) === "double" || false,
                hasInternetService: (portability === null || portability === void 0 ? void 0 : (ref7 = portability.portabilityInfo) === null || ref7 === void 0 ? void 0 : ref7.toLowerCase()) === "telint"
            }));
        }
        if ((ref4 = dataPortIn) === null || ref4 === void 0 ? void 0 : ref4.error) {
            var ref8;
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isLoadingCheck: false,
                isSuccessCheck: false,
                isErrorCheck: true,
                errorMessage: (ref8 = dataPortIn) === null || ref8 === void 0 ? void 0 : ref8.errorMessage
            }));
            setPortability(_object_spread({}, portability));
        }
        if (isSuccessPortIn && (!dataPortIn || ((ref5 = dataPortIn) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.resultDescription) === null || ref6 === void 0 ? void 0 : ref6.toLowerCase()) === "failure")) {
            var ref9, ref10, ref11, ref12, ref13, ref14;
            resetPortIn();
            var errorText = "";
            if (((ref9 = dataPortIn) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.resultMessages) === null || ref10 === void 0 ? void 0 : (ref11 = ref10[0]) === null || ref11 === void 0 ? void 0 : ref11.messageCode) === "9000054") {
                errorText = ErrorCodes.MIGRATION_CODE_ERROR;
            } else if ((ref12 = dataPortIn) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.resultMessages) === null || ref13 === void 0 ? void 0 : (ref14 = ref13[0]) === null || ref14 === void 0 ? void 0 : ref14.messageDescription) {
                errorText = ErrorCodes.NUMBER_ERROR;
            } else {
                errorText = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
            }
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isLoadingCheck: false,
                isSuccessCheck: false,
                isErrorCheck: true,
                errorMessage: errorText
            }));
            setPortability(_object_spread({}, portability));
        }
    }, [
        isLoadingPortIn,
        isSuccessPortIn,
        isErrorPortIn,
        portabilityStatus
    ]);
    useEffect(function() {
        if (isSuccessModify) {
            var ref, ref1, ref2, ref3;
            setModifiedNumber(portability === null || portability === void 0 ? void 0 : portability.portabilityNumber);
            setFirstCode((portability === null || portability === void 0 ? void 0 : (ref = portability.portabilityMigration) === null || ref === void 0 ? void 0 : ref.firstCode) || "");
            setFirstOperator((portability === null || portability === void 0 ? void 0 : (ref1 = portability.portabilityMigration) === null || ref1 === void 0 ? void 0 : ref1.firstOperator) || "");
            setSecondCode((portability === null || portability === void 0 ? void 0 : (ref2 = portability.portabilityMigration) === null || ref2 === void 0 ? void 0 : ref2.secondCode) || "");
            setSecondOperator((portability === null || portability === void 0 ? void 0 : (ref3 = portability.portabilityMigration) === null || ref3 === void 0 ? void 0 : ref3.secondOperator) || "");
            setOperators((portability === null || portability === void 0 ? void 0 : portability.portabilityOperators) || "");
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isLoading: false,
                isSuccess: true,
                isError: false,
                errorMessage: ""
            }));
            setPortability(_object_spread({}, portability));
        }
        if (isErrorModify) {
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isLoading: false,
                isSuccess: false,
                isError: true,
                errorMessage: "modifyPhoneNumberProductQuery ERROR"
            }));
            setPortability(_object_spread({}, portability));
        }
    }, [
        isSuccessModify,
        isErrorModify
    ]);
    return null;
};
