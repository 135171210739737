import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
var LOCKIN_ERROR_CODES = {
    EMPTY_CAMPAIGNS_LIST: "EMPTY_CAMPAIGNS_LIST",
    NO_USEFUL_CAMPAIGNS_FOUND: "NO_USEFUL_CAMPAIGNS_FOUND",
    CAMPAIGNS_GENERIC_ERROR: "CAMPAIGNS_GENERIC_ERROR"
};
var checkCampaignEligibility = function(offeringServiceability, campaignsData) {
    var ref;
    if (campaignsData && campaignsData.length <= 0) {
        return {
            eligible: false,
            errorCode: LOCKIN_ERROR_CODES.EMPTY_CAMPAIGNS_LIST
        };
    }
    var serviceabilityIds = offeringServiceability === null || offeringServiceability === void 0 ? void 0 : (ref = offeringServiceability.offeringServiceabilityItem) === null || ref === void 0 ? void 0 : ref.map(function(item) {
        var ref;
        return item === null || item === void 0 ? void 0 : (ref = item.offering) === null || ref === void 0 ? void 0 : ref.id;
    });
    // FixedLine type filter campaignsOffer
    var fixedCampaignsList = (campaignsData === null || campaignsData === void 0 ? void 0 : campaignsData.filter(function(item) {
        var ref;
        return (item === null || item === void 0 ? void 0 : (ref = item.type) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "fixed";
    })) || [];
    if ((fixedCampaignsList === null || fixedCampaignsList === void 0 ? void 0 : fixedCampaignsList.length) === 0) {
        return {
            eligible: false,
            errorCode: LOCKIN_ERROR_CODES.EMPTY_CAMPAIGNS_LIST
        };
    }
    var usefulFixedCampaignsList = (fixedCampaignsList === null || fixedCampaignsList === void 0 ? void 0 : fixedCampaignsList.filter(function(item) {
        var ref;
        return serviceabilityIds.includes(item === null || item === void 0 ? void 0 : (ref = item.fixedBundle) === null || ref === void 0 ? void 0 : ref.productOfferId);
    })) || [];
    if ((usefulFixedCampaignsList === null || usefulFixedCampaignsList === void 0 ? void 0 : usefulFixedCampaignsList.length) === 0) {
        return {
            eligible: false,
            errorCode: LOCKIN_ERROR_CODES.EMPTY_CAMPAIGNS_LIST
        };
    }
    var lockInOffersFound = usefulFixedCampaignsList === null || usefulFixedCampaignsList === void 0 ? void 0 : usefulFixedCampaignsList.sort(function(item1, item2) {
        return parseInt(item2.score, 10) - parseInt(item1.score, 10);
    });
    if (Object.keys(lockInOffersFound).length !== 0) {
        return {
            eligible: true,
            lockInOffer: lockInOffersFound[0],
            errorCode: ""
        };
    }
    return {
        eligible: false,
        errorCode: LOCKIN_ERROR_CODES.NO_USEFUL_CAMPAIGNS_FOUND
    };
};
var getEligibilityProduct = function(allProducts, campaignsData) {
    var ref, ref1;
    var offeringServiceAbility = getFromLocalStorageByKey("offeringServiceability");
    var campaignEligibility = checkCampaignEligibility(offeringServiceAbility, campaignsData);
    var lockInProduct;
    if (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : (ref = campaignEligibility.lockInOffer) === null || ref === void 0 ? void 0 : (ref1 = ref.fixedBundle) === null || ref1 === void 0 ? void 0 : ref1.productOfferId) lockInProduct = allProducts.find(function(el) {
        var ref;
        return el.offerId.toString() == (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : (ref = campaignEligibility.lockInOffer) === null || ref === void 0 ? void 0 : ref.fixedBundle.productOfferId.toString());
    });
    return {
        campaignEligibility: campaignEligibility,
        lockInProduct: lockInProduct
    };
};
export { LOCKIN_ERROR_CODES, getEligibilityProduct };
