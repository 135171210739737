import { ECodeIdentification } from "@vfit/shared/models";
var getIdentificationMethod = function(identificationDetails) {
    var selectedMethod;
    if (Array.isArray(identificationDetails)) {
        selectedMethod = identificationDetails === null || identificationDetails === void 0 ? void 0 : identificationDetails.find(function(item) {
            return item.isSelected;
        });
    } else {
        selectedMethod = identificationDetails;
    }
    return (selectedMethod === null || selectedMethod === void 0 ? void 0 : selectedMethod.code) || ECodeIdentification.NA;
};
/**
 * A getter method to retrieve the delivery code based on the deliveryItems
 * @param deliveryItems
 * @returns
 */ var getDeliveryCode = function(deliveryItems) {
    var ref;
    if (deliveryItems === null || deliveryItems === void 0 ? void 0 : (ref = deliveryItems[0]) === null || ref === void 0 ? void 0 : ref.details) {
        var ref1, ref2;
        var objToFilter = deliveryItems === null || deliveryItems === void 0 ? void 0 : (ref1 = deliveryItems[0]) === null || ref1 === void 0 ? void 0 : ref1.details;
        var filteredItem = objToFilter === null || objToFilter === void 0 ? void 0 : objToFilter.filter(function(itemToFilter) {
            return (itemToFilter === null || itemToFilter === void 0 ? void 0 : itemToFilter.code) == "T" || (itemToFilter === null || itemToFilter === void 0 ? void 0 : itemToFilter.code) == "S";
        });
        return filteredItem === null || filteredItem === void 0 ? void 0 : (ref2 = filteredItem[0]) === null || ref2 === void 0 ? void 0 : ref2.code;
    }
    return "T";
};
export { getIdentificationMethod, getDeliveryCode };
