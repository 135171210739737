import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { COOKIE_TECH_APPOINTMENT, getProductCategory, LOGGED_USER_KEYS, retrieveCartCookie } from "@vfit/consumer/data-access";
import crypto from "crypto-es";
import { checkWindow, getFromLocalStorageByKey, setItemCookie } from "@vfit/shared/data-access";
var removeCurrency = function(currencyString) {
    return currencyString.replace(/[^0-9,-]+/g, "");
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var clearTextFromHtml = function(htmlText) {
    return htmlText === null || htmlText === void 0 ? void 0 : htmlText.replace(/<\/?[^>]+(>|$)/g, "");
};
/**
 * Method used to retrieve a number price in the format: nn.nn
 * @param text
 */ var retrievePriceFromText = function(text) {
    var price = "";
    var regex = /(\d+(\.\d{3})*,?\d*)\s*€/;
    var match = text === null || text === void 0 ? void 0 : text.match(regex);
    if (match && match[1]) {
        var numericPrice = parseFloat(match[1].replace(".", "").replace(",", "."));
        price = numericPrice.toFixed(2).toString();
    }
    return price;
};
/**
 * Method to return the products by organized products
 * @param queryClient
 * @param products
 */ var getOrganizedSoftProductsByProducts = function(queryClient, products) {
    var allProductIds = [];
    var allProductNames = [];
    var allProductPrices = [];
    var allProductCategory = [];
    var productQuantity = 0;
    if (products.length > 0) {
        productQuantity = products.length;
        products.forEach(function(prod) {
            allProductIds.push(prod.offerId);
            allProductNames.push(prod.slug);
            allProductPrices.push(removeCurrency(prod.price));
            allProductCategory.push(getProductCategory(prod === null || prod === void 0 ? void 0 : prod.offerType));
        });
    }
    return {
        product_id: allProductIds,
        product_name: allProductNames,
        product_price: allProductPrices,
        product_category: allProductCategory,
        product_quantity: productQuantity.toString()
    };
};
/**
 * Method to return the devices by organized devices
 * @param queryClient
 * @param devices
 */ var getOrganizedSoftDevicesByDevices = function(queryClient, devices) {
    var allDeviceIds = [];
    var allDeviceNames = [];
    var allDevicePrices = [];
    var allDeviceCategory = [];
    var deviceQuantity = 0;
    if (devices.length > 0) {
        deviceQuantity = devices.length;
        devices.forEach(function(device) {
            var ref;
            var clearedText = retrievePriceFromText(((ref = device.devicedetail) === null || ref === void 0 ? void 0 : ref.lowestPrice) || "");
            allDeviceIds.push(device.devicedetail.slug);
            allDeviceNames.push(device.devicedetail.deviceName);
            allDevicePrices.push(removeCurrency(clearedText));
            allDeviceCategory.push(device.devicedetail.category);
        });
    }
    return {
        product_id: allDeviceIds,
        product_name: allDeviceNames,
        product_price: allDevicePrices,
        product_category: allDeviceCategory,
        product_quantity: deviceQuantity.toString()
    };
};
/**
 * Method to return the user logged/not logged
 */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
var getUserInfo = function(queryClient) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37;
    var SHA256 = crypto.SHA256;
    var user = getFromLocalStorageByKey("user");
    var silentLogin = getFromLocalStorageByKey("silentLogin");
    var identifyEnrichement = getFromLocalStorageByKey("identify");
    var isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) || false;
    var customerData = getFromLocalStorageByKey("customerData");
    var appSessionFromStorage = checkWindow() && localStorage.getItem("appSession");
    var appSession = !!appSessionFromStorage && JSON.parse(appSessionFromStorage);
    var appMsisdn = appSession === null || appSession === void 0 ? void 0 : appSession.value;
    var visitorLoginStatus = appMsisdn || isLoggedUser ? "logged in" : "logged out";
    var visitorType = (localStorage.getItem("userType") || "").replace("anonymous", "");
    return _object_spread({
        visitor_login_type: "web"
    }, !!retrieveCartCookie() && {
        visitor_type: "returning visitor ".concat(visitorType).trim()
    }, (user === null || user === void 0 ? void 0 : (ref = user.customer) === null || ref === void 0 ? void 0 : ref[0].id) && {
        visitor_customer_id: user === null || user === void 0 ? void 0 : user.customer[0].id
    }, (user === null || user === void 0 ? void 0 : (ref1 = user.taggedInfoCust) === null || ref1 === void 0 ? void 0 : ref1.email) && {
        visitor_id_email: SHA256(user === null || user === void 0 ? void 0 : (ref2 = user.taggedInfoCust) === null || ref2 === void 0 ? void 0 : ref2.email).toString()
    }, (user === null || user === void 0 ? void 0 : (ref3 = user.customer) === null || ref3 === void 0 ? void 0 : ref3[0].msisdns) && {
        visitor_id_asset_list: (ref5 = user === null || user === void 0 ? void 0 : (ref4 = user.customer) === null || ref4 === void 0 ? void 0 : ref4[0].msisdns) === null || ref5 === void 0 ? void 0 : ref5.map(function(msisdn) {
            var ref;
            return ((ref = SHA256(msisdn)) === null || ref === void 0 ? void 0 : ref.toString()) || "";
        })
    }, identifyEnrichement && identifyEnrichement.id && {
        visitor_id_asset_active: SHA256(identifyEnrichement.id).toString()
    }, appMsisdn && {
        visitor_id_asset_active: SHA256(appMsisdn).toString()
    }, visitorLoginStatus && {
        visitor_login_status: visitorLoginStatus
    }, customerData && ((ref6 = customerData[0]) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.owningIndividual) === null || ref7 === void 0 ? void 0 : ref7.birthDate) && {
        visitor_customer_dob: (ref8 = customerData[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.owningIndividual) === null || ref9 === void 0 ? void 0 : ref9.birthDate
    }, customerData && ((ref10 = customerData[0]) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.owningIndividual) === null || ref11 === void 0 ? void 0 : ref11.gender) && {
        visitor_customer_gender: (ref12 = customerData[0]) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.owningIndividual) === null || ref13 === void 0 ? void 0 : ref13.gender
    }, customerData && ((ref14 = customerData[0]) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.billingAddress) === null || ref15 === void 0 ? void 0 : ref15.city) && {
        visitor_customer_location_city: (ref16 = customerData[0]) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.billingAddress) === null || ref17 === void 0 ? void 0 : ref17.city
    }, customerData && ((ref18 = customerData[0]) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.owningIndividual) === null || ref19 === void 0 ? void 0 : ref19.nation) && {
        visitor_customer_location_country: (ref20 = customerData[0]) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.owningIndividual) === null || ref21 === void 0 ? void 0 : ref21.nation
    }, customerData && ((ref22 = customerData[0]) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.billingAddress) === null || ref23 === void 0 ? void 0 : ref23.postalCode) && {
        visitor_customer_location_id: (ref24 = customerData[0]) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.billingAddress) === null || ref25 === void 0 ? void 0 : ref25.postalCode
    }, customerData && ((ref26 = customerData[0]) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.owningIndividual) === null || ref27 === void 0 ? void 0 : ref27.firstName) && {
        visitor_customer_name_first: (ref28 = customerData[0]) === null || ref28 === void 0 ? void 0 : (ref29 = ref28.owningIndividual) === null || ref29 === void 0 ? void 0 : ref29.firstName
    }, customerData && ((ref30 = customerData[0]) === null || ref30 === void 0 ? void 0 : (ref31 = ref30.owningIndividual) === null || ref31 === void 0 ? void 0 : ref31.lastName) && {
        visitor_customer_name_last: (ref32 = customerData[0]) === null || ref32 === void 0 ? void 0 : (ref33 = ref32.owningIndividual) === null || ref33 === void 0 ? void 0 : ref33.lastName
    }, customerData && ((ref34 = customerData[0]) === null || ref34 === void 0 ? void 0 : (ref35 = ref34.billingAddress) === null || ref35 === void 0 ? void 0 : ref35.stateOrProvince) && {
        visitor_customer_region: (ref36 = customerData[0]) === null || ref36 === void 0 ? void 0 : (ref37 = ref36.billingAddress) === null || ref37 === void 0 ? void 0 : ref37.stateOrProvince
    });
};
var encryptData = function(value) {
    var SHA256 = crypto.SHA256;
    return SHA256(value).toString();
};
/**
 * Check if product is a promo prod
 * @Todo integrate logic from be
 * @param slug
 */ var getProductLabelPromo = function(slug) {
    // TODO: Check product promo integration
    var promoProds = [
        "internet-unlimited-special-edition"
    ];
    var labelToReturn = slug;
    if (slug && promoProds.includes(slug)) {
        labelToReturn += ":promo";
    }
    return labelToReturn;
};
/**
 * Method to return the PageProduct
 * @param product
 */ var getPageProductsInfo = function(product) {
    return {
        product_name: (product === null || product === void 0 ? void 0 : product.slug) || "",
        product_price: (product === null || product === void 0 ? void 0 : product.price) ? removeCurrency(product === null || product === void 0 ? void 0 : product.price) : "",
        product_id: "".concat(product === null || product === void 0 ? void 0 : product.offerId),
        product_quantity: "1",
        product_category: [
            getProductCategory(product === null || product === void 0 ? void 0 : product.offerType)
        ]
    };
};
/**
 * Retrieve info for device product
 * @param devicedetail
 * @param selectedModel
 */ var getProductDeviceInfo = function(param, selectedModel) {
    var devicedetail = param.devicedetail;
    var ref;
    return {
        product_price: retrievePriceFromText((selectedModel === null || selectedModel === void 0 ? void 0 : selectedModel.price) || ""),
        product_quantity: (devicedetail === null || devicedetail === void 0 ? void 0 : (ref = devicedetail.models) === null || ref === void 0 ? void 0 : ref.length.toString()) || "",
        product_name: (devicedetail === null || devicedetail === void 0 ? void 0 : devicedetail.deviceName) || "",
        product_category: (devicedetail === null || devicedetail === void 0 ? void 0 : devicedetail.category) || "",
        product_id: (selectedModel === null || selectedModel === void 0 ? void 0 : selectedModel.sku) || ""
    };
};
var createTechAppointmentMockCookie = function(urlRedirect) {
    var cookie = {
        timestamp: Date.now(),
        technology: "MAKE",
        portability: "N",
        buildingId: "",
        contactPhoneNumber: "3491303527",
        customerFiscalCode: "XBSTTM92R05A045P",
        provinceOfInstallation: "MI",
        techAppointmentReminder: "Y",
        customer: {
            firstName: "testMAKEzero",
            lastName: "xbs",
            city: "Milano",
            displayStateOrProvince: "MI",
            note: "Citofono numero 2",
            postalCode: "20146",
            street: "Via Lorenteggio",
            streetNumber: "240"
        }
    };
    setItemCookie(COOKIE_TECH_APPOINTMENT, cookie, 0.021);
    if (urlRedirect) {
        window.location.href = urlRedirect;
    }
};
export { getOrganizedSoftProductsByProducts, getOrganizedSoftDevicesByDevices, getUserInfo, encryptData, getProductLabelPromo, getPageProductsInfo, getProductDeviceInfo, createTechAppointmentMockCookie };
