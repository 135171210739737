export var evaluatePortabilityLabels = function(labels) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15;
    return {
        title: labels === null || labels === void 0 ? void 0 : (ref = labels.portabilitymobile) === null || ref === void 0 ? void 0 : (ref1 = ref.migrationData) === null || ref1 === void 0 ? void 0 : ref1.title,
        description: labels === null || labels === void 0 ? void 0 : (ref2 = labels.portabilitymobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.migrationData) === null || ref3 === void 0 ? void 0 : ref3.description,
        modalTitle: labels === null || labels === void 0 ? void 0 : (ref4 = labels.portabilitymobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.migrationData) === null || ref5 === void 0 ? void 0 : ref5.modalTitle,
        modalDescription: labels === null || labels === void 0 ? void 0 : (ref6 = labels.portabilitymobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.migrationData) === null || ref7 === void 0 ? void 0 : ref7.modalDescription,
        checkboxText: labels === null || labels === void 0 ? void 0 : (ref8 = labels.portabilitymobile) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.migrationData) === null || ref9 === void 0 ? void 0 : ref9.checkboxText,
        operatorLabel: labels === null || labels === void 0 ? void 0 : (ref10 = labels.portabilitymobile) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.selectInput) === null || ref11 === void 0 ? void 0 : ref11.operator,
        contractLabel: labels === null || labels === void 0 ? void 0 : (ref12 = labels.portabilitymobile) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.selectInput) === null || ref13 === void 0 ? void 0 : ref13.contract,
        secondActionLabel: labels === null || labels === void 0 ? void 0 : (ref14 = labels.portabilitymobile) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.migrationData) === null || ref15 === void 0 ? void 0 : ref15.actionLabel
    };
};
