import LoadingOrder from "./components/LoadingOrder/loadingOrder";
import LoadingError from "./components/LoadingError/loadingError";
var CHECKOUT_FLOWS = [
    {
        title: "Waiting",
        component: LoadingOrder
    },
    {
        title: "Error",
        component: LoadingError
    },
    {
        title: ""
    }, 
];
export { CHECKOUT_FLOWS };
