var getEsimItem = function(orderItems) {
    var ref, ref1;
    if (!orderItems || !(orderItems === null || orderItems === void 0 ? void 0 : orderItems.simOptions) || (orderItems === null || orderItems === void 0 ? void 0 : (ref = orderItems.simOptions) === null || ref === void 0 ? void 0 : ref.length) === 0) return undefined;
    return orderItems === null || orderItems === void 0 ? void 0 : (ref1 = orderItems.simOptions) === null || ref1 === void 0 ? void 0 : ref1.find(function(simOption) {
        var ref, ref1;
        return (ref1 = simOption === null || simOption === void 0 ? void 0 : (ref = simOption.value) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === null || ref1 === void 0 ? void 0 : ref1.includes("esim");
    });
};
var isAlreadySelectedEsim = function(orderItems) {
    var ref;
    return (orderItems === null || orderItems === void 0 ? void 0 : (ref = orderItems.simOptions) === null || ref === void 0 ? void 0 : ref.some(function(el) {
        var ref, ref1;
        return el.selection && ((ref1 = (ref = el.name) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === null || ref1 === void 0 ? void 0 : ref1.includes("esim"));
    })) || false;
};
export { getEsimItem, isAlreadySelectedEsim };
