import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { CMS_CONFIG, ErrorCodes, errorMock, useCmsConfig, useGetCustomerReference } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { API, getFromLocalStorageByKey, openPopup, PAGES, trackView } from "@vfit/shared/data-access";
import { resetData } from "@vfit/shared/providers";
import { useEffect } from "react";
import { getShippingBillingForLoggedOrActive, getTrackError, retrieveOwningIndividual } from "../../checkout.utils";
import { useCheckout } from "../../../iBuyMobile.context";
export var usePostOtpFlow = function(props) {
    var ref, ref1, ref2, ref3;
    var handleOnClose = props.handleOnClose;
    var ref4 = useCheckout(), product = ref4.product, findCaller = ref4.findCaller, customerFlow = ref4.customerFlow, customerAsyncInfo = ref4.customerAsyncInfo, owningData = ref4.owningData, checkoutErrors = ref4.checkoutErrors, isModalButtonSticky = ref4.isModalButtonSticky, setBillingAddress = ref4.setBillingAddress, setShippingAddress = ref4.setShippingAddress, setIsDisabledBackButton = ref4.setIsDisabledBackButton, setCustomerId = ref4.setCustomerId, setOwningData = ref4.setOwningData, setIsStartCustomerFlow = ref4.setIsStartCustomerFlow, setFindCaller = ref4.setFindCaller;
    var queryClient = useQueryClient();
    var countries = getFromLocalStorageByKey("getCountry");
    var otpVerifyResponse = {
        id: findCaller.verifyOtp
    };
    var ref5 = useGetCustomerReference(), isReferenceSuccess = ref5.isSuccess, isReferenceLoading = ref5.isLoading, isReferenceError = ref5.isError, errorCustomerReference = ref5.error;
    var associateCustomer = customerFlow.associateCustomer;
    var genericError = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GENERIC_ERROR_MOBILE);
    var ref6 = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE) || {}, tmp = ref6.errorcomponent, errors = (tmp === void 0 ? {
        errors: []
    } : tmp).errors;
    var blockedCustomer = errors === null || errors === void 0 ? void 0 : errors.find(function(item) {
        return (item === null || item === void 0 ? void 0 : item.error) === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED;
    });
    var isLoadingCustomerInfo = isReferenceLoading || !isReferenceSuccess || !!(otpVerifyResponse === null || otpVerifyResponse === void 0 ? void 0 : otpVerifyResponse.id) && !associateCustomer.isSuccess || !!(otpVerifyResponse === null || otpVerifyResponse === void 0 ? void 0 : otpVerifyResponse.id) && !(customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.billingAccount) === null || ref === void 0 ? void 0 : ref.isSuccess) || (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref1 = customerAsyncInfo.customerInfo) === null || ref1 === void 0 ? void 0 : ref1.isLoading) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref2 = customerFlow.payment) === null || ref2 === void 0 ? void 0 : ref2.isLoading) || (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref3 = customerAsyncInfo.termsAndConditions) === null || ref3 === void 0 ? void 0 : ref3.isLoading);
    var preconditionCustomer = function() {
        if (associateCustomer.isSuccess || associateCustomer.isError || isReferenceError) {
            var ref, ref1, ref2, ref3, ref4, ref5;
            setFindCaller(_object_spread_props(_object_spread({}, findCaller, (customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.findcaller) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : ref1.paymean) && {
                paymean: customerFlow === null || customerFlow === void 0 ? void 0 : (ref2 = customerFlow.findcaller) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.data) === null || ref3 === void 0 ? void 0 : ref3.paymean
            }), {
                showOtp: false,
                verifyOtp: "",
                isVerified: true
            }));
            if (associateCustomer.isError && ((ref4 = associateCustomer.error) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.error) === null || ref5 === void 0 ? void 0 : ref5.statusText) === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED) {
                var ref6, ref7, ref8;
                if (associateCustomer.isError) {
                    trackView(getTrackError(associateCustomer.error));
                }
                var handleClose = function() {
                    if (handleOnClose) {
                        resetData(queryClient);
                        handleOnClose();
                    }
                };
                var goBackAndReset = function() {
                    var ref;
                    if (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref = blockedCustomer.button) === null || ref === void 0 ? void 0 : ref.urlForRedirect) {
                        var ref1;
                        openPopup((blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref1 = blockedCustomer.button) === null || ref1 === void 0 ? void 0 : ref1.urlForRedirect) || "", function() {
                            if (handleOnClose) {
                                resetData(queryClient);
                                handleOnClose();
                            }
                        });
                    } else if (handleOnClose) {
                        resetData(queryClient);
                        handleOnClose();
                    }
                };
                checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref8 = checkoutErrors.showAndTrackError) === null || ref8 === void 0 ? void 0 : ref8.call(checkoutErrors, genericError, function() {
                    return handleClose();
                }, undefined, (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : blockedCustomer.title) || "Attenzione", (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : blockedCustomer.message) || "I seguenti articoli non sono attivabili dal cliente selezionato", function() {
                    return goBackAndReset();
                }, (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref6 = blockedCustomer.button) === null || ref6 === void 0 ? void 0 : ref6.title) || "Ti richiamiamo", function() {
                    var ref;
                    return openPopup((product === null || product === void 0 ? void 0 : (ref = product.genericErrorCallMeNow) === null || ref === void 0 ? void 0 : ref.url) || "", handleClose);
                }, (product === null || product === void 0 ? void 0 : product.removeCTC) ? undefined : product === null || product === void 0 ? void 0 : (ref7 = product.genericErrorCallMeNow) === null || ref7 === void 0 ? void 0 : ref7.label, isModalButtonSticky);
            } else if (associateCustomer.isError || isReferenceError) {
                var ref9, ref10, ref11, ref12, ref13, ref14, ref15;
                if (isReferenceError && errorCustomerReference) trackView(getTrackError(errorMock(ErrorCodes.REFERENCE_CUSTOMER, errorCustomerReference)));
                if (associateCustomer.isError && (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref9 = customerAsyncInfo.customerInfo) === null || ref9 === void 0 ? void 0 : ref9.isError)) trackView(getTrackError(customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref10 = customerAsyncInfo.customerInfo) === null || ref10 === void 0 ? void 0 : ref10.error));
                checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref15 = checkoutErrors.showAndTrackError) === null || ref15 === void 0 ? void 0 : ref15.call(checkoutErrors, genericError, handleOnClose, associateCustomer.isError && (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref11 = customerAsyncInfo.customerInfo) === null || ref11 === void 0 ? void 0 : ref11.isError) ? customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref12 = customerAsyncInfo.customerInfo) === null || ref12 === void 0 ? void 0 : ref12.error : undefined, "", "", handleOnClose, (genericError === null || genericError === void 0 ? void 0 : (ref13 = genericError.genericerror) === null || ref13 === void 0 ? void 0 : ref13.buttonLabel) || "", function() {
                    var ref;
                    return openPopup((product === null || product === void 0 ? void 0 : (ref = product.genericErrorCallMeNow) === null || ref === void 0 ? void 0 : ref.url) || "", function() {
                        return handleOnClose;
                    });
                }, (product === null || product === void 0 ? void 0 : product.removeCTC) ? undefined : product === null || product === void 0 ? void 0 : (ref14 = product.genericErrorCallMeNow) === null || ref14 === void 0 ? void 0 : ref14.label, isModalButtonSticky);
            }
        }
    };
    useEffect(function() {
        preconditionCustomer();
        if (associateCustomer.isSuccess) {
            var ref;
            var customer = (ref = customerAsyncInfo.customerInfo.data) === null || ref === void 0 ? void 0 : ref[0];
            if (customer) {
                var retrivedIndividual = retrieveOwningIndividual(customer, countries);
                setOwningData(_object_spread_props(_object_spread({}, owningData), {
                    owningIndividual: _object_spread({}, owningData.owningIndividual, retrivedIndividual)
                }));
                setCustomerId(customer.id);
                var postalAddress = getShippingBillingForLoggedOrActive(customer);
                setShippingAddress(postalAddress);
                setBillingAddress(postalAddress);
            }
        }
    }, [
        associateCustomer,
        isReferenceError,
        customerAsyncInfo
    ]);
    useEffect(function() {
        setIsDisabledBackButton(isLoadingCustomerInfo);
    }, [
        isLoadingCustomerInfo
    ]);
    useEffect(function() {
        setFindCaller(_object_spread_props(_object_spread({}, findCaller), {
            showOtp: false
        }));
        setIsStartCustomerFlow(true);
    }, []);
    return {
        isLoading: isLoadingCustomerInfo
    };
};
