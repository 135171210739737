export var organizeRecognitionSimVideoRecognition = function(data, isEsim) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    var cmsConfig = isEsim ? data === null || data === void 0 ? void 0 : (ref = data.personalinfomobile) === null || ref === void 0 ? void 0 : ref.recognitionVideoEsim : data === null || data === void 0 ? void 0 : (ref1 = data.personalinfomobile) === null || ref1 === void 0 ? void 0 : ref1.recognitionVideo;
    return {
        data: {
            title: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.title) || "",
            description: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.description) || "",
            textBold: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.textBold) || "",
            text: (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.text) || ""
        },
        list: {
            title: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref2 = cmsConfig.list) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.title) || "",
            items: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref4 = cmsConfig.list) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.elements) || [
                {
                    text: "",
                    icon: ""
                }, 
            ],
            portabilityItems: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref6 = cmsConfig.list) === null || ref6 === void 0 ? void 0 : (ref7 = ref6[0]) === null || ref7 === void 0 ? void 0 : ref7.portability) || [
                {
                    text: "",
                    icon: ""
                }, 
            ]
        }
    };
};
