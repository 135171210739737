import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 0 20px;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 492px;\n\n  @media (min-width: ",
        ") {\n    padding: 0 40px;\n    height: 737px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 0 74px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 30px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-fb88e7f5-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var TextLoaderEmpty = styled.div.withConfig({
    componentId: "sc-fb88e7f5-1"
})(_templateObject1(), breakpoints.tablet);
