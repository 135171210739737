import { useEffect } from "react";
import { checkIsGoBackSIA } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyFixed.context";
export var useCartAsyncInformation = function() {
    var isLoadingCart = useCheckout().isLoadingCart;
    useEffect(function() {
        if (!isLoadingCart && !checkIsGoBackSIA()) {
            var shoppingCart = getFromLocalStorageByKey("shoppingCart");
            if (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id) {
            // TODO: Add all APIs FROM CART ID
            }
        }
    }, [
        isLoadingCart
    ]);
    return null;
};
