import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 20px;\n\n  @media (min-width: ",
        ") {\n    padding: 60px 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 60px 55px;\n  }\n\n  .linkWithIcon {\n    margin-top: 16px;\n    display: flex;\n    justify-content: left;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        ";\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n  text-align: center;\n  color: ",
        ";\n  margin-bottom: 32px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  margin-bottom: 32px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  display: flex;\n  flex-direction: row;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: fit-content;\n  text-align: start;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 25px;\n  margin-right: 10px;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  input {\n    position: absolute;\n    margin: 0 auto;\n    opacity: 0;\n    cursor: pointer;\n    height: 20px;\n    width: 20px;\n    z-index: 3;\n  }\n\n  .checkmark::after {\n    left: 7px;\n    top: 3px;\n    width: 5px;\n    height: 10px;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    transform: rotate(45deg);\n    content: '';\n    position: absolute;\n    display: none;\n  }\n\n  .checkmark {\n    position: absolute;\n    height: ",
        ";\n    width: ",
        ";\n    border-radius: ",
        ";\n    border: ",
        " solid ",
        ";\n  }\n\n  &:hover input ~ .checkmark {\n    border: ",
        " solid ",
        ";\n  }\n\n  input:checked ~ .checkmark {\n    background-color: ",
        ";\n    border: none;\n  }\n\n  input:checked ~ .checkmark::after {\n    display: block;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n  margin-bottom: 8px;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    text-align: left;\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  p {\n    margin: 0 0 24px;\n\n    &:has(b) {\n      margin: 0 0 8px;\n    }\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n  margin-top: 12px;\n  margin-bottom: 0;\n  span {\n    font-weight: 700;\n    ",
        ";\n    transition: all 0.2s;\n    text-decoration: underline;\n    cursor: pointer;\n\n    &:hover {\n      color: ",
        ";\n      text-decoration-color: red;\n    }\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-c8b885ff-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop, breakpoints.desktop, pxToCssFont(18, 26));
export var Title = styled.div.withConfig({
    componentId: "sc-c8b885ff-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), function(param) {
    var margin = param.margin;
    return margin != undefined ? pxToRem.apply(void 0, _to_consumable_array(margin)) : "0 0 16px";
}, breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled.div.withConfig({
    componentId: "sc-c8b885ff-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30));
export var OldNumber = styled.div.withConfig({
    componentId: "sc-c8b885ff-3"
})(_templateObject3(), fonts.regular, pxToCssFont(20, 26), colors.$e60000);
export var MigrationForm = styled.div.withConfig({
    componentId: "sc-c8b885ff-4"
})(_templateObject4());
export var Footer = styled.div.withConfig({
    componentId: "sc-c8b885ff-5"
})(_templateObject5(), fonts.regular, pxToCssFont(14, 18));
export var FooterDiv = styled.div.withConfig({
    componentId: "sc-c8b885ff-6"
})(_templateObject6());
export var CheckboxDiv = styled.div.withConfig({
    componentId: "sc-c8b885ff-7"
})(_templateObject7());
export var Checkbox = styled.div.withConfig({
    componentId: "sc-c8b885ff-8"
})(_templateObject8(), pxToRem(20), pxToRem(20), pxToRem(3), pxToRem(1), colors.$bebebe, pxToRem(1), colors.$bebebe, colors.$00697c);
export var ParagraphTitle = styled.div.withConfig({
    componentId: "sc-c8b885ff-9"
})(_templateObject9(), fonts.regular, pxToCssFont(20, 26));
export var Paragraph = styled.div.withConfig({
    componentId: "sc-c8b885ff-10"
})(_templateObject10(), fonts.regular, pxToCssFont(20, 26));
export var ModalContent = styled.div.withConfig({
    componentId: "sc-c8b885ff-11"
})(_templateObject11());
export var Question = styled.p.withConfig({
    componentId: "sc-c8b885ff-12"
})(_templateObject12(), pxToCssFont(16, 22), colors.$e60000);
