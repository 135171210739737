export var organizePaymentMethod = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
    return {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.paymentsmobile) === null || ref === void 0 ? void 0 : ref.title) || "",
        subtitle: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref1 = dataFromCms.paymentsmobile) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.userRegistered) === null || ref2 === void 0 ? void 0 : ref2.description) || "",
        buttonLabel: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.paymentsmobile) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.userRegistered) === null || ref4 === void 0 ? void 0 : ref4.changeLabel) || "",
        yourIbanTitle: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref5 = dataFromCms.paymentsmobile) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.paymentMethod) === null || ref6 === void 0 ? void 0 : ref6.titleIban) || "",
        yourCreditCardTitle: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref7 = dataFromCms.paymentsmobile) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.paymentMethod) === null || ref8 === void 0 ? void 0 : ref8.titleCard) || "",
        expirationLabelTitle: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref9 = dataFromCms.paymentsmobile) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.paymentMethod) === null || ref10 === void 0 ? void 0 : ref10.expirationLabel) || ""
    };
};
export var organizePaymentMethodError = function(paymentCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    return {
        titlePaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref = paymentCMS.paymentsmobile) === null || ref === void 0 ? void 0 : (ref1 = ref.paymentSelectionError) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        messagePaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref2 = paymentCMS.paymentsmobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.paymentSelectionError) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        messageSelectedPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref4 = paymentCMS.paymentsmobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.paymentError) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.notFound) === null || ref6 === void 0 ? void 0 : ref6.message) || "Siamo spiacenti ma si \xe8 verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.",
        titleSelectedPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref7 = paymentCMS.paymentsmobile) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.paymentError) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.notFound) === null || ref9 === void 0 ? void 0 : ref9.title) || "Ops!"
    };
};
