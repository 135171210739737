import { useEffect, useState } from "react";
import { useGetDataFromStorage, useCreateAddress, useValidateAddress, CUSTOMER_TYPES } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { LoggerInstance } from "@vfit/shared/providers";
import { CoverageErrorType } from "@vfit/shared/models";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
export var useAddressMobileFlow = function(param) {
    var onConfirmAddress = param.onConfirmAddress;
    var ref, ref1, ref2, ref3, ref4;
    var queryClient = useQueryClient();
    var ref5 = useState(), address = ref5[0], setAddress = ref5[1];
    var ref6 = useState(false), isSuccess = ref6[0], setIsSuccess = ref6[1];
    var ref7 = useState(false), isLoading = ref7[0], setIsLoading = ref7[1];
    var ref8 = useState(false), isError = ref8[0], setIsError = ref8[1];
    var ref9 = useState(CoverageErrorType.KO), errorType = ref9[0], setErrorType = ref9[1];
    var ref10 = useState(false), mutationIsNotFull = ref10[0], setMutationIsNotFull = ref10[1];
    var ref11 = useState(""), errorMessage = ref11[0], setErrorMessage = ref11[1];
    var coexistenceData = getFromLocalStorageByKey("coexistenceUser");
    var silentLoginR2 = queryClient.getQueryData("silentLoginR2");
    var isLoggedR2 = (silentLoginR2 === null || silentLoginR2 === void 0 ? void 0 : silentLoginR2.flowId) && (silentLoginR2 === null || silentLoginR2 === void 0 ? void 0 : silentLoginR2.flowId) === "existingLogin" && (coexistenceData === null || coexistenceData === void 0 ? void 0 : coexistenceData.userType) === CUSTOMER_TYPES.LEGACY;
    /**
   * Hook for useValidateAddress() & useCreateAddress();
   */ var validateAddressMutation = useValidateAddress();
    var createAddressMutation = useCreateAddress(); ///
    var isLocalStorageDisable = localStorage.getItem("disableReset");
    var addressObject = queryClient.getQueryData("coverageToolInputAddress");
    var validatedAddress = useGetDataFromStorage("validatedAddress", !!isLocalStorageDisable);
    var createdAddress = useGetDataFromStorage("createdAddress", !!isLocalStorageDisable);
    /**
   *
   * Enabling API calls only if the addressObject retrieved from google autocomplete
   * is fully filled
   */ var flowEnabler = !!((ref = addressObject) === null || ref === void 0 ? void 0 : ref.city) && !!((ref1 = addressObject) === null || ref1 === void 0 ? void 0 : ref1.postalCode) && !!((ref2 = addressObject) === null || ref2 === void 0 ? void 0 : ref2.stateOrProvince) && !!((ref3 = addressObject) === null || ref3 === void 0 ? void 0 : ref3.street) && !!((ref4 = addressObject) === null || ref4 === void 0 ? void 0 : ref4.streetNumber) && onConfirmAddress;
    /**
   * Check address already validated
   * when user click on edit address this check prevent the api call if address is already validated
   * @return boolean
   */ var checkAddressAlreadyValidated = function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
        LoggerInstance.debug({
            validatedAddress: validatedAddress
        });
        var validAddress = (ref = validatedAddress) === null || ref === void 0 ? void 0 : (ref1 = ref.validAddressList) === null || ref1 === void 0 ? void 0 : ref1[0];
        if (!validAddress) return false;
        return ((ref2 = validAddress.city) === null || ref2 === void 0 ? void 0 : ref2.toLowerCase()) === (addressObject === null || addressObject === void 0 ? void 0 : (ref3 = addressObject.city) === null || ref3 === void 0 ? void 0 : ref3.toLowerCase()) && ((ref4 = validAddress.postalCode) === null || ref4 === void 0 ? void 0 : ref4.toLowerCase()) === (addressObject === null || addressObject === void 0 ? void 0 : (ref5 = addressObject.postalCode) === null || ref5 === void 0 ? void 0 : ref5.toLowerCase()) && ((ref6 = validAddress.stateOrProvince) === null || ref6 === void 0 ? void 0 : ref6.toLowerCase()) === (addressObject === null || addressObject === void 0 ? void 0 : addressObject.stateOrProvince.toLowerCase()) && ((ref7 = validAddress.street) === null || ref7 === void 0 ? void 0 : ref7.toLowerCase()) === (addressObject === null || addressObject === void 0 ? void 0 : (ref8 = addressObject.street) === null || ref8 === void 0 ? void 0 : ref8.toLowerCase());
    };
    var checkValidateAddress = function() {
        var ref, ref1, ref2;
        var showError = function() {
            var ref;
            LoggerInstance.debug("ERROR POPUP");
            setIsLoading(false);
            setIsError(true);
            setErrorType(CoverageErrorType.KO);
            setErrorMessage(((ref = validatedAddress) === null || ref === void 0 ? void 0 : ref.errorMessage) || "");
        };
        LoggerInstance.debug("serviceabilityAddressMutation validatedAddress", validatedAddress);
        LoggerInstance.debug("serviceabilityAddressMutation.mutate PAYLOAD", (ref = validatedAddress) === null || ref === void 0 ? void 0 : (ref1 = ref.validAddressList) === null || ref1 === void 0 ? void 0 : ref1[0]);
        if (validateAddressMutation.isError || ((ref2 = validatedAddress) === null || ref2 === void 0 ? void 0 : ref2.error)) {
            showError();
        }
    };
    useEffect(function() {
        if (!flowEnabler && !!addressObject) {
            setMutationIsNotFull(true);
        }
        if (addressObject && flowEnabler) {
            var addressAlreadyValidated = checkAddressAlreadyValidated();
            if (!addressAlreadyValidated && !validatedAddress) {
                setIsLoading(true);
                validateAddressMutation.mutate({
                    placeObject: addressObject
                });
            }
        }
    }, [
        addressObject,
        flowEnabler
    ]);
    useEffect(function() {
        if (!!validatedAddress && validateAddressMutation.isSuccess) {
            checkValidateAddress();
        }
    }, [
        !!validatedAddress,
        validateAddressMutation.isSuccess
    ]);
    useEffect(function() {
        var ref;
        if (validateAddressMutation.isError && ((ref = validatedAddress) === null || ref === void 0 ? void 0 : ref.error)) {
            var ref1;
            setIsError(true);
            setErrorMessage((ref1 = validatedAddress) === null || ref1 === void 0 ? void 0 : ref1.errorMessage);
        }
    }, [
        validateAddressMutation.isError,
        !!validatedAddress
    ]);
    useEffect(function() {
        var ref, ref1;
        if (!isError && (!createdAddress || !!isLocalStorageDisable) && !createAddressMutation.isLoading && ((ref = validatedAddress) === null || ref === void 0 ? void 0 : (ref1 = ref.validAddressList) === null || ref1 === void 0 ? void 0 : ref1[0]) && validateAddressMutation.isSuccess) {
            var ref2, ref3;
            createAddressMutation.mutate({
                address: (ref2 = validatedAddress) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.validAddressList) === null || ref3 === void 0 ? void 0 : ref3[0]
            });
        }
    }, [
        isError,
        validateAddressMutation.isSuccess
    ]);
    useEffect(function() {
        if (!isError && createAddressMutation.isSuccess && (createAddressMutation === null || createAddressMutation === void 0 ? void 0 : createAddressMutation.data)) {
            setIsSuccess(true);
            setAddress(createAddressMutation === null || createAddressMutation === void 0 ? void 0 : createAddressMutation.data);
        }
    }, [
        createAddressMutation.isSuccess,
        createAddressMutation === null || createAddressMutation === void 0 ? void 0 : createAddressMutation.data
    ]);
    useEffect(function() {
        if (isSuccess) {
            setIsLoading(false);
        }
    }, [
        isSuccess
    ]);
    useEffect(function() {
        if (isLoggedR2) {
            setErrorType(CoverageErrorType.R2_LOGGED);
            setIsLoading(false);
            setIsError(true);
        }
    }, [
        isLoggedR2
    ]);
    return {
        data: address,
        validatedAddress: validateAddressMutation === null || validateAddressMutation === void 0 ? void 0 : validateAddressMutation.data,
        mutationIsNotFull: mutationIsNotFull,
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        errorMessage: errorMessage,
        errorType: errorType
    };
};
