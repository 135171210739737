import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 32px;\n\n  @media (max-width: ",
        ") {\n    height: auto;\n\n    // overflow-x: hidden;\n    max-height: calc(100vh - 230px);\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 0 64px;\n  }\n\n  @media (min-width: ",
        ") and (max-width: ",
        ") {\n    width: 616px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-5182ac6a-0"
})(_templateObject(), breakpoints.tablet, breakpoints.tablet, breakpoints.tablet, breakpoints.desktop);
