import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { createPayloadFixed, errorMock, getDXLBundlePO, useCampaignAccept, useCampaignsInfo, useGetShoppingCartSilentUpdate, useShoppingCartMutation } from "@vfit/consumer/data-access";
import { usePageProvider } from "@vfit/consumer/providers";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useQueryClient } from "react-query";
import { resetData } from "@vfit/shared/providers";
import { IUpdateProductPriceType } from "@vfit/shared/models";
import { updateProductPrice } from "@vfit/consumer/ibuyfixed";
import { useCheckout } from "../../../iBuyFixed.context";
import { getEligibilityProduct } from "./useLockInAppFlow.utils";
/**
 * Lockin APP Flow
 * If is lockin (DXL)
 * campaigns
 * accept
 * getCart
 *
 * if is not lockin (NOT DXL but alternative)
 * createCart
 */ export var useLockInAppFlow = function() {
    var ref, ref1;
    var page = usePageProvider().page;
    var queryClient = useQueryClient();
    var allProducts = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || [];
    var ref2 = useState(""), acceptOrderId = ref2[0], setAcceptOrderId = ref2[1];
    var ref3 = useCheckout(), product = ref3.product, isStartLockInAppFlow = ref3.isStartLockInAppFlow, lockInAppFlow = ref3.lockInAppFlow, shippingAddress = ref3.shippingAddress, setCustomerId = ref3.setCustomerId, setProduct = ref3.setProduct, setLockInAppFlow = ref3.setLockInAppFlow, setIsStartLockInAppFlow = ref3.setIsStartLockInAppFlow;
    var ref4 = useCampaignsInfo({
        keyDependency: [
            "campaignsLockInApp"
        ]
    }), campaignsData = ref4.data, campaignsError = ref4.error, isLoadingCampaigns = ref4.isLoading, isSuccessCampaigns = ref4.isSuccess, isErrorCampaigns = ref4.isError, refetchCampaigns = ref4.refetch;
    var ref5 = useCampaignAccept(), acceptData = ref5.data, acceptError = ref5.error, isLoadingAccept = ref5.isLoading, isSuccessAccept = ref5.isSuccess, isErrorAccept = ref5.isError, mutateAccept = ref5.mutate, resetCampaignAccept = ref5.reset;
    var ref6 = useGetShoppingCartSilentUpdate(acceptOrderId, {
        enabled: false,
        keyDependency: [
            "getShoppingCartLockInApp"
        ]
    }), getShoppingCartData = ref6.data, getShoppingCartError = ref6.error, isLoadingShoppingCart = ref6.isLoading, isSuccessShoppingCart = ref6.isSuccess, isErrorShoppingCart = ref6.isError, refetchShoppingCart = ref6.refetch;
    var ref7 = useShoppingCartMutation(), mutatePostShoppingCart = ref7.mutate, isLoadingPostShoppingCart = ref7.isLoading, isSuccessPostShoppingCart = ref7.isSuccess, isErrorPostShoppingCart = ref7.isError, errorPostShoppingCart = ref7.error, postShoppingCartData = ref7.data;
    var onSuccessAppLockin = function() {
        var ref;
        if (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref = lockInAppFlow.checkEligibility) === null || ref === void 0 ? void 0 : ref.lockInProduct) {
            setProduct(_object_spread({}, lockInAppFlow.checkEligibility.lockInProduct, updateProductPrice(lockInAppFlow.checkEligibility.lockInProduct, IUpdateProductPriceType.EDIT)));
        }
    };
    var resetLockInAppFlow = function() {
        sessionStorage.removeItem("uxfauthorization");
        resetCampaignAccept();
        resetData(queryClient, [
            "getShoppingCartLockInApp",
            "campaignsLockInApp"
        ]);
        setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
            resetLockInAppFlow: resetLockInAppFlow,
            shoppingCart: {
                data: undefined,
                error: undefined,
                isSuccess: false,
                isLoading: false,
                isError: false
            },
            accept: {
                data: undefined,
                error: undefined,
                isSuccess: false,
                isLoading: false,
                isError: false
            },
            checkEligibility: {
                eligible: false,
                lockInOffer: undefined,
                lockInProduct: undefined,
                errorCode: ""
            },
            campaigns: {
                data: undefined,
                error: undefined,
                isSuccess: false,
                isLoading: false,
                isError: false
            }
        }));
    };
    useEffect(function() {
        if (isLoadingShoppingCart) {
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                shoppingCart: _object_spread_props(_object_spread({}, lockInAppFlow.shoppingCart), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorShoppingCart) {
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                shoppingCart: _object_spread_props(_object_spread({}, lockInAppFlow.shoppingCart), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("shoppingCart", getShoppingCartError)
                })
            }));
        } else if (isSuccessShoppingCart) {
            var ref, ref1, ref2, ref3;
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                shoppingCart: _object_spread_props(_object_spread({}, lockInAppFlow.shoppingCart), {
                    data: getShoppingCartData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                })
            }));
            var customerLocal = getFromLocalStorageByKey("customerData");
            var findCaller = getFromLocalStorageByKey("findCaller");
            if ((customerLocal === null || customerLocal === void 0 ? void 0 : (ref = customerLocal[0]) === null || ref === void 0 ? void 0 : ref.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref1 = findCaller.customerRef) === null || ref1 === void 0 ? void 0 : ref1.id)) setCustomerId((customerLocal === null || customerLocal === void 0 ? void 0 : (ref2 = customerLocal[0]) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id) || "");
            onSuccessAppLockin();
        }
    }, [
        isSuccessShoppingCart,
        isErrorShoppingCart,
        isLoadingShoppingCart
    ]);
    useEffect(function() {
        if (isLoadingPostShoppingCart) {
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                shoppingCart: _object_spread_props(_object_spread({}, lockInAppFlow.shoppingCart), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorPostShoppingCart) {
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                shoppingCart: _object_spread_props(_object_spread({}, lockInAppFlow.shoppingCart), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("shoppingCart", errorPostShoppingCart)
                })
            }));
        } else if (isSuccessPostShoppingCart) {
            var ref, ref1, ref2, ref3;
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                shoppingCart: _object_spread_props(_object_spread({}, lockInAppFlow.shoppingCart), {
                    data: postShoppingCartData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                })
            }));
            var customerLocal = getFromLocalStorageByKey("customerData");
            var findCaller = getFromLocalStorageByKey("findCaller");
            if ((customerLocal === null || customerLocal === void 0 ? void 0 : (ref = customerLocal[0]) === null || ref === void 0 ? void 0 : ref.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref1 = findCaller.customerRef) === null || ref1 === void 0 ? void 0 : ref1.id)) setCustomerId((customerLocal === null || customerLocal === void 0 ? void 0 : (ref2 = customerLocal[0]) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref3 = findCaller.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id) || "");
            onSuccessAppLockin();
        }
    }, [
        isSuccessPostShoppingCart,
        isErrorPostShoppingCart,
        isLoadingPostShoppingCart
    ]);
    useEffect(function() {
        if (acceptOrderId) refetchShoppingCart();
    }, [
        acceptOrderId
    ]);
    useEffect(function() {
        if (isLoadingAccept) {
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                accept: _object_spread_props(_object_spread({}, lockInAppFlow.accept), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorAccept) {
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                accept: _object_spread_props(_object_spread({}, lockInAppFlow.accept), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("accept", acceptError)
                })
            }));
        } else if (isSuccessAccept) {
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                shoppingCart: {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    data: undefined,
                    error: undefined
                },
                accept: _object_spread_props(_object_spread({}, lockInAppFlow.accept), {
                    data: acceptData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                })
            }));
            setAcceptOrderId((acceptData === null || acceptData === void 0 ? void 0 : acceptData.orderId) || "");
        }
    }, [
        isSuccessAccept,
        isErrorAccept,
        isLoadingAccept
    ]);
    useEffect(function() {
        if (isLoadingCampaigns) {
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                campaigns: _object_spread_props(_object_spread({}, lockInAppFlow.campaigns), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorCampaigns) {
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                resetLockInAppFlow: resetLockInAppFlow,
                campaigns: _object_spread_props(_object_spread({}, lockInAppFlow.campaigns), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("campaigns", campaignsError)
                })
            }));
        } else if (isSuccessCampaigns) {
            var ref = getEligibilityProduct(allProducts, campaignsData), campaignEligibility = ref.campaignEligibility, lockInProduct = ref.lockInProduct;
            var isEligibleOffer = (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : campaignEligibility.eligible) && (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : campaignEligibility.lockInOffer);
            setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow, isEligibleOffer && {
                accept: {
                    isLoading: true,
                    data: undefined,
                    error: undefined,
                    isError: false,
                    isSuccess: false
                }
            }), {
                resetLockInAppFlow: resetLockInAppFlow,
                campaigns: _object_spread_props(_object_spread({}, lockInAppFlow.campaigns), {
                    data: campaignsData,
                    error: undefined,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                }),
                checkEligibility: _object_spread({}, campaignEligibility, lockInProduct && {
                    lockInProduct: _object_spread({}, lockInProduct)
                })
            }));
            if (isEligibleOffer) {
                var ref1, ref2;
                mutateAccept({
                    campaignId: (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : (ref1 = campaignEligibility.lockInOffer) === null || ref1 === void 0 ? void 0 : ref1.id) || "",
                    sessionId: (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : (ref2 = campaignEligibility.lockInOffer) === null || ref2 === void 0 ? void 0 : ref2.sessionId) || ""
                });
            }
        }
    }, [
        isSuccessCampaigns,
        isErrorCampaigns,
        isLoadingCampaigns
    ]);
    useEffect(function() {
        if (isStartLockInAppFlow) {
            setIsStartLockInAppFlow(false);
        }
    }, [
        isStartLockInAppFlow
    ]);
    useEffect(function() {
        if (isStartLockInAppFlow) {
            var ref, ref1, ref2, ref3, ref4;
            // If is DXL lockin product, start lockin flow
            if (((ref = getDXLBundlePO()) === null || ref === void 0 ? void 0 : ref.toString()) === (product === null || product === void 0 ? void 0 : (ref1 = product.offerId) === null || ref1 === void 0 ? void 0 : ref1.toString())) {
                refetchCampaigns();
            } else if ((product === null || product === void 0 ? void 0 : (ref2 = product.offerId) === null || ref2 === void 0 ? void 0 : ref2.toString()) && !(lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref3 = lockInAppFlow.shoppingCart) === null || ref3 === void 0 ? void 0 : ref3.isLoading) && !(lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref4 = lockInAppFlow.shoppingCart) === null || ref4 === void 0 ? void 0 : ref4.isSuccess)) {
                var ref5, ref6, ref7, ref8, ref9;
                var offeringServiceability = getFromLocalStorageByKey("offeringServiceability");
                var payload = createPayloadFixed(product === null || product === void 0 ? void 0 : (ref5 = product.offerId) === null || ref5 === void 0 ? void 0 : ref5.toString(), ((ref6 = shippingAddress) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.id) === null || ref7 === void 0 ? void 0 : ref7.toString()) || "", ((ref8 = offeringServiceability) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.id) === null || ref9 === void 0 ? void 0 : ref9.toString()) || "");
                // is an alternative offer, call only shopping cart and associate
                setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
                    campaigns: {
                        isLoading: false,
                        isError: false,
                        isSuccess: false
                    },
                    accept: {
                        isLoading: false,
                        isError: false,
                        isSuccess: false
                    },
                    shoppingCart: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                }));
                if (payload) {
                    mutatePostShoppingCart(payload);
                }
            }
        }
    }, [
        isStartLockInAppFlow
    ]);
    useEffect(function() {
        setLockInAppFlow(_object_spread_props(_object_spread({}, lockInAppFlow), {
            resetLockInAppFlow: resetLockInAppFlow
        }));
    }, []);
    return null;
};
