import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { useReleaseResource, useReservePhoneNumber } from "@vfit/consumer/data-access";
export var useReserveFlow = function(param) {
    var flowEnabler = param.flowEnabler;
    var ref = useState({
        isSuccess: false,
        isLoading: false,
        isError: false
    }), config = ref[0], setConfig = ref[1];
    var ref1 = useReservePhoneNumber(), reserveMutate = ref1.mutate, isSuccessReserve = ref1.isSuccess, isLoadingReserve = ref1.isLoading, isErrorReserve = ref1.isError, reserveData = ref1.data;
    var ref2 = useReleaseResource(), releaseResourceMutate = ref2.mutate, isSuccessReleaseResource = ref2.isSuccess, isLoadingReleaseResource = ref2.isLoading, isErrorReleaseResource = ref2.isError;
    useEffect(function() {
        if (flowEnabler && !isLoadingReserve && !isSuccessReserve && !isErrorReserve && !isLoadingReleaseResource && !isErrorReleaseResource && !isSuccessReleaseResource) {
            setConfig(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    isError: false,
                    isSuccess: false,
                    isLoading: true
                });
            });
            reserveMutate();
        }
    }, [
        flowEnabler
    ]);
    useEffect(function() {
        if (isSuccessReleaseResource && !isErrorReleaseResource && !isLoadingReleaseResource) {
            setConfig({
                isSuccess: true,
                isLoading: false,
                isError: true
            });
        } else if (isErrorReleaseResource) {
            setConfig({
                isSuccess: false,
                isLoading: false,
                isError: true
            });
        }
    }, [
        isSuccessReleaseResource,
        isLoadingReleaseResource
    ]);
    useEffect(function() {
        if (isSuccessReserve && !isErrorReserve && !isLoadingReserve) {
            releaseResourceMutate({
                valueList: reserveData === null || reserveData === void 0 ? void 0 : reserveData.map(function(el) {
                    return el.value || "";
                })
            });
        } else if (isErrorReserve) {
            setConfig({
                isSuccess: false,
                isLoading: false,
                isError: true
            });
        }
    }, [
        isSuccessReserve,
        isLoadingReserve
    ]);
    return _object_spread({}, config);
};
