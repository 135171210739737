import { useEffect, useState } from "react";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
export var usePickupPointLocateStore = function(param) {
    var getData = param.getData;
    var ref = useState([]), postalOffices = ref[0], setPostalOffices = ref[1];
    var ref1 = useState([]), pickupPoints = ref1[0], setPickupPoints = ref1[1];
    var ref2 = useState([]), vodafoneStores = ref2[0], setVodafoneStores = ref2[1];
    var retrieveData = function() {
        var postalOfficesData = getFromLocalStorageByKey("searchPickUpLocations");
        var pickupPointsData = getFromLocalStorageByKey("searchPickUpStoreLocations");
        var vodafoneStoresData = getFromLocalStorageByKey("locateStores");
        setPostalOffices(postalOfficesData);
        setPickupPoints(pickupPointsData);
        setVodafoneStores(vodafoneStoresData);
    };
    useEffect(function() {
        if (getData) {
            retrieveData();
        }
    }, [
        getData
    ]);
    return {
        postalOffices: postalOffices,
        pickupPoints: pickupPoints,
        vodafoneStores: vodafoneStores
    };
};
