import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { ErrorCodes, errorMock, getIsStartFromLockin, useAssociateCustomer, useCampaignAccept, useCampaignsInfo, useGetShoppingCartSilentUpdate, LOCK_IN_IS_ACCEPTED_FROM_HUB } from "@vfit/consumer/data-access";
import { resetData } from "@vfit/shared/providers";
import { useQueryClient } from "react-query";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyFixed.context";
import { getEligibilityCampaign } from "./useLockInFlow.utils";
import { getErrorStatusText } from "../UseCustomerFlow/useCustomerFlow.utils";
/**
 * Lockin Flow
 * campaigns
 * accept
 * getCart
 * associate customer
 */ export var useLockInFlow = function() {
    var queryClient = useQueryClient();
    var ref = useState(""), acceptOrderId = ref[0], setAcceptOrderId = ref[1];
    var ref1 = useCheckout(), product = ref1.product, isStartLockInFlow = ref1.isStartLockInFlow, lockInFlow = ref1.lockInFlow, customerFlow = ref1.customerFlow, setIsStartDeliveryBase = ref1.setIsStartDeliveryBase, setCustomerFlow = ref1.setCustomerFlow, setCustomerId = ref1.setCustomerId, setLockInFlow = ref1.setLockInFlow, setIsStartLockInFlow = ref1.setIsStartLockInFlow;
    var ref2 = useCampaignsInfo({
        keyDependency: [
            "campaignsLockIn"
        ]
    }), campaignsData = ref2.data, campaignsError = ref2.error, isLoadingCampaigns = ref2.isLoading, isSuccessCampaigns = ref2.isSuccess, isErrorCampaigns = ref2.isError, refetchCampaigns = ref2.refetch;
    var ref3 = useCampaignAccept(), acceptData = ref3.data, acceptError = ref3.error, isLoadingAccept = ref3.isLoading, isSuccessAccept = ref3.isSuccess, isErrorAccept = ref3.isError, mutateAccept = ref3.mutate, resetCampaignAccept = ref3.reset;
    var ref4 = useGetShoppingCartSilentUpdate(acceptOrderId, {
        enabled: false,
        keyDependency: [
            "getShoppingCartLockIn"
        ]
    }), getShoppingCartData = ref4.data, getShoppingCartError = ref4.error, isLoadingShoppingCart = ref4.isLoading, isSuccessShoppingCart = ref4.isSuccess, isErrorShoppingCart = ref4.isError, refetchShoppingCart = ref4.refetch;
    var ref5 = useAssociateCustomer(), associateCustomerData = ref5.data, associateCustomerError = ref5.error, associateCustomerMutate = ref5.mutate, isSuccessAssociateCustomer = ref5.isSuccess, isErrorAssociateCustomer = ref5.isError, isLoadingAssociateCustomer = ref5.isLoading, resetAssociateCustomer = ref5.reset;
    var resetLockInFlow = function() {
        sessionStorage.removeItem("uxfauthorization");
        resetAssociateCustomer();
        resetCampaignAccept();
        resetData(queryClient, [
            "getShoppingCartLockIn",
            "campaignsLockIn"
        ]);
        setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
            resetLockInFlow: resetLockInFlow,
            associateCustomer: {
                data: undefined,
                error: undefined,
                isSuccess: false,
                isLoading: false,
                isError: false
            },
            shoppingCart: {
                data: undefined,
                error: undefined,
                isSuccess: false,
                isLoading: false,
                isError: false
            },
            accept: {
                data: undefined,
                error: undefined,
                isSuccess: false,
                isLoading: false,
                isError: false
            },
            campaigns: {
                data: undefined,
                error: undefined,
                isSuccess: false,
                isLoading: false,
                isError: false
            }
        }));
    };
    useEffect(function() {
        if (getIsStartFromLockin()) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                associateCustomer: _object_spread({}, lockInFlow.associateCustomer)
            }));
        }
    }, [
        lockInFlow
    ]);
    useEffect(function() {
        if (isLoadingAssociateCustomer) {
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                associateCustomer: _object_spread_props(_object_spread({}, lockInFlow.associateCustomer), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorAssociateCustomer) {
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                associateCustomer: _object_spread_props(_object_spread({}, lockInFlow.associateCustomer), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("associateCustomer", associateCustomerError)
                })
            }));
        } else if (isSuccessAssociateCustomer) {
            var ref, ref1;
            if (((ref = associateCustomerData) === null || ref === void 0 ? void 0 : (ref1 = ref.cartValidation) === null || ref1 === void 0 ? void 0 : ref1.status) === "blocked") {
                setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                    resetLockInFlow: resetLockInFlow,
                    associateCustomer: _object_spread_props(_object_spread({}, lockInFlow.associateCustomer), {
                        data: associateCustomerData,
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: errorMock("associateCustomer", {
                            status: "500",
                            statusText: getErrorStatusText("500", ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED),
                            url: ""
                        })
                    })
                }));
            } else {
                setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                    resetLockInFlow: resetLockInFlow,
                    associateCustomer: _object_spread_props(_object_spread({}, lockInFlow.associateCustomer), {
                        data: associateCustomerData,
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    })
                }));
                var customerLocal = getFromLocalStorageByKey("customerData");
                if (customerLocal === null || customerLocal === void 0 ? void 0 : customerLocal[0]) setCustomerId(customerLocal === null || customerLocal === void 0 ? void 0 : customerLocal[0].id);
                setIsStartDeliveryBase(true);
            }
        }
    }, [
        isSuccessAssociateCustomer,
        isErrorAssociateCustomer,
        isLoadingAssociateCustomer
    ]);
    useEffect(function() {
        if (isLoadingShoppingCart) {
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                shoppingCart: _object_spread_props(_object_spread({}, lockInFlow.shoppingCart), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorShoppingCart) {
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                shoppingCart: _object_spread_props(_object_spread({}, lockInFlow.shoppingCart), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("shoppingCart", getShoppingCartError)
                })
            }));
        } else if (isSuccessShoppingCart) {
            var alreadyDoneAssociateCustomer = getFromLocalStorageByKey("associateCustomer");
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                shoppingCart: _object_spread_props(_object_spread({}, lockInFlow.shoppingCart), {
                    data: getShoppingCartData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                })
            }));
            if (!alreadyDoneAssociateCustomer && getIsStartFromLockin()) associateCustomerMutate();
        }
    }, [
        isSuccessShoppingCart,
        isErrorShoppingCart,
        isLoadingShoppingCart
    ]);
    useEffect(function() {
        if (acceptOrderId) refetchShoppingCart();
    }, [
        acceptOrderId
    ]);
    useEffect(function() {
        if (isLoadingAccept) {
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                accept: _object_spread_props(_object_spread({}, lockInFlow.accept), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorAccept) {
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                accept: _object_spread_props(_object_spread({}, lockInFlow.accept), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("accept", acceptError)
                })
            }));
        } else if (isSuccessAccept) {
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                shoppingCart: {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    data: undefined,
                    error: undefined
                },
                accept: _object_spread_props(_object_spread({}, lockInFlow.accept), {
                    data: acceptData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                })
            }));
            setAcceptOrderId((acceptData === null || acceptData === void 0 ? void 0 : acceptData.orderId) || "");
        }
    }, [
        isSuccessAccept,
        isErrorAccept,
        isLoadingAccept
    ]);
    useEffect(function() {
        if (isLoadingCampaigns) {
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                campaigns: _object_spread_props(_object_spread({}, lockInFlow.campaigns), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorCampaigns) {
            setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                resetLockInFlow: resetLockInFlow,
                campaigns: _object_spread_props(_object_spread({}, lockInFlow.campaigns), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("campaigns", campaignsError)
                })
            }));
        } else if (isSuccessCampaigns) {
            var lockInOfferCampaign = getEligibilityCampaign(product, campaignsData);
            if (!lockInOfferCampaign) {
                setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                    campaigns: _object_spread_props(_object_spread({}, lockInFlow.campaigns), {
                        data: undefined,
                        error: undefined,
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    })
                }));
            } else {
                setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
                    resetLockInFlow: resetLockInFlow,
                    accept: {
                        isLoading: true,
                        data: undefined,
                        error: undefined,
                        isError: false,
                        isSuccess: false
                    },
                    campaigns: _object_spread_props(_object_spread({}, lockInFlow.campaigns), {
                        data: campaignsData,
                        error: undefined,
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    })
                }));
                mutateAccept({
                    campaignId: (lockInOfferCampaign === null || lockInOfferCampaign === void 0 ? void 0 : lockInOfferCampaign.id) || "",
                    sessionId: (lockInOfferCampaign === null || lockInOfferCampaign === void 0 ? void 0 : lockInOfferCampaign.sessionId) || ""
                });
            }
        }
    }, [
        isSuccessCampaigns,
        isErrorCampaigns,
        isLoadingCampaigns
    ]);
    useEffect(function() {
        if (isStartLockInFlow) {
            setIsStartLockInFlow(false);
        }
    }, [
        isStartLockInFlow
    ]);
    useEffect(function() {
        var ref;
        if (isStartLockInFlow && !(lockInFlow === null || lockInFlow === void 0 ? void 0 : (ref = lockInFlow.accept) === null || ref === void 0 ? void 0 : ref.isSuccess)) {
            var lockInIsAcceptedFromHub = localStorage.getItem(LOCK_IN_IS_ACCEPTED_FROM_HUB);
            if (!lockInIsAcceptedFromHub) refetchCampaigns();
        }
    }, [
        isStartLockInFlow
    ]);
    useEffect(function() {
        setLockInFlow(_object_spread_props(_object_spread({}, lockInFlow), {
            resetLockInFlow: resetLockInFlow
        }));
    }, []);
    return null;
};
