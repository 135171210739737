import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { handleFirstCharacterOfSentence } from "@vfit/consumer/data-access";
export var getDataOrganized = function(deliveryData, owningIndividual, billingAddress, shippingAddress, editAddressConfig, isEsim, isEsimFD, isCourier) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37, ref38, ref39, ref40, ref41, ref42, ref43, ref44, ref45, ref46, ref47, ref48, ref49, ref50, ref51, ref52, ref53, ref54, ref55, ref56, ref57, ref58, ref59, ref60, ref61, ref62, ref63;
    var simInformation = deliveryData === null || deliveryData === void 0 ? void 0 : (ref = deliveryData.deliveryinformationm) === null || ref === void 0 ? void 0 : (ref1 = ref.selfService) === null || ref1 === void 0 ? void 0 : ref1.sim;
    var esimInformation = deliveryData === null || deliveryData === void 0 ? void 0 : (ref2 = deliveryData.deliveryinformationm) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.selfService) === null || ref3 === void 0 ? void 0 : ref3.eSim;
    var esimFullyDigitalInformation = deliveryData === null || deliveryData === void 0 ? void 0 : (ref4 = deliveryData.deliveryinformationm) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.selfService) === null || ref5 === void 0 ? void 0 : ref5.eSimFullyDigital;
    var courierInformation = deliveryData === null || deliveryData === void 0 ? void 0 : (ref6 = deliveryData.deliveryinformationm) === null || ref6 === void 0 ? void 0 : ref6.courrier;
    var topInformation = {
        topTitle: "",
        description: ""
    };
    if (isEsim) {
        if (!isEsimFD) {
            topInformation = _object_spread_props(_object_spread({}, topInformation), {
                topTitle: (esimInformation === null || esimInformation === void 0 ? void 0 : esimInformation.title) || "I tuoi indirizzi",
                description: (esimInformation === null || esimInformation === void 0 ? void 0 : esimInformation.description) || "Controlla la correttezza dell’indirizzo di spedizione e fatturazione. Ti invieremo per posta le istruzioni necessarie per installare la tua nuova eSIM."
            });
        } else {
            topInformation = _object_spread_props(_object_spread({}, topInformation), {
                topTitle: (esimFullyDigitalInformation === null || esimFullyDigitalInformation === void 0 ? void 0 : esimFullyDigitalInformation.title) || (esimInformation === null || esimInformation === void 0 ? void 0 : esimInformation.title) || "I tuoi indirizzi",
                description: (esimFullyDigitalInformation === null || esimFullyDigitalInformation === void 0 ? void 0 : esimFullyDigitalInformation.description) || (esimInformation === null || esimInformation === void 0 ? void 0 : esimInformation.description) || "Controlla la correttezza dell’indirizzo di spedizione e fatturazione."
            });
        }
    } else if (isCourier) {
        topInformation = _object_spread_props(_object_spread({}, topInformation), {
            topTitle: (courierInformation === null || courierInformation === void 0 ? void 0 : courierInformation.title) || (simInformation === null || simInformation === void 0 ? void 0 : simInformation.title) || "I tuoi indirizzi",
            description: (courierInformation === null || courierInformation === void 0 ? void 0 : courierInformation.description) || (simInformation === null || simInformation === void 0 ? void 0 : simInformation.description) || "Controlla la correttezza dell’indirizzo di spedizione e fatturazione."
        });
    } else {
        topInformation = _object_spread_props(_object_spread({}, topInformation), {
            topTitle: (simInformation === null || simInformation === void 0 ? void 0 : simInformation.title) || "I tuoi indirizzi",
            description: (simInformation === null || simInformation === void 0 ? void 0 : simInformation.description) || "Controlla la correttezza dell’indirizzo di spedizione e fatturazione."
        });
    }
    return _object_spread_props(_object_spread({}, topInformation), {
        userInfoTitle: "",
        userData: {
            lastName: owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName,
            firstName: owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName,
            address: handleFirstCharacterOfSentence((shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.street) || "").concat(" ", (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.streetNumber) || ""),
            city: handleFirstCharacterOfSentence((shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.city) || ""),
            zipCode: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.postalCode) || "",
            province: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.stateOrProvince) || ""
        },
        billingData: {
            title: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref7 = deliveryData.deliveryinformationm) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.addressBilling) === null || ref8 === void 0 ? void 0 : ref8.bottomTitle) || "Indirizzo di fatturazione",
            matchAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref9 = deliveryData.deliveryinformationm) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.addressBilling) === null || ref10 === void 0 ? void 0 : ref10.title) || "L’indirizzo di fatturazione corrisponde all’indirizzo di installazione.",
            noMatch: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref11 = deliveryData.deliveryinformationm) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.addressBilling) === null || ref12 === void 0 ? void 0 : ref12.description) || "Non corrisponde?",
            labelEdit: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref13 = deliveryData.deliveryinformationm) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.addressBilling) === null || ref14 === void 0 ? void 0 : ref14.linkLabelEdit) || "Modifica Indirizzo",
            changeAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref15 = deliveryData.deliveryinformationm) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.addressBilling) === null || ref16 === void 0 ? void 0 : ref16.linkLabel) || "Cambia l'indirizzo di fatturazione",
            addressData: {
                lastName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "",
                firstName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "",
                address: handleFirstCharacterOfSentence(billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.street).concat(" ", (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.streetNumber) || ""),
                city: handleFirstCharacterOfSentence(billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.city),
                zipCode: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.postalCode) || "",
                province: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.stateOrProvince) || ""
            }
        },
        shippingData: {
            title: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref17 = deliveryData.deliveryinformationm) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.addressShipping) === null || ref18 === void 0 ? void 0 : ref18.title) || "Indirizzo di spedizione",
            matchAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref19 = deliveryData.deliveryinformationm) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.addressShipping) === null || ref20 === void 0 ? void 0 : ref20.subtitle) || "L'indirizzo di spedizione corrisponde all'indirizzo di installazione.",
            noMatch: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref21 = deliveryData.deliveryinformationm) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.addressShipping) === null || ref22 === void 0 ? void 0 : ref22.address) || "Non corrisponde?",
            labelEdit: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref23 = deliveryData.deliveryinformationm) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.addressShipping) === null || ref24 === void 0 ? void 0 : ref24.linkLabelEdit) || "Modifica Indirizzo",
            changeAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref25 = deliveryData.deliveryinformationm) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.addressShipping) === null || ref26 === void 0 ? void 0 : ref26.linkLabel) || "Cambia l'indirizzo di spedizione",
            addressData: {
                lastName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "",
                firstName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "",
                address: handleFirstCharacterOfSentence((shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.street) || "").concat(" ", (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.streetNumber) || ""),
                city: handleFirstCharacterOfSentence(shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.city),
                zipCode: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.postalCode) || "",
                province: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.stateOrProvince) || "",
                note: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.note) || "",
                postalOfficeDescription: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.postalOfficeDescription) || ""
            }
        },
        billingDataEsim: {
            title: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref27 = deliveryData.deliveryinformationm) === null || ref27 === void 0 ? void 0 : (ref28 = ref27.addressBillingEsim) === null || ref28 === void 0 ? void 0 : ref28.bottomTitle) || "Indirizzo di fatturazione Esim",
            matchAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref29 = deliveryData.deliveryinformationm) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.addressBillingEsim) === null || ref30 === void 0 ? void 0 : ref30.title) || "L’indirizzo di fatturazione corrisponde all’indirizzo di installazione ESIM.",
            noMatch: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref31 = deliveryData.deliveryinformationm) === null || ref31 === void 0 ? void 0 : (ref32 = ref31.addressBillingEsim) === null || ref32 === void 0 ? void 0 : ref32.description) || "Non corrisponde ESIM?",
            labelEdit: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref33 = deliveryData.deliveryinformationm) === null || ref33 === void 0 ? void 0 : (ref34 = ref33.addressBillingEsim) === null || ref34 === void 0 ? void 0 : ref34.linkLabelEdit) || "Modifica Indirizzo ESIM",
            changeAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref35 = deliveryData.deliveryinformationm) === null || ref35 === void 0 ? void 0 : (ref36 = ref35.addressBillingEsim) === null || ref36 === void 0 ? void 0 : ref36.linkLabel) || "Modifica Indirizzo ESIM",
            addressData: {
                lastName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "",
                firstName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "",
                address: handleFirstCharacterOfSentence(billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.street).concat(" ", (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.streetNumber) || ""),
                city: handleFirstCharacterOfSentence(billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.city),
                zipCode: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.postalCode) || "",
                province: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.stateOrProvince) || ""
            }
        },
        shippingDataEsim: {
            title: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref37 = deliveryData.deliveryinformationm) === null || ref37 === void 0 ? void 0 : (ref38 = ref37.addressShippingEsim) === null || ref38 === void 0 ? void 0 : ref38.title) || "Indirizzo di spedizione ESIM",
            matchAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref39 = deliveryData.deliveryinformationm) === null || ref39 === void 0 ? void 0 : (ref40 = ref39.addressShippingEsim) === null || ref40 === void 0 ? void 0 : ref40.subtitle) || "L'indirizzo di spedizione corrisponde all'indirizzo di fatturazione ESIM.",
            noMatch: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref41 = deliveryData.deliveryinformationm) === null || ref41 === void 0 ? void 0 : (ref42 = ref41.addressShippingEsim) === null || ref42 === void 0 ? void 0 : ref42.address) || "Non corrisponde ESIM?",
            labelEdit: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref43 = deliveryData.deliveryinformationm) === null || ref43 === void 0 ? void 0 : (ref44 = ref43.addressShippingEsim) === null || ref44 === void 0 ? void 0 : ref44.linkLabelEdit) || "Modifica Indirizzo ESIM",
            changeAddress: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref45 = deliveryData.deliveryinformationm) === null || ref45 === void 0 ? void 0 : (ref46 = ref45.addressShippingEsim) === null || ref46 === void 0 ? void 0 : ref46.linkLabel) || "Cambia l'indirizzo di spedizione ESIM",
            addressData: {
                lastName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "",
                firstName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "",
                address: handleFirstCharacterOfSentence((shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.street) || "").concat(" ", (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.streetNumber) || ""),
                city: handleFirstCharacterOfSentence(shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.city),
                zipCode: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.postalCode) || "",
                province: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.stateOrProvince) || "",
                note: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.note) || "",
                postalOfficeDescription: (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.postalOfficeDescription) || ""
            }
        },
        deliveryCta: "",
        afterEditAddress: "",
        editAddressConfig: editAddressConfig,
        noteLabels: {
            noteTitle: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref47 = deliveryData.deliveryinformationm) === null || ref47 === void 0 ? void 0 : (ref48 = ref47.commonLabel) === null || ref48 === void 0 ? void 0 : ref48.descriptionNote) || "Aggiungi note per la consegna",
            noteTitleAfterEdit: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref49 = deliveryData.deliveryinformationm) === null || ref49 === void 0 ? void 0 : (ref50 = ref49.commonLabel) === null || ref50 === void 0 ? void 0 : ref50.linkLabelEdit) || "Modifica note per la consegna"
        },
        noteModalConfig: {
            title: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref51 = deliveryData.deliveryinformationm) === null || ref51 === void 0 ? void 0 : (ref52 = ref51.commonLabel) === null || ref52 === void 0 ? void 0 : ref52.linkLabel) || "Note di consegna",
            description: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref53 = deliveryData.deliveryinformationm) === null || ref53 === void 0 ? void 0 : (ref54 = ref53.commonLabel) === null || ref54 === void 0 ? void 0 : ref54.modalTextLabel) || "Inserisci le note per la consegna",
            textLabel: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref55 = deliveryData.deliveryinformationm) === null || ref55 === void 0 ? void 0 : (ref56 = ref55.commonLabel) === null || ref56 === void 0 ? void 0 : ref56.placeholderNotes) || "Note per la consegna",
            ctaLabel: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref57 = deliveryData.deliveryinformationm) === null || ref57 === void 0 ? void 0 : (ref58 = ref57.commonLabel) === null || ref58 === void 0 ? void 0 : ref58.modalCtaLabel) || "Salva note",
            titleMod: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref59 = deliveryData.deliveryinformationm) === null || ref59 === void 0 ? void 0 : (ref60 = ref59.commonLabel) === null || ref60 === void 0 ? void 0 : ref60.linkLabelEdit) || "Modifica note per la consegna",
            descriptionMod: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref61 = deliveryData.deliveryinformationm) === null || ref61 === void 0 ? void 0 : (ref62 = ref61.commonLabel) === null || ref62 === void 0 ? void 0 : ref62.linkLabel) || "Note di consegna"
        },
        disableAddNotes: (deliveryData === null || deliveryData === void 0 ? void 0 : (ref63 = deliveryData.deliveryinformationm) === null || ref63 === void 0 ? void 0 : ref63.disableAddNotes) === "true"
    });
};
