export var organizeConfirmIdentityCard = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    return {
        titleCms: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.clientRecognition) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        descriptionCms: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref2 = dataFromCms.personalinfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.clientRecognition) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        placeholderCms: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref4 = dataFromCms.personalinfo) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.selectInput) === null || ref5 === void 0 ? void 0 : ref5.contactPlaceholder) || "",
        ctaLabelCms: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref6 = dataFromCms.personalinfo) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.clientRecognition) === null || ref7 === void 0 ? void 0 : ref7.codeNotAccessLabel) || ""
    };
};
export var SKELETON_SHAPE = {
    margins: [
        60,
        30
    ],
    tabletMargins: [
        32,
        15
    ],
    groups: [
        {
            heights: 60,
            marginBottom: 32
        },
        {
            heights: 60,
            marginBottom: 15
        },
        {
            heights: 75,
            repeat: 4,
            gap: 15
        }, 
    ]
};
