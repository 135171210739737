import { useEffect, useState } from "react";
import { checkIsGoBackSIA, errorMock, useNotify, useUpdateProductCharacteristic, useUpdateProductCharacteristicLight } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
export var useTermsFlow = function(termsAndConditions, isSiaError) {
    var postponeNotify = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false, enabled = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : true;
    var queryClient = useQueryClient();
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(undefined), error = ref3[0], setError = ref3[1];
    var ref4 = useState(""), errorMessage = ref4[0], setErrorMessage = ref4[1];
    var ref5 = useState(termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider), optionToReconsiderState = ref5[0], setOptionToReconsiderState = ref5[1];
    var updatedProductCharacteristic_2 = queryClient.getQueryData("updateProductCharacteristic_2");
    var updatedProductCharacteristicLight = queryClient.getQueryData("updateProductCharacteristicLight");
    /**
   * get notify data from localstorage or query
   */ var notifyFromStorage = getFromLocalStorageByKey("notify");
    var notifyFromQuery = queryClient.getQueryData("notify");
    var notify = notifyFromQuery || notifyFromStorage;
    var useUpdateProductCharacteristicMutation = useUpdateProductCharacteristic();
    var useUpdateProductCharacteristicLightMutation = useUpdateProductCharacteristicLight();
    var notifyMutation = useNotify();
    useEffect(function() {
        if (!checkIsGoBackSIA() && ((termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.consensus) || (termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.preConsensus)) && (termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider) !== "" && (!useUpdateProductCharacteristicMutation.isSuccess || (termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider) !== optionToReconsiderState)) {
            setIsSuccess(false);
            setIsLoading(true);
        }
    }, [
        termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.consensus,
        termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.preConsensus,
        termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider, 
    ]);
    useEffect(function() {
        if (isLoading && enabled && !checkIsGoBackSIA()) {
            var shoppingCart = getFromLocalStorageByKey("shoppingCart");
            var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
            if (cartId) useUpdateProductCharacteristicMutation.mutate(termsAndConditions.optionToReconsider);
        }
    }, [
        isLoading,
        enabled
    ]);
    useEffect(function() {
        if (!checkIsGoBackSIA() && enabled && !notifyMutation.isLoading && !notifyMutation.isError && !notifyMutation.isSuccess && ((termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.consensus) || (termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.preConsensus)) && !isSiaError) {
            var notifyData = localStorage.getItem("notify");
            if (notifyData !== "1" && !postponeNotify) notifyMutation.mutate((termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.consensus) || (termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.preConsensus));
        }
    }, [
        enabled,
        notifyMutation.isSuccess,
        notifyMutation.isLoading,
        notifyMutation.isError,
        termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.consensus,
        termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.preConsensus,
        termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider,
        useUpdateProductCharacteristicMutation.isSuccess, 
    ]);
    useEffect(function() {
        if (updatedProductCharacteristic_2 === null || updatedProductCharacteristic_2 === void 0 ? void 0 : updatedProductCharacteristic_2.error) {
            setIsLoading(false);
            setIsError(true);
            setErrorMessage(updatedProductCharacteristic_2.errorMessage);
            setError(errorMock("notify", updatedProductCharacteristic_2 === null || updatedProductCharacteristic_2 === void 0 ? void 0 : updatedProductCharacteristic_2.error));
        }
        if (notify === null || notify === void 0 ? void 0 : notify.error) {
            setIsLoading(false);
            setIsError(true);
            setErrorMessage(notify === null || notify === void 0 ? void 0 : notify.errorMessage);
            setError(errorMock("notify", notify === null || notify === void 0 ? void 0 : notify.error));
        }
        if (!checkIsGoBackSIA() && isLoading && enabled && updatedProductCharacteristic_2 && useUpdateProductCharacteristicMutation.isSuccess && !(updatedProductCharacteristic_2 === null || updatedProductCharacteristic_2 === void 0 ? void 0 : updatedProductCharacteristic_2.error) && (termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider) && ((termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.consensus) || (termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.preConsensus)) && (!useUpdateProductCharacteristicLightMutation.isSuccess && !useUpdateProductCharacteristicLightMutation.isLoading || optionToReconsiderState != (termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider))) {
            useUpdateProductCharacteristicLightMutation.mutate(termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider);
            setOptionToReconsiderState(termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider);
        }
    }, [
        enabled,
        updatedProductCharacteristicLight,
        updatedProductCharacteristic_2,
        termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.consensus,
        termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.preConsensus,
        termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.optionToReconsider,
        useUpdateProductCharacteristicMutation.isSuccess, 
    ]);
    useEffect(function() {
        if (useUpdateProductCharacteristicLightMutation.isSuccess) {
            setIsLoading(false);
            setIsSuccess(true);
        }
    }, [
        updatedProductCharacteristicLight,
        useUpdateProductCharacteristicLightMutation.isSuccess,
        useUpdateProductCharacteristicLightMutation.isError, 
    ]);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        error: error,
        errorMessage: errorMessage
    };
};
