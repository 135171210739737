export var organizePortabilityCard = function(portabilityCardCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15;
    return {
        errorOnPortability: {
            errorMessage: {
                genericError: {
                    title: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref = portabilityCardCms.portabilitymobile) === null || ref === void 0 ? void 0 : (ref1 = ref.errorOnPortability) === null || ref1 === void 0 ? void 0 : ref1.message) || ""
                }
            }
        },
        title: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref2 = portabilityCardCms.portabilitymobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.general) === null || ref3 === void 0 ? void 0 : ref3.title) || "",
        description: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref4 = portabilityCardCms.portabilitymobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.general) === null || ref5 === void 0 ? void 0 : ref5.description) || "",
        bottomText: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref6 = portabilityCardCms.portabilitymobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.general) === null || ref7 === void 0 ? void 0 : ref7.bottomText) || "",
        items: [
            {
                title: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref8 = portabilityCardCms.portabilitymobile) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.general) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.choicePortability) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.option1) === null || ref11 === void 0 ? void 0 : ref11.title) || "S\xec",
                value: "yes"
            },
            {
                title: (portabilityCardCms === null || portabilityCardCms === void 0 ? void 0 : (ref12 = portabilityCardCms.portabilitymobile) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.general) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.choicePortability) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.option2) === null || ref15 === void 0 ? void 0 : ref15.title) || "No",
                value: "no"
            }, 
        ]
    };
};
