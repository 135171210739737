import { checkWindow } from "@vfit/shared/data-access";
import { getUserInfo, getProductLabelPromo, getPageProductsInfo, getProductDeviceInfo, getOrganizedSoftProductsByProducts, getOrganizedSoftDevicesByDevices, createTechAppointmentMockCookie, encryptData } from "./utils";
import { ISoftEvents } from "./interfaces";
export var SoftManagerService = function(queryClient) {
    var getSoftProductsByProducts = function(products) {
        return getOrganizedSoftProductsByProducts(queryClient, products);
    };
    var getSoftDevicesByDevices = function(devices) {
        return getOrganizedSoftDevicesByDevices(queryClient, devices);
    };
    var getPromoLabel = function(slug) {
        return getProductLabelPromo(slug);
    };
    var getUser = function() {
        return getUserInfo(queryClient);
    };
    var getEncryptedData = function(value) {
        return encryptData(value);
    };
    var getPageProduct = function(product) {
        return getPageProductsInfo(product);
    };
    var getProductDevice = function(device, selectedModel) {
        return getProductDeviceInfo(device, selectedModel);
    };
    var addCustomOffer = function(product) {
        var customAddEvent = new CustomEvent(ISoftEvents.ADD_CUSTOM_OFFER, {
            detail: product
        });
        document.dispatchEvent(customAddEvent);
    };
    var setIsAbTest = function(value) {
        var customSetAbTestEvent = new CustomEvent(ISoftEvents.SET_AB_TEST, {
            detail: value
        });
        document.dispatchEvent(customSetAbTestEvent);
    };
    var setDisableRedirect = function(value) {
        if (!checkWindow()) return;
        if (value.toString() === "0") localStorage.removeItem("disableRed");
        else localStorage.setItem("disableRed", value.toString());
    };
    var setMockTechAppointment = function(redirectUrl) {
        return createTechAppointmentMockCookie(redirectUrl);
    };
    return {
        getSoftProductsByProducts: getSoftProductsByProducts,
        getSoftDevicesByDevices: getSoftDevicesByDevices,
        addCustomOffer: addCustomOffer,
        getPageProduct: getPageProduct,
        getProductDevice: getProductDevice,
        getUser: getUser,
        getEncryptedData: getEncryptedData,
        getPromoLabel: getPromoLabel,
        setIsAbTest: setIsAbTest,
        setDisableRedirect: setDisableRedirect,
        setMockTechAppointment: setMockTechAppointment
    };
};
