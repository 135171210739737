import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { replaceShippingCost } from "@vfit/consumer/data-access";
import { ECodeIdentification } from "@vfit/shared/models";
export var organizeRecognitionSimMethodSelection = function(data, isMNP, isEsim) {
    var price = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "";
    var ref, ref1, ref2, ref3;
    var itemsToReturn = [];
    if (!isEsim && !isMNP) {
        var ref4, ref5, ref6, ref7, ref8, ref9;
        itemsToReturn = [
            _object_spread_props(_object_spread({}, data === null || data === void 0 ? void 0 : (ref4 = data.personalinfomobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.recognitionMethodSelection) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.elements) === null || ref6 === void 0 ? void 0 : ref6[0]), {
                value: ECodeIdentification.VR
            }),
            _object_spread_props(_object_spread({}, data === null || data === void 0 ? void 0 : (ref7 = data.personalinfomobile) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.recognitionMethodSelection) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.elements) === null || ref9 === void 0 ? void 0 : ref9[1]), {
                value: ECodeIdentification.CO
            }), 
        ];
    }
    if (!isEsim && isMNP) {
        var ref10, ref11, ref12, ref13, ref14, ref15;
        itemsToReturn = [
            _object_spread_props(_object_spread({}, data === null || data === void 0 ? void 0 : (ref10 = data.personalinfomobile) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.recognitionMethodSelection) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.elementsMnp) === null || ref12 === void 0 ? void 0 : ref12[0]), {
                value: ECodeIdentification.VR
            }),
            _object_spread_props(_object_spread({}, data === null || data === void 0 ? void 0 : (ref13 = data.personalinfomobile) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.recognitionMethodSelection) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.elementsMnp) === null || ref15 === void 0 ? void 0 : ref15[1]), {
                value: ECodeIdentification.CO
            }), 
        ];
    }
    if (isEsim && !isMNP) {
        var ref16, ref17, ref18, ref19, ref20, ref21;
        itemsToReturn = [
            _object_spread_props(_object_spread({}, data === null || data === void 0 ? void 0 : (ref16 = data.personalinfomobile) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.recognitionMethodSelectionEsim) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.elements) === null || ref18 === void 0 ? void 0 : ref18[0]), {
                value: ECodeIdentification.VR
            }),
            _object_spread_props(_object_spread({}, data === null || data === void 0 ? void 0 : (ref19 = data.personalinfomobile) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.recognitionMethodSelectionEsim) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.elements) === null || ref21 === void 0 ? void 0 : ref21[1]), {
                value: ECodeIdentification.CO
            }), 
        ];
    }
    if (isEsim && isMNP) {
        var ref22, ref23, ref24, ref25, ref26, ref27;
        itemsToReturn = [
            _object_spread_props(_object_spread({}, data === null || data === void 0 ? void 0 : (ref22 = data.personalinfomobile) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.recognitionMethodSelectionEsim) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.elementsMnp) === null || ref24 === void 0 ? void 0 : ref24[0]), {
                value: ECodeIdentification.VR
            }),
            _object_spread_props(_object_spread({}, data === null || data === void 0 ? void 0 : (ref25 = data.personalinfomobile) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.recognitionMethodSelectionEsim) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.elementsMnp) === null || ref27 === void 0 ? void 0 : ref27[1]), {
                value: ECodeIdentification.CO
            }), 
        ];
    }
    return {
        title: replaceShippingCost((data === null || data === void 0 ? void 0 : (ref = data.personalinfomobile) === null || ref === void 0 ? void 0 : (ref1 = ref.recognitionMethodSelection) === null || ref1 === void 0 ? void 0 : ref1.title) || "", price),
        description: replaceShippingCost((data === null || data === void 0 ? void 0 : (ref2 = data.personalinfomobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.recognitionMethodSelection) === null || ref3 === void 0 ? void 0 : ref3.description) || "", price),
        items: itemsToReturn
    };
};
