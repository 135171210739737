/* eslint-disable no-param-reassign */ import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { CART_TYPE, getCostFromShoppingCartByType, getHubPath, getOneTimeAmountFromShoppingCart, organizeTechnology, Placeholders, retrieveProductTechnology, START_FROM_APP_LOCKIN, TechnologyCategories, updateProduct } from "@vfit/consumer/data-access";
import { IUpdateProductPriceType } from "@vfit/shared/models";
import { checkWindow, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { htmlModalManager } from "@vfit/shared/providers";
import { retrieveProduct } from "../iBuyFixed.utils";
/**
 * Method to update mobile product price
 */ export var updateProductPrice = function(product, updateType) {
    var ref, ref1;
    var oneTimeAmountCost = getOneTimeAmountFromShoppingCart();
    var recurringAmountCost = getCostFromShoppingCartByType(CART_TYPE.RECURRING);
    var shippingCost = getCostFromShoppingCartByType(CART_TYPE.SHIPPING);
    var shippingCostFormattedPrice = shippingCost === null || shippingCost === void 0 ? void 0 : shippingCost.formattedPrice;
    var oneTimeAmount = (oneTimeAmountCost === null || oneTimeAmountCost === void 0 ? void 0 : (ref = oneTimeAmountCost.formattedPrice) === null || ref === void 0 ? void 0 : ref.toString()) || 0;
    var recurringAmount = (recurringAmountCost === null || recurringAmountCost === void 0 ? void 0 : (ref1 = recurringAmountCost.formattedPrice) === null || ref1 === void 0 ? void 0 : ref1.toString()) || 0;
    var PLACEHOLDERS_ONETIME_RECURRENCE = [
        CART_TYPE.PLACEHOLDER_ONETIME,
        CART_TYPE.PLACEHOLDER_RECURRENCE, 
    ];
    var PLACEHOLDERS_ONETIME_RECURRENCE_SHIPPING = [
        CART_TYPE.PLACEHOLDER_ONETIME,
        CART_TYPE.PLACEHOLDER_RECURRENCE,
        CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, 
    ];
    var PLACEHOLDERS_ACTIVATION_RECURRING = [
        CART_TYPE.PLACEHOLDER_RECURRING,
        CART_TYPE.PLACEHOLDER_ONETIME,
        CART_TYPE.PLACEHOLDER_RECURRENCE, 
    ];
    var PLACEHOLDERS_ACTIVATION_RECURRING_SHIPPING = [
        CART_TYPE.PLACEHOLDER_RECURRING,
        CART_TYPE.PLACEHOLDER_ONETIME,
        CART_TYPE.PLACEHOLDER_RECURRENCE,
        CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, 
    ];
    var PLACEHOLDERS_RECURRING_RECURRENCE = [
        CART_TYPE.PLACEHOLDER_RECURRING,
        CART_TYPE.PLACEHOLDER_RECURRENCE, 
    ];
    var PLACEHOLDERS_RECURRING_RECURRENCE_SHIPPING = [
        CART_TYPE.PLACEHOLDER_RECURRING,
        CART_TYPE.PLACEHOLDER_RECURRENCE,
        CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, 
    ];
    var havePlaceholder = function(text, placeholders) {
        var checkPlaceholder = false;
        placeholders.forEach(function(el) {
            checkPlaceholder = checkPlaceholder || text.includes(el);
        });
        return checkPlaceholder;
    };
    if (product) {
        // region ONE TIME AND RECURRING
        if (oneTimeAmount && recurringAmount) {
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.priceActivationRecurring) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref2, ref3, ref4;
                product.price = ((ref4 = (ref3 = (ref2 = product.priceActivationRecurring) === null || ref2 === void 0 ? void 0 : ref2.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref3 === void 0 ? void 0 : ref3.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref4 === void 0 ? void 0 : ref4.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.subpriceActivationRecurring) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref5, ref6, ref7;
                product.subprice = ((ref7 = (ref6 = (ref5 = product.subpriceActivationRecurring) === null || ref5 === void 0 ? void 0 : ref5.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref6 === void 0 ? void 0 : ref6.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref7 === void 0 ? void 0 : ref7.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (shippingCostFormattedPrice && havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionActivationRecurringShipping) || "", PLACEHOLDERS_ACTIVATION_RECURRING_SHIPPING)) {
                var ref8, ref9, ref10, ref11;
                product.description = ((ref11 = (ref10 = (ref9 = (ref8 = product.descriptionActivationRecurringShipping) === null || ref8 === void 0 ? void 0 : ref8.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref9 === void 0 ? void 0 : ref9.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref10 === void 0 ? void 0 : ref10.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) === null || ref11 === void 0 ? void 0 : ref11.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, (shippingCostFormattedPrice === null || shippingCostFormattedPrice === void 0 ? void 0 : shippingCostFormattedPrice.toString()) || "")) || "";
            } else if (havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionActivationRecurring) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref12, ref13, ref14;
                product.description = ((ref14 = (ref13 = (ref12 = product.descriptionActivationRecurring) === null || ref12 === void 0 ? void 0 : ref12.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref13 === void 0 ? void 0 : ref13.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref14 === void 0 ? void 0 : ref14.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
        } else if (oneTimeAmount && !recurringAmount) {
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.priceActivation) || "", PLACEHOLDERS_ONETIME_RECURRENCE)) {
                var ref15, ref16;
                product.price = ((ref16 = (ref15 = product.priceActivation) === null || ref15 === void 0 ? void 0 : ref15.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref16 === void 0 ? void 0 : ref16.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.subpriceActivation) || "", PLACEHOLDERS_ONETIME_RECURRENCE)) {
                var ref17, ref18;
                product.subprice = ((ref18 = (ref17 = product.subpriceActivation) === null || ref17 === void 0 ? void 0 : ref17.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref18 === void 0 ? void 0 : ref18.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (shippingCostFormattedPrice && havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionActivationShipping) || "", PLACEHOLDERS_ONETIME_RECURRENCE_SHIPPING)) {
                var ref19, ref20, ref21;
                product.description = ((ref21 = (ref20 = (ref19 = product.descriptionActivationShipping) === null || ref19 === void 0 ? void 0 : ref19.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref20 === void 0 ? void 0 : ref20.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, (shippingCostFormattedPrice === null || shippingCostFormattedPrice === void 0 ? void 0 : shippingCostFormattedPrice.toString()) || "")) === null || ref21 === void 0 ? void 0 : ref21.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            } else if (havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionActivation) || "", PLACEHOLDERS_ONETIME_RECURRENCE)) {
                var ref22, ref23;
                product.description = ((ref23 = (ref22 = product.descriptionActivation) === null || ref22 === void 0 ? void 0 : ref22.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref23 === void 0 ? void 0 : ref23.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
        } else if (!oneTimeAmount && recurringAmount) {
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.priceRecurring) || "", PLACEHOLDERS_RECURRING_RECURRENCE)) {
                var ref24, ref25;
                product.price = ((ref25 = (ref24 = product.priceRecurring) === null || ref24 === void 0 ? void 0 : ref24.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref25 === void 0 ? void 0 : ref25.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.subpriceRecurring) || "", PLACEHOLDERS_RECURRING_RECURRENCE)) {
                var ref26, ref27;
                product.subprice = ((ref27 = (ref26 = product.subpriceRecurring) === null || ref26 === void 0 ? void 0 : ref26.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref27 === void 0 ? void 0 : ref27.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (shippingCostFormattedPrice && havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionRecurringShipping) || "", PLACEHOLDERS_RECURRING_RECURRENCE_SHIPPING)) {
                var ref28, ref29, ref30;
                product.description = ((ref30 = (ref29 = (ref28 = product.descriptionRecurringShipping) === null || ref28 === void 0 ? void 0 : ref28.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref29 === void 0 ? void 0 : ref29.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, (shippingCostFormattedPrice === null || shippingCostFormattedPrice === void 0 ? void 0 : shippingCostFormattedPrice.toString()) || "")) === null || ref30 === void 0 ? void 0 : ref30.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            } else if (havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionRecurring) || "", PLACEHOLDERS_RECURRING_RECURRENCE)) {
                var ref31, ref32;
                product.description = ((ref32 = (ref31 = product.descriptionRecurring) === null || ref31 === void 0 ? void 0 : ref31.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref32 === void 0 ? void 0 : ref32.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
        } else {
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.price) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref33, ref34, ref35;
                product.price = ((ref35 = (ref34 = (ref33 = product.price) === null || ref33 === void 0 ? void 0 : ref33.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref34 === void 0 ? void 0 : ref34.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || "")) === null || ref35 === void 0 ? void 0 : ref35.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.subprice) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref36, ref37, ref38;
                product.subprice = ((ref38 = (ref37 = (ref36 = product.subprice) === null || ref36 === void 0 ? void 0 : ref36.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref37 === void 0 ? void 0 : ref37.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || "")) === null || ref38 === void 0 ? void 0 : ref38.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.description) || "", PLACEHOLDERS_ACTIVATION_RECURRING_SHIPPING)) {
                var ref39, ref40, ref41, ref42;
                product.description = ((ref42 = (ref41 = (ref40 = (ref39 = product.description) === null || ref39 === void 0 ? void 0 : ref39.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref40 === void 0 ? void 0 : ref40.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || "")) === null || ref41 === void 0 ? void 0 : ref41.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, (shippingCostFormattedPrice === null || shippingCostFormattedPrice === void 0 ? void 0 : shippingCostFormattedPrice.toString()) || "")) === null || ref42 === void 0 ? void 0 : ref42.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
        }
        // endregion
        product.recurringCost = (recurringAmountCost === null || recurringAmountCost === void 0 ? void 0 : recurringAmountCost.price) || 0;
        product.oneTimeCost = (oneTimeAmountCost === null || oneTimeAmountCost === void 0 ? void 0 : oneTimeAmountCost.price) || 0;
        product.shippingCost = (shippingCost === null || shippingCost === void 0 ? void 0 : shippingCost.price) || 0;
        switch(updateType){
            case IUpdateProductPriceType.EDIT_WALLET:
                product.backupOfferDetails = JSON.parse(JSON.stringify(product.offerDetails));
                product.backupConditions = JSON.parse(JSON.stringify(product.conditions));
                product.offerDetails = product.offerDetailsWallet;
                product.conditions = product.conditionsWallet;
                break;
            case IUpdateProductPriceType.RESET:
                if (product.backupOfferDetails) {
                    product.offerDetails = product.backupOfferDetails;
                    delete product.backupOfferDetails;
                }
                if (product.backupConditions) {
                    product.conditions = product.backupConditions;
                    delete product.backupConditions;
                }
                break;
            default:
                break;
        }
        product.isUpdatedPriceWallet = updateType === IUpdateProductPriceType.EDIT_WALLET;
        updateProduct(product);
    }
    return product;
};
/**
 * Method to update shoppingCartUrl
 */ export var updateProductShoppingCartUrl = function(page) {
    var hub = page ? getHubPath(page) : "";
    var product = retrieveProduct(true);
    if (product && checkWindow()) {
        updateProduct(_object_spread_props(_object_spread({}, product, hub && {
            hubPath: hub
        }), {
            shoppingCartUrl: window.location.href
        }));
    }
};
export var getLocalAddress = function() {
    var addressData = getFromLocalStorageByKey("createdAddress");
    var dataToDisplay = [
        "".concat((addressData === null || addressData === void 0 ? void 0 : addressData.street) || "", " ").concat((addressData === null || addressData === void 0 ? void 0 : addressData.streetNumber) || ""),
        "".concat((addressData === null || addressData === void 0 ? void 0 : addressData.postalCode) || "", " ").concat(addressData === null || addressData === void 0 ? void 0 : addressData.city),
        "".concat((addressData === null || addressData === void 0 ? void 0 : addressData.stateOrProvince) || ""), 
    ];
    return _object_spread_props(_object_spread({}, addressData), {
        city: (addressData === null || addressData === void 0 ? void 0 : addressData.city) || "",
        postalCode: (addressData === null || addressData === void 0 ? void 0 : addressData.postalCode) || "",
        stateOrProvince: (addressData === null || addressData === void 0 ? void 0 : addressData.stateOrProvince) || "",
        street: (addressData === null || addressData === void 0 ? void 0 : addressData.street) || "",
        streetNumber: (addressData === null || addressData === void 0 ? void 0 : addressData.streetNumber) || "",
        latitude: Number(addressData === null || addressData === void 0 ? void 0 : addressData.latitude) || 0,
        longitude: Number(addressData === null || addressData === void 0 ? void 0 : addressData.longitude) || 0,
        displayedAddress: dataToDisplay.join(", ")
    });
};
export var getTechnology = function() {
    var offeringServiceAbility = getFromLocalStorageByKey("offeringServiceability");
    return organizeTechnology(offeringServiceAbility);
};
var organizeLableShoppingCart = function(tech, originalTechType) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    return {
        editAddress: (tech === null || tech === void 0 ? void 0 : (ref = tech.technology) === null || ref === void 0 ? void 0 : (ref1 = ref.bestOfferChoice) === null || ref1 === void 0 ? void 0 : ref1.editAddress) || "",
        bestOfferChoice: (tech === null || tech === void 0 ? void 0 : (ref2 = tech.technology) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.bestOfferChoice) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.text) === null || ref4 === void 0 ? void 0 : ref4.replace("$TECHNOLOGY", "".concat(originalTechType))) || "",
        bestOfferChoiceMap: (tech === null || tech === void 0 ? void 0 : (ref5 = tech.technology) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.bestOfferChoice) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.textForMap) === null || ref7 === void 0 ? void 0 : ref7.replace("$TECHNOLOGY", "".concat(originalTechType))) || ""
    };
};
export var getTechLabels = function(allTechs) {
    var tecnology = getTechnology();
    var tech;
    switch(tecnology === null || tecnology === void 0 ? void 0 : tecnology.mappedCategory){
        case TechnologyCategories.FTTH:
            tech = allTechs.ftth;
            break;
        case TechnologyCategories.FTTC:
            tech = allTechs.fttc;
            break;
        case TechnologyCategories.ADSL:
            tech = allTechs.adsl;
            break;
        case TechnologyCategories.FWAOUTDOOR:
            tech = allTechs.FWAOUTDOOR;
            break;
        case TechnologyCategories.FWAINDOOR:
            tech = allTechs.FWAINDOOR;
            break;
        case TechnologyCategories.FWAOUTDOOR5G:
            tech = allTechs.FWAOUTDOOR5G;
            break;
        case TechnologyCategories.FTTHTIM:
            tech = allTechs.FTTHTIM;
            break;
        case TechnologyCategories.FWAINDOOR5G:
            tech = allTechs.FWAINDOOR5G;
            break;
        default:
            break;
    }
    if (!(tecnology === null || tecnology === void 0 ? void 0 : tecnology.mappedCategory)) {
        return {
            editAddress: "",
            bestOfferChoice: "",
            bestOfferChoiceMap: ""
        };
    }
    return organizeLableShoppingCart(tech, tecnology === null || tecnology === void 0 ? void 0 : tecnology.shoppingCartTech);
};
export var isAppLockin = function() {
    var isAppLockinRetrieved = localStorage.getItem(START_FROM_APP_LOCKIN);
    return !!isAppLockinRetrieved && isAppLockinRetrieved === "true";
};
export var checkPopupVisibility = function(product) {
    var ref, ref1;
    if ((product === null || product === void 0 ? void 0 : (ref = product.cartPopup) === null || ref === void 0 ? void 0 : ref.isEnabled) && (product === null || product === void 0 ? void 0 : (ref1 = product.cartPopup) === null || ref1 === void 0 ? void 0 : ref1.htmlDesktop)) {
        var ref2, ref3, ref4, ref5, ref6, ref7, ref8;
        var formatString = function(str) {
            var ref, ref1;
            return (ref1 = (ref = str === null || str === void 0 ? void 0 : str.replace(Placeholders.TECHNOLOGY, retrieveProductTechnology())) === null || ref === void 0 ? void 0 : ref.replace(Placeholders.PRICE, (product === null || product === void 0 ? void 0 : product.price) || "")) === null || ref1 === void 0 ? void 0 : ref1.replace(Placeholders.ORIGINAL_PRICE, (product === null || product === void 0 ? void 0 : product.originalPrice) || "");
        };
        var htmlDesktop = formatString((product === null || product === void 0 ? void 0 : (ref2 = product.cartPopup) === null || ref2 === void 0 ? void 0 : ref2.htmlDesktop) || "");
        var htmlTablet = formatString((product === null || product === void 0 ? void 0 : (ref3 = product.cartPopup) === null || ref3 === void 0 ? void 0 : ref3.htmlTablet) || "");
        var htmlMobile = formatString((product === null || product === void 0 ? void 0 : (ref4 = product.cartPopup) === null || ref4 === void 0 ? void 0 : ref4.htmlMobile) || "");
        htmlModalManager.handleHtmlModal({
            target: (product === null || product === void 0 ? void 0 : (ref5 = product.cmsId) === null || ref5 === void 0 ? void 0 : ref5.toString()) || "",
            isEnabledAlwaysShown: (product === null || product === void 0 ? void 0 : (ref6 = product.cartPopup) === null || ref6 === void 0 ? void 0 : ref6.isEnabledAlwaysShown) || false,
            isEnabled: (product === null || product === void 0 ? void 0 : (ref7 = product.cartPopup) === null || ref7 === void 0 ? void 0 : ref7.isEnabled) || false,
            timerToDisplay: (product === null || product === void 0 ? void 0 : (ref8 = product.cartPopup) === null || ref8 === void 0 ? void 0 : ref8.timerToDisplay) || undefined,
            htmlDesktop: htmlDesktop || "",
            htmlTablet: htmlTablet || "",
            htmlMobile: htmlMobile || ""
        });
    }
};
