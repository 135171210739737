export var IOperationType;
(function(IOperationType) {
    IOperationType["ERROR"] = "error";
    IOperationType["ADD"] = "add";
    IOperationType["EDIT"] = "edit";
})(IOperationType || (IOperationType = {}));
export var IContactType;
(function(IContactType) {
    IContactType["EMAIL"] = "email";
    IContactType["MSISDN"] = "phone";
})(IContactType || (IContactType = {}));
