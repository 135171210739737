import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  width: 287px;\n\n  @media (min-width: ",
        ") {\n    width: 366px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 406px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 0 16px;\n  font-family: ",
        ";\n  text-align: center;\n  font-weight: 400;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    padding: 60px 0 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  margin-top: ",
        ";\n  margin-bottom: ",
        ";\n  text-align: center;\n  font-weight: 400;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n\n  div {\n    margin-top: 0;\n  }\n\n  p {\n    position: relative;\n    margin-bottom: 0;\n  }\n\n  gap: ",
        ";\n\n  @media (min-width: ",
        ") {\n    gap: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  margin-top: ",
        ";\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-top: 3.75rem;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-957295f6-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-957295f6-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Text = styled.div.withConfig({
    componentId: "sc-957295f6-2"
})(_templateObject2(), fonts.regular, pxToRem(16), pxToRem(32), pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop, pxToRem(40));
export var Div = styled.div.withConfig({
    componentId: "sc-957295f6-3"
})(_templateObject3(), pxToRem(16), breakpoints.tablet, pxToRem(20));
export var Footer = styled.div.withConfig({
    componentId: "sc-957295f6-4"
})(_templateObject4(), fonts.regular, pxToCssFont(14, 18), pxToRem(16), pxToRem(32), breakpoints.tablet, pxToRem(20));
export var Skeleton = styled.div.withConfig({
    componentId: "sc-957295f6-5"
})(_templateObject5());
