import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: 48px;\n\n  h3 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  h4 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    margin-top: 14px;\n    margin-bottom: 0;\n  }\n\n  #edit-address {\n    text-decoration-line: underline;\n    text-underline-position: from-font;\n    width: max-content;\n\n    &:hover {\n      color: ",
        ";\n    }\n\n    &:active {\n      color: ",
        ";\n    }\n  }\n\n  p {\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        "\n    color: ",
        ";\n    margin: 0;\n    margin-top: 16px;\n    cursor: pointer;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-left: 32px;\n    margin-right: 32px;\n\n    h3 {\n      ",
        "\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    p {\n      ",
        "\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    margin-left: 80px;\n    margin-right: 80px;\n\n    h3 {\n      ",
        "\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    p {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var ShoppingCartOfferContainer = styled.div.withConfig({
    componentId: "sc-4351b6fc-0"
})(_templateObject(), fonts.exbold, pxToCssFontSize(20), fonts.light, pxToCssFontSize(20), colors.$e60000, colors.$e60000, fonts.regular, pxToCssFont(18, 26), colors.$262626, breakpoints.tablet, pxToCssFontSize(30), pxToCssFontSize(24), pxToCssFontSize(18), breakpoints.desktop, pxToCssFont(36, 38), pxToCssFontSize(24), pxToCssFontSize(18));
