export var organizeTermsCard = function(cmsData) {
    var ref, ref1, ref2, ref3;
    var cmsCard = (cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.termsandconditionsmo) === null || ref === void 0 ? void 0 : ref.card) || undefined;
    return {
        title: (cmsCard === null || cmsCard === void 0 ? void 0 : cmsCard.title) || "",
        description: (cmsCard === null || cmsCard === void 0 ? void 0 : cmsCard.description) || "",
        documents: (ref1 = cmsData.termsandconditionsmo.modals) === null || ref1 === void 0 ? void 0 : ref1.map(function(modals) {
            return {
                text: (modals === null || modals === void 0 ? void 0 : modals.title) || "",
                content: (modals === null || modals === void 0 ? void 0 : modals.description) || "",
                pdfUrl: (modals === null || modals === void 0 ? void 0 : modals.url) || "",
                pdfLabel: (modals === null || modals === void 0 ? void 0 : modals.buttonLabel) || ""
            };
        }),
        documentsCheck: cmsCard.conditions,
        titleModal: (cmsCard === null || cmsCard === void 0 ? void 0 : (ref2 = cmsCard.modalError) === null || ref2 === void 0 ? void 0 : ref2.title) || "",
        messageModal: (cmsCard === null || cmsCard === void 0 ? void 0 : (ref3 = cmsCard.modalError) === null || ref3 === void 0 ? void 0 : ref3.message) || ""
    };
};
