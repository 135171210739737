import { fonts, pxToLineHeight } from "@vfit/shared/themes";
export var organizeOtpWinback = function(dataFromCms, phoneNumber) {
    var ref, ref1, ref2, ref3, ref4;
    return {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.title) || "",
        description: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.description) || "",
        codeResponseDescription: ((ref = dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.codeResponse) === null || ref === void 0 ? void 0 : ref.description) || "",
        instructionText: {
            text: "".concat((ref1 = dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.telephoneVerify) === null || ref1 === void 0 ? void 0 : ref1.replace("$number", '<b style="color:red">'.concat(phoneNumber, "</b>"))),
            fontFamily: fonts.exbold,
            size: 20,
            lineHeight: pxToLineHeight(20, 26)
        },
        alerts: {
            error: ((ref2 = dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.codeResponse) === null || ref2 === void 0 ? void 0 : ref2.alertWarn) || "",
            sentNew: ((ref3 = dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.codeResponse) === null || ref3 === void 0 ? void 0 : ref3.alertSuccess) || ""
        },
        otpError: ((ref4 = dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.codeResponse) === null || ref4 === void 0 ? void 0 : ref4.otpError) || "",
        detail: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.codeMissingLabel) || "",
        links: {
            sendNew: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.codeNewLabel) || "",
            noAccess: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.codeNotAccessLabel) || "",
            urlNoAccess: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.winbacknumber.otp.contactNotAccessUrl) || ""
        }
    };
};
export var organizePopupLabels = function(dataFromCms) {
    var ref;
    var popupLabels = dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.winbacknumber) === null || ref === void 0 ? void 0 : ref.errorPopup;
    return {
        titlePopup: popupLabels === null || popupLabels === void 0 ? void 0 : popupLabels.title,
        messagePopup: popupLabels === null || popupLabels === void 0 ? void 0 : popupLabels.message,
        ctaLabel: popupLabels === null || popupLabels === void 0 ? void 0 : popupLabels.ctaLabel,
        buttonLabel: popupLabels === null || popupLabels === void 0 ? void 0 : popupLabels.secondButtonLabel
    };
};
