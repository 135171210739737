var checkCampaignEligibility = function(product, campaignsData) {
    // FixedLine type filter campaignsOffer
    var fixedCampaignsList = (campaignsData === null || campaignsData === void 0 ? void 0 : campaignsData.filter(function(item) {
        var ref;
        return (item === null || item === void 0 ? void 0 : (ref = item.type) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "fixed";
    })) || [];
    if ((fixedCampaignsList === null || fixedCampaignsList === void 0 ? void 0 : fixedCampaignsList.length) === 0) {
        return undefined;
    }
    var lockInOffersFound = fixedCampaignsList === null || fixedCampaignsList === void 0 ? void 0 : fixedCampaignsList.find(function(item) {
        var ref, ref1, ref2;
        return (item === null || item === void 0 ? void 0 : (ref = item.fixedBundle) === null || ref === void 0 ? void 0 : (ref1 = ref.productOfferId) === null || ref1 === void 0 ? void 0 : ref1.toString()) === (product === null || product === void 0 ? void 0 : (ref2 = product.offerId) === null || ref2 === void 0 ? void 0 : ref2.toString());
    });
    if (!lockInOffersFound) {
        return undefined;
    }
    return lockInOffersFound;
};
var getEligibilityCampaign = function(product, campaignsData) {
    return checkCampaignEligibility(product, campaignsData);
};
export { getEligibilityCampaign };
