import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { CMS_CONFIG, ErrorCodes, errorMock, useCmsConfig, useGetCustomerReference } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { API, getFromLocalStorageByKey, openPopup, PAGES } from "@vfit/shared/data-access";
import { resetData } from "@vfit/shared/providers";
import { useEffect } from "react";
import { retrieveOwningIndividual } from "../../checkout.utils";
import { useCheckout } from "../../../iBuyFixed.context";
export var useIAddFirstCardFlow = function(props) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14;
    var handleOnClose = props.handleOnClose;
    var ref15 = useCheckout(), product = ref15.product, findCaller = ref15.findCaller, customerFlow = ref15.customerFlow, customerAsyncInfo = ref15.customerAsyncInfo, owningData = ref15.owningData, checkoutErrors = ref15.checkoutErrors, isModalButtonSticky = ref15.isModalButtonSticky, lockInAppFlow = ref15.lockInAppFlow, setIsStartLockInAppFlow = ref15.setIsStartLockInAppFlow, setIsDisabledBackButton = ref15.setIsDisabledBackButton, setCustomerId = ref15.setCustomerId, setOwningData = ref15.setOwningData, setIsStartCustomerFlow = ref15.setIsStartCustomerFlow, setFindCaller = ref15.setFindCaller;
    var queryClient = useQueryClient();
    var countries = getFromLocalStorageByKey("getCountry");
    var otpVerifyResponse = {
        id: findCaller.verifyOtp
    };
    var ref16 = useGetCustomerReference(), isReferenceSuccess = ref16.isSuccess, isReferenceLoading = ref16.isLoading, isReferenceError = ref16.isError, errorCustomerReference = ref16.error;
    var genericError = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GENERIC_ERROR);
    var ref17 = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_ORDER_ERROR), errors = ref17.errorcomponent.errors;
    var blockedCustomer = errors === null || errors === void 0 ? void 0 : errors.find(function(item) {
        return (item === null || item === void 0 ? void 0 : item.error) === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED;
    });
    var isLoadingLockin = (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref = lockInAppFlow.campaigns) === null || ref === void 0 ? void 0 : ref.isLoading) || (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref1 = lockInAppFlow.accept) === null || ref1 === void 0 ? void 0 : ref1.isLoading) || (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref2 = lockInAppFlow.shoppingCart) === null || ref2 === void 0 ? void 0 : ref2.isLoading) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref3 = customerFlow.associateCustomer) === null || ref3 === void 0 ? void 0 : ref3.isLoading);
    var isSuccessLockin = (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref4 = lockInAppFlow.campaigns) === null || ref4 === void 0 ? void 0 : ref4.isSuccess) && (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref5 = lockInAppFlow.accept) === null || ref5 === void 0 ? void 0 : ref5.isSuccess) && (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref6 = lockInAppFlow.shoppingCart) === null || ref6 === void 0 ? void 0 : ref6.isSuccess) && (customerFlow === null || customerFlow === void 0 ? void 0 : (ref7 = customerFlow.associateCustomer) === null || ref7 === void 0 ? void 0 : ref7.isSuccess);
    var isLoadingCustomerInfo = isReferenceLoading || !isReferenceSuccess || !!(otpVerifyResponse === null || otpVerifyResponse === void 0 ? void 0 : otpVerifyResponse.id) && !(customerFlow === null || customerFlow === void 0 ? void 0 : (ref8 = customerFlow.associateCustomer) === null || ref8 === void 0 ? void 0 : ref8.isSuccess);
    var isErrorLockin = (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref9 = lockInAppFlow.campaigns) === null || ref9 === void 0 ? void 0 : ref9.isError) || (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref10 = lockInAppFlow.accept) === null || ref10 === void 0 ? void 0 : ref10.isError) || (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref11 = lockInAppFlow.shoppingCart) === null || ref11 === void 0 ? void 0 : ref11.isError) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref12 = customerFlow.associateCustomer) === null || ref12 === void 0 ? void 0 : ref12.isError) || (lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref13 = lockInAppFlow.campaigns) === null || ref13 === void 0 ? void 0 : ref13.isSuccess) && !(lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref14 = lockInAppFlow.checkEligibility) === null || ref14 === void 0 ? void 0 : ref14.eligible);
    var preconditionCustomer = function() {
        var ref, ref1;
        if ((customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.associateCustomer) === null || ref === void 0 ? void 0 : ref.isSuccess) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref1 = customerFlow.associateCustomer) === null || ref1 === void 0 ? void 0 : ref1.isError) || isReferenceError) {
            var ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
            setFindCaller(_object_spread_props(_object_spread({}, findCaller, (customerFlow === null || customerFlow === void 0 ? void 0 : (ref2 = customerFlow.findcaller) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.data) === null || ref3 === void 0 ? void 0 : ref3.paymean) && {
                paymean: customerFlow === null || customerFlow === void 0 ? void 0 : (ref4 = customerFlow.findcaller) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.data) === null || ref5 === void 0 ? void 0 : ref5.paymean
            }), {
                showOtp: false,
                verifyOtp: "",
                isVerified: true
            }));
            if ((customerFlow === null || customerFlow === void 0 ? void 0 : (ref6 = customerFlow.associateCustomer) === null || ref6 === void 0 ? void 0 : ref6.isError) && (customerFlow === null || customerFlow === void 0 ? void 0 : (ref7 = customerFlow.associateCustomer) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.error) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.error) === null || ref9 === void 0 ? void 0 : ref9.statusText) === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED) {
                var ref11, ref12, ref13, ref14, ref15;
                var handleClose = function() {
                    if (handleOnClose) {
                        resetData(queryClient);
                        handleOnClose();
                    }
                };
                var goBackAndReset = function() {
                    var ref;
                    if (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref = blockedCustomer.button) === null || ref === void 0 ? void 0 : ref.urlForRedirect) {
                        var ref1;
                        openPopup((blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref1 = blockedCustomer.button) === null || ref1 === void 0 ? void 0 : ref1.urlForRedirect) || "", function() {
                            if (handleOnClose) {
                                resetData(queryClient);
                                handleOnClose();
                            }
                        });
                    } else if (handleOnClose) {
                        resetData(queryClient);
                        handleOnClose();
                    }
                };
                checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref15 = checkoutErrors.showAndTrackError) === null || ref15 === void 0 ? void 0 : ref15.call(checkoutErrors, genericError, function() {
                    return handleClose();
                }, (customerFlow === null || customerFlow === void 0 ? void 0 : (ref11 = customerFlow.associateCustomer) === null || ref11 === void 0 ? void 0 : ref11.isError) ? customerFlow === null || customerFlow === void 0 ? void 0 : (ref12 = customerFlow.associateCustomer) === null || ref12 === void 0 ? void 0 : ref12.error : undefined, (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : blockedCustomer.title) || "Attenzione", (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : blockedCustomer.message) || "I seguenti articoli non sono attivabili dal cliente selezionato", function() {
                    return goBackAndReset();
                }, (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref13 = blockedCustomer.button) === null || ref13 === void 0 ? void 0 : ref13.title) || "Ti richiamiamo", function() {
                    var ref;
                    return openPopup((product === null || product === void 0 ? void 0 : (ref = product.genericErrorCallMeNow) === null || ref === void 0 ? void 0 : ref.url) || "", handleClose);
                }, product === null || product === void 0 ? void 0 : (ref14 = product.genericErrorCallMeNow) === null || ref14 === void 0 ? void 0 : ref14.label, isModalButtonSticky);
            } else if ((customerFlow === null || customerFlow === void 0 ? void 0 : (ref10 = customerFlow.associateCustomer) === null || ref10 === void 0 ? void 0 : ref10.isError) || isReferenceError) {
                var ref16, ref17, ref18, ref19, ref20;
                var error;
                if (isReferenceError && errorCustomerReference) {
                    error = errorMock(ErrorCodes.REFERENCE_CUSTOMER, errorCustomerReference);
                }
                if ((customerFlow === null || customerFlow === void 0 ? void 0 : (ref16 = customerFlow.associateCustomer) === null || ref16 === void 0 ? void 0 : ref16.isError) && (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref17 = customerAsyncInfo.customerInfo) === null || ref17 === void 0 ? void 0 : ref17.isError)) {
                    var ref21;
                    error = customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref21 = customerAsyncInfo.customerInfo) === null || ref21 === void 0 ? void 0 : ref21.error;
                }
                checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref20 = checkoutErrors.showAndTrackError) === null || ref20 === void 0 ? void 0 : ref20.call(checkoutErrors, genericError, handleOnClose, _object_spread_props(_object_spread({}, error), {
                    isBlocking: true
                }), "", "", handleOnClose, (genericError === null || genericError === void 0 ? void 0 : (ref18 = genericError.genericerror) === null || ref18 === void 0 ? void 0 : ref18.buttonLabel) || "", function() {
                    var ref;
                    return openPopup((product === null || product === void 0 ? void 0 : (ref = product.genericErrorCallMeNow) === null || ref === void 0 ? void 0 : ref.url) || "", function() {
                        return handleOnClose;
                    });
                }, product === null || product === void 0 ? void 0 : (ref19 = product.genericErrorCallMeNow) === null || ref19 === void 0 ? void 0 : ref19.label, isModalButtonSticky);
            }
        }
    };
    var showError = function() {
        var ref, ref1, ref2, ref3;
        var error = (customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.findcaller) === null || ref === void 0 ? void 0 : ref.error) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref1 = customerFlow.customerInfo) === null || ref1 === void 0 ? void 0 : ref1.error);
        checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref3 = checkoutErrors.showAndTrackError) === null || ref3 === void 0 ? void 0 : ref3.call(checkoutErrors, genericError, function() {
            var ref;
            lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref = lockInAppFlow.resetLockInAppFlow) === null || ref === void 0 ? void 0 : ref.call(lockInAppFlow);
            handleOnClose === null || handleOnClose === void 0 ? void 0 : handleOnClose();
        }, _object_spread_props(_object_spread({}, error), {
            isBlocking: true
        }), undefined, undefined, function() {
            var ref;
            lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref = lockInAppFlow.resetLockInAppFlow) === null || ref === void 0 ? void 0 : ref.call(lockInAppFlow);
            handleOnClose === null || handleOnClose === void 0 ? void 0 : handleOnClose();
        }, (genericError === null || genericError === void 0 ? void 0 : (ref2 = genericError.genericerror) === null || ref2 === void 0 ? void 0 : ref2.buttonLabel) || "Chiudi");
    };
    useEffect(function() {
        var ref;
        preconditionCustomer();
        if (customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.associateCustomer) === null || ref === void 0 ? void 0 : ref.isSuccess) {
            var ref1, ref2;
            var customer = customerFlow === null || customerFlow === void 0 ? void 0 : (ref1 = customerFlow.customerInfo) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.data) === null || ref2 === void 0 ? void 0 : ref2[0];
            if (customer) {
                var retrivedIndividual = retrieveOwningIndividual(customer, countries);
                setOwningData(_object_spread_props(_object_spread({}, owningData), {
                    owningIndividual: _object_spread({}, owningData.owningIndividual, retrivedIndividual)
                }));
                setCustomerId(customer.id);
            }
        }
    }, [
        lockInAppFlow,
        isReferenceError,
        customerAsyncInfo
    ]);
    useEffect(function() {
        var ref, ref1, ref2;
        if (isSuccessLockin && ((ref = customerFlow.findcaller) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.customerRef) === null || ref2 === void 0 ? void 0 : ref2.id)) {
            var ref3, ref4, ref5;
            setCustomerId((ref3 = customerFlow.findcaller) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.data) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.customerRef) === null || ref5 === void 0 ? void 0 : ref5.id);
        }
    }, [
        isSuccessLockin
    ]);
    useEffect(function() {
        if (isErrorLockin) {
            // TODO: Manage error if user is NOT eligible
            // TODO: Create cart with fallback offer and associate customer
            showError();
        }
    }, [
        isErrorLockin
    ]);
    useEffect(function() {
        setIsDisabledBackButton(isLoadingCustomerInfo || isLoadingLockin);
    }, [
        isLoadingLockin,
        isLoadingCustomerInfo
    ]);
    useEffect(function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6;
        if ((customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.findcaller) === null || ref === void 0 ? void 0 : ref.isSuccess) && (customerFlow === null || customerFlow === void 0 ? void 0 : (ref1 = customerFlow.findcaller) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.data) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id) && !(lockInAppFlow === null || lockInAppFlow === void 0 ? void 0 : (ref4 = lockInAppFlow.campaigns) === null || ref4 === void 0 ? void 0 : ref4.isSuccess)) {
            setIsStartLockInAppFlow(true);
        }
        if ((customerFlow === null || customerFlow === void 0 ? void 0 : (ref5 = customerFlow.findcaller) === null || ref5 === void 0 ? void 0 : ref5.isError) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref6 = customerFlow.customerInfo) === null || ref6 === void 0 ? void 0 : ref6.isError)) {
            showError();
        }
    }, [
        customerFlow
    ]);
    useEffect(function() {
        setFindCaller(_object_spread_props(_object_spread({}, findCaller), {
            showOtp: false
        }));
        setIsStartCustomerFlow(true);
    }, []);
    return {
        isLoading: isLoadingLockin || isLoadingCustomerInfo
    };
};
