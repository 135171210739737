import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 0 20px;\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    padding: 0 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 0 74px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-top: 159px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 234px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 244px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n  font-family: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    margin-top: 24px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 20px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n  font-family: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    margin-top: 8px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-5ffbc40d-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var InnerContainer = styled.div.withConfig({
    componentId: "sc-5ffbc40d-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var Paragraph = styled.p.withConfig({
    componentId: "sc-5ffbc40d-2"
})(_templateObject2(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-5ffbc40d-3"
})(_templateObject3(), fonts.exbold, pxToCssFont(35, 38), colors.$e60000, breakpoints.tablet, pxToCssFont(42, 52));
export var Subtitle = styled(Paragraph).withConfig({
    componentId: "sc-5ffbc40d-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(24, 28), breakpoints.tablet, pxToCssFont(24, 30), breakpoints.desktop);
export var Description = styled(Paragraph).withConfig({
    componentId: "sc-5ffbc40d-5"
})(_templateObject5(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop, pxToCssFont(20, 26));
