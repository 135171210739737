import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-top: 24px;\n  margin-bottom: 32px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 45px;\n\n  @media (min-width: ",
        ") {\n    width: 258px;\n    margin: 0 auto;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 296px;\n    margin: 0 auto;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 24px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 40px;\n  }\n\n  div {\n    .textAreaInputFieldset {\n      height: 100px;\n\n      @media (min-width: ",
        ") {\n        height: 120px;\n      }\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-716c9f63-0"
})(_templateObject(), colors.$ffffff);
export var Paragraph = styled.p.withConfig({
    componentId: "sc-716c9f63-1"
})(_templateObject1(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-716c9f63-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled(Paragraph).withConfig({
    componentId: "sc-716c9f63-3"
})(_templateObject3(), fonts.regular, pxToCssFont(18, 24));
export var CtaContainer = styled.div.withConfig({
    componentId: "sc-716c9f63-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop);
export var InputContainer = styled.div.withConfig({
    componentId: "sc-716c9f63-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop, breakpoints.tablet);
