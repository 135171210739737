import { checkBypassApiCallFromSIA, errorMock, useDeliveryBaseV1 } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
export var useDeliveryBaseFlow = function(param) {
    var enabled = param.enabled, isEsim = param.isEsim;
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(""), errorMessage = ref3[0], setErrorMessage = ref3[1];
    var ref4 = useState(undefined), error = ref4[0], setError = ref4[1];
    var ref5 = useState([]), deliveryOptions = ref5[0], setDeliveryOptions = ref5[1];
    var ref6 = useState([]), identificationDetails = ref6[0], setIdentificationDetails = ref6[1];
    var enabler = !checkBypassApiCallFromSIA() && !isError && enabled;
    var ref7 = useDeliveryBaseV1(isEsim || false, {
        enabled: enabler,
        keyDependency: [
            "deliveryDetailsIsBaseMobile"
        ]
    }), deliveryBaseData = ref7.data, deliveryBaseError = ref7.error, deliveryBaseIsSuccess = ref7.isSuccess, deliveryBaseIsLoading = ref7.isLoading, deliveryBaseIsError = ref7.isError;
    useEffect(function() {
        if (deliveryBaseIsLoading) {
            setIsSuccess(false);
            setIsLoading(true);
            setIsError(false);
        } else if (deliveryBaseIsSuccess && !isSuccess && !checkBypassApiCallFromSIA()) {
            var ref, ref1;
            setDeliveryOptions(Array.isArray(deliveryBaseData) ? (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref = deliveryBaseData[0]) === null || ref === void 0 ? void 0 : ref.deliveryDetails) || [] : (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : deliveryBaseData.deliveryDetails) || []);
            setIdentificationDetails(Array.isArray(deliveryBaseData) ? (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref1 = deliveryBaseData[0]) === null || ref1 === void 0 ? void 0 : ref1.identificationDetails) || {} : (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : deliveryBaseData.identificationDetails) || {});
            setIsLoading(false);
            setIsSuccess(true);
        }
    }, [
        deliveryBaseIsSuccess,
        deliveryBaseIsLoading,
        deliveryOptions
    ]);
    useEffect(function() {
        if (deliveryBaseIsError) {
            setIsLoading(false);
            setIsError(true);
            setError(errorMock("deliveryBase", deliveryBaseError));
            setErrorMessage(deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : deliveryBaseData.errorMessage);
        }
    }, [
        deliveryBaseIsError,
        deliveryBaseData
    ]);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        errorMessage: errorMessage,
        deliveryOptions: deliveryOptions,
        identificationDetails: identificationDetails,
        error: error
    };
};
