import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { CMS_CONFIG, ErrorCodes, errorMock, getCurrentUserType, IUserType, useAssociateCustomer, useBillingAccount, useCmsConfig, useCustomer, useGetCustomer, useGetFindCaller, useGetPaymean, usePatchCustomer, usePayment, useUpdateProductCharacteristic } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
import { API, computeInverse, getFromLocalStorageByKey, PAGES } from "@vfit/shared/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { useCheckout } from "../../../iBuyMobile.context";
import { getBSNewMopMobile, getErrorStatusText, getPatchPayload } from "./useCustomerFlow.utils";
import { ID_FLOWS } from "../../checkout.constants";
import { handleCRMErrorCodes } from "../../checkout.utils";
/**
 * Customer flow
 * if the user is NEXT ACTIVE call this flow
 * findcaller - getCustomer - familyPlusFlow if is not winback - associatecustomer - payment - billingaccount - paymean - updateproductcharacteristics
 * if the user in Prospect or Logged call
 * patchCustomer/postCustomer - getCustomer - associatecustomer - payment - billingaccount - paymean - updateproductcharacteristics
 * if user is logged
 * familyPlusFlow if is not winback - associatecustomer - payment - billingaccount - paymean - updateproductcharacteristics
 */ export var useCustomerFlow = function() {
    var ref, ref1;
    var ref2 = useCheckout(), isLoadingCart = ref2.isLoadingCart, isStartCustomerFlow = ref2.isStartCustomerFlow, owningData = ref2.owningData, customerFlow = ref2.customerFlow, currentStepKey = ref2.currentStepKey, familyPlus = ref2.familyPlus, isStartFamilyPlus = ref2.isStartFamilyPlus, findCallerPreOTP = ref2.findCaller, product = ref2.product, setCustomerFlow = ref2.setCustomerFlow, setContextCustomerId = ref2.setCustomerId, setIsStartCustomerFlow = ref2.setIsStartCustomerFlow, setIsStartFamilyPlus = ref2.setIsStartFamilyPlus, setBillingEmail = ref2.setBillingEmail;
    var globalConfig = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG);
    var ref3 = useState(""), customerId = ref3[0], setCustomerId = ref3[1];
    var isNextActive = !!(owningData.isValid && findCallerPreOTP.verifyOtp);
    var isLogged = getCurrentUserType() === IUserType.LOGGED_USER;
    // region APIs for customer flow
    var ref4 = useAssociateCustomer(), associateCustomerData = ref4.data, associateCustomerError = ref4.error, associateCustomerMutate = ref4.mutate, isSuccessAssociateCustomer = ref4.isSuccess, isErrorAssociateCustomer = ref4.isError, isLoadingAssociateCustomer = ref4.isLoading;
    var ref5 = usePatchCustomer(), patchCustomerData = ref5.data, patchCustomerError = ref5.error, patchCustomerMutate = ref5.mutate, isSuccessPatchCustomer = ref5.isSuccess, isErrorPatchCustomer = ref5.isError, isLoadingPatchCustomer = ref5.isLoading;
    var ref6 = useCustomer(), postCustomerData = ref6.data, postCustomerError = ref6.error, postCustomerMutate = ref6.mutate, isSuccessPostCustomer = ref6.isSuccess, isErrorPostCustomer = ref6.isError, isLoadingPostCustomer = ref6.isLoading;
    var ref7 = useGetCustomer(customerId, {
        enabled: false
    }), customerData = ref7.data, customerError = ref7.error, isSuccessGetCustomer = ref7.isSuccess, isErrorGetCustomer = ref7.isError, isLoadingGetCustomer = ref7.isLoading, refetchGetCustomer = ref7.refetch;
    var ref8 = usePayment({
        enabled: false
    }), paymentError = ref8.error, paymentData = ref8.data, isSuccessPayment = ref8.isSuccess, isLoadingPayment = ref8.isLoading, isErrorPayment = ref8.isError, refetchPayment = ref8.refetch;
    var ref9 = useGetPaymean({
        enabled: false
    }, globalConfig), paymeanError = ref9.error, paymeanData = ref9.data, isSuccessPaymean = ref9.isSuccess, isLoadingPaymean = ref9.isLoading, isErrorPaymean = ref9.isError, refetchPaymean = ref9.refetch;
    var ref10 = useUpdateProductCharacteristic(), mutateUpdateProductCharacteristics = ref10.mutate, updateProductCharacteristicsError = ref10.error, isErrorUpdateProductCharacteristics = ref10.isError, isSuccessUpdateProductCharacteristics = ref10.isSuccess, isLoadingUpdateProductCharacteristics = ref10.isLoading;
    var ref11 = useBillingAccount({
        enabled: false
    }, globalConfig), billingAccountData = ref11.data, billingAccountError = ref11.error, isBillingAccountLoading = ref11.isLoading, isBillingAccountError = ref11.isError, isBillingAccountSuccess = ref11.isSuccess, refetchBillingAccount = ref11.refetch;
    var ref12 = useGetFindCaller(_object_spread({
        fiscalCode: owningData === null || owningData === void 0 ? void 0 : (ref = owningData.owningIndividual) === null || ref === void 0 ? void 0 : (ref1 = ref.fiscalCode) === null || ref1 === void 0 ? void 0 : ref1.toUpperCase()
    }, (product === null || product === void 0 ? void 0 : product.isWinback) && {
        isWinbackFlow: true
    }), {
        enabled: false
    }), findcallerData = ref12.data, findcallerError = ref12.error, isErrorFindcaller = ref12.isError, isSuccessFindcaller = ref12.isSuccess, isLoadingFindcaller = ref12.isLoading, refetchFindcaller = ref12.refetch;
    // endregion
    var isAlreadyDoneAssociateCustomer = function() {
        var alreadyDoneAssociateCustomer = getFromLocalStorageByKey("associateCustomer");
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        return !!shoppingCart && !!alreadyDoneAssociateCustomer;
    };
    var checkAssociateCustomer = function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
        var CUSTOMER_IS_ELIGIBLE_FOR_FAMILY_PLUS = !isLoadingCart && (familyPlus === null || familyPlus === void 0 ? void 0 : (ref = familyPlus.campaign) === null || ref === void 0 ? void 0 : ref.isSuccess) && (familyPlus === null || familyPlus === void 0 ? void 0 : (ref1 = familyPlus.campaign) === null || ref1 === void 0 ? void 0 : ref1.isEligible);
        var CUSTOMER_IS_NOT_ELIGIBLE_FOR_FAMILY_PLUS = !isLoadingCart && (familyPlus === null || familyPlus === void 0 ? void 0 : (ref2 = familyPlus.campaign) === null || ref2 === void 0 ? void 0 : ref2.isSuccess) && !(familyPlus === null || familyPlus === void 0 ? void 0 : (ref3 = familyPlus.campaign) === null || ref3 === void 0 ? void 0 : ref3.isEligible);
        var CHECK_FAMILY_PLUS = (familyPlus === null || familyPlus === void 0 ? void 0 : (ref4 = familyPlus.campaign) === null || ref4 === void 0 ? void 0 : ref4.isSuccess) && (familyPlus === null || familyPlus === void 0 ? void 0 : (ref5 = familyPlus.campaign) === null || ref5 === void 0 ? void 0 : ref5.isRejected) || (familyPlus === null || familyPlus === void 0 ? void 0 : (ref6 = familyPlus.campaign) === null || ref6 === void 0 ? void 0 : ref6.isSuccess) && (familyPlus === null || familyPlus === void 0 ? void 0 : (ref7 = familyPlus.accept) === null || ref7 === void 0 ? void 0 : ref7.isSuccess) && (familyPlus === null || familyPlus === void 0 ? void 0 : (ref8 = familyPlus.accept) === null || ref8 === void 0 ? void 0 : ref8.acceptId) && (familyPlus === null || familyPlus === void 0 ? void 0 : (ref9 = familyPlus.updateCart) === null || ref9 === void 0 ? void 0 : ref9.isSuccess) || (familyPlus === null || familyPlus === void 0 ? void 0 : familyPlus.campaign.isError);
        // If is familyplus MM
        if (product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct) {
            if (CUSTOMER_IS_ELIGIBLE_FOR_FAMILY_PLUS) {
                if (CHECK_FAMILY_PLUS && !isAlreadyDoneAssociateCustomer()) {
                    associateCustomerMutate();
                }
            }
        } else if (CUSTOMER_IS_ELIGIBLE_FOR_FAMILY_PLUS || CUSTOMER_IS_NOT_ELIGIBLE_FOR_FAMILY_PLUS) {
            // If is in campaign but not eligible or campaign is in error or is in campaign but user have rejected the campaign
            // or is in campaign and user accept the campaign
            if (CUSTOMER_IS_NOT_ELIGIBLE_FOR_FAMILY_PLUS || CHECK_FAMILY_PLUS && !isAlreadyDoneAssociateCustomer()) {
                associateCustomerMutate();
            }
        }
    };
    useEffect(function() {
        if (isLoadingUpdateProductCharacteristics) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                updateProductChatacteristics: _object_spread_props(_object_spread({}, customerFlow.updateProductChatacteristics), {
                    error: undefined,
                    isError: false,
                    isSuccess: true,
                    isLoading: false
                })
            }));
        } else if (isErrorUpdateProductCharacteristics) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                updateProductChatacteristics: _object_spread_props(_object_spread({}, customerFlow.updateProductChatacteristics), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("updateProductChatacteristics", updateProductCharacteristicsError)
                })
            }));
        } else if (isSuccessUpdateProductCharacteristics) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                updateProductChatacteristics: _object_spread_props(_object_spread({}, customerFlow.updateProductChatacteristics), {
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
        }
    }, [
        isSuccessUpdateProductCharacteristics,
        isErrorUpdateProductCharacteristics,
        isLoadingUpdateProductCharacteristics, 
    ]);
    useEffect(function() {
        if (isLoadingPaymean) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                paymean: _object_spread_props(_object_spread({}, customerFlow.paymean), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorPaymean) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                paymean: _object_spread_props(_object_spread({}, customerFlow.paymean), {
                    isSuccess: false,
                    isLoading: false,
                    isError: true,
                    error: errorMock("paymean", paymeanError)
                })
            }));
        } else if (isSuccessPaymean) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                paymean: _object_spread_props(_object_spread({}, customerFlow.paymean), {
                    error: undefined,
                    data: paymeanData,
                    isLoading: false,
                    isError: false,
                    isSuccess: true
                })
            }));
        }
    }, [
        isErrorPaymean,
        isLoadingPaymean,
        isSuccessPaymean
    ]);
    useEffect(function() {
        if (isBillingAccountLoading) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                billingAccount: _object_spread_props(_object_spread({}, customerFlow.billingAccount), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isBillingAccountError) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                billingAccount: _object_spread_props(_object_spread({}, customerFlow.billingAccount), {
                    isSuccess: false,
                    isError: true,
                    isLoading: false,
                    error: errorMock("billingAccount", billingAccountError)
                })
            }));
        } else if (isBillingAccountSuccess) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                paymean: _object_spread_props(_object_spread({}, customerFlow.paymean), {
                    isLoading: true
                }),
                billingAccount: _object_spread_props(_object_spread({}, customerFlow.billingAccount), {
                    error: undefined,
                    isError: false,
                    isSuccess: true,
                    isLoading: false
                })
            }));
            setBillingEmail(billingAccountData.billDeliveryDetails.emailAddress);
            refetchPaymean();
        }
    }, [
        isBillingAccountError,
        isBillingAccountLoading,
        isBillingAccountSuccess
    ]);
    useEffect(function() {
        if (isLoadingPayment) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                payment: _object_spread_props(_object_spread({}, customerFlow.payment), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorPayment) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                payment: _object_spread_props(_object_spread({}, customerFlow.payment), {
                    isSuccess: false,
                    isLoading: false,
                    isError: true,
                    error: errorMock("payment", paymentError)
                })
            }));
        } else if (isSuccessPayment) {
            var newMopPaymentData = getBSNewMopMobile(paymentData, globalConfig);
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                billingAccount: _object_spread_props(_object_spread({}, customerFlow.billingAccount), {
                    isLoading: true
                }),
                payment: _object_spread_props(_object_spread({}, customerFlow.payment), {
                    error: undefined,
                    data: newMopPaymentData,
                    isLoading: false,
                    isError: false,
                    isSuccess: true
                })
            }));
            refetchBillingAccount();
        }
    }, [
        isErrorPayment,
        isLoadingPayment,
        isSuccessPayment
    ]);
    useEffect(function() {
        if (isLoadingAssociateCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorAssociateCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("associateCustomer", associateCustomerError)
                })
            }));
        } else if (isSuccessAssociateCustomer) {
            var ref, ref1;
            if (((ref = associateCustomerData) === null || ref === void 0 ? void 0 : (ref1 = ref.cartValidation) === null || ref1 === void 0 ? void 0 : ref1.status) === "blocked") {
                setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                    resetPatchAndPost: resetPatchAndPost,
                    associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                        data: associateCustomerData,
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: errorMock("associateCustomer", {
                            status: "200",
                            statusText: getErrorStatusText("200", ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED),
                            url: ""
                        }, undefined, ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED)
                    })
                }));
            } else {
                var ref2, ref3, ref4;
                setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                    resetPatchAndPost: resetPatchAndPost,
                    payment: _object_spread_props(_object_spread({}, customerFlow.payment), {
                        isLoading: true
                    }),
                    associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                        data: associateCustomerData,
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    })
                }));
                var customerLocal = getFromLocalStorageByKey("customerData");
                var findCallerInactive = getFromLocalStorageByKey("findCaller");
                if ((customerLocal === null || customerLocal === void 0 ? void 0 : (ref2 = customerLocal[0]) === null || ref2 === void 0 ? void 0 : ref2.id) || (findCallerInactive === null || findCallerInactive === void 0 ? void 0 : (ref3 = findCallerInactive.customerRef) === null || ref3 === void 0 ? void 0 : ref3.id)) setContextCustomerId((customerLocal === null || customerLocal === void 0 ? void 0 : customerLocal[0].id) || (findCallerInactive === null || findCallerInactive === void 0 ? void 0 : (ref4 = findCallerInactive.customerRef) === null || ref4 === void 0 ? void 0 : ref4.id) || "");
                mutateUpdateProductCharacteristics(undefined);
                refetchPayment();
            }
        }
    }, [
        isSuccessAssociateCustomer,
        isErrorAssociateCustomer,
        isLoadingAssociateCustomer
    ]);
    useEffect(function() {
        if (customerFlow.acceptedFallbackOffer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                associateCustomer: {
                    data: undefined,
                    error: undefined,
                    isSuccess: false,
                    isLoading: true,
                    isError: false
                },
                payment: {
                    data: undefined,
                    error: undefined,
                    isSuccess: false,
                    isLoading: false,
                    isError: false
                },
                billingAccount: {
                    error: undefined,
                    isSuccess: false,
                    isLoading: false,
                    isError: false
                },
                paymean: {
                    data: undefined,
                    error: undefined,
                    isSuccess: false,
                    isLoading: false,
                    isError: false
                },
                updateProductChatacteristics: {
                    error: undefined,
                    isSuccess: false,
                    isLoading: false,
                    isError: false
                }
            }));
            associateCustomerMutate();
        }
    }, [
        customerFlow.acceptedFallbackOffer
    ]);
    useEffect(function() {
        checkAssociateCustomer();
    }, [
        familyPlus === null || familyPlus === void 0 ? void 0 : familyPlus.campaign,
        familyPlus === null || familyPlus === void 0 ? void 0 : familyPlus.updateCart,
        familyPlus === null || familyPlus === void 0 ? void 0 : familyPlus.accept
    ]);
    useEffect(function() {
        if (isLoadingGetCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorGetCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("customerInfo", customerError)
                })
            }));
        } else if (isSuccessGetCustomer) {
            var alreadyDoneAssociateCustomer = getFromLocalStorageByKey("associateCustomer");
            if (alreadyDoneAssociateCustomer) {
                setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                    resetPatchAndPost: resetPatchAndPost,
                    customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                        data: customerData === null || customerData === void 0 ? void 0 : customerData[0],
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    })
                }));
            } else {
                setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                    resetPatchAndPost: resetPatchAndPost,
                    associateCustomer: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        data: undefined,
                        error: undefined
                    },
                    customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                        data: customerData === null || customerData === void 0 ? void 0 : customerData[0],
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    })
                }));
                // if is next active or logged and not is winback start family plus flow
                // else associate customer
                var START_FAMILY_PLUS = (isNextActive || isLogged) && !isStartFamilyPlus && !(product === null || product === void 0 ? void 0 : product.isWinback) && (!(product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct) && (product === null || product === void 0 ? void 0 : product.familyPlusId) || (product === null || product === void 0 ? void 0 : product.isFamilyPlusProduct));
                if (START_FAMILY_PLUS) {
                    setIsStartFamilyPlus(true);
                } else if (!isAlreadyDoneAssociateCustomer() && !isLoadingCart) {
                    associateCustomerMutate();
                }
            }
        }
    }, [
        isSuccessGetCustomer,
        isErrorGetCustomer,
        isLoadingGetCustomer,
        isLoadingCart
    ]);
    useEffect(function() {
        if (customerId) {
            var alreadyHaveCustomerData = getFromLocalStorageByKey("customerData");
            if (!alreadyHaveCustomerData) refetchGetCustomer();
        }
    }, [
        customerId
    ]);
    useEffect(function() {
        if (isLoadingPostCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                postCustomer: _object_spread_props(_object_spread({}, customerFlow.postCustomer), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorPostCustomer) {
            var ref, ref1, ref2;
            var headerErrorCode = ((ref = postCustomerError.headers) === null || ref === void 0 ? void 0 : ref.get("ERR-errorCode")) || "";
            var backendError = ((ref1 = postCustomerError.headers) === null || ref1 === void 0 ? void 0 : ref1.get("ERR-backendErrorCode")) || "";
            var backendMessage = ((ref2 = postCustomerError.headers) === null || ref2 === void 0 ? void 0 : ref2.get("ERR-backendErrorMessage")) || "";
            var errorCode = headerErrorCode === ErrorCodes.DIGITAL_CRM_CODE ? handleCRMErrorCodes(backendError) : headerErrorCode;
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                postCustomer: _object_spread_props(_object_spread({}, customerFlow.postCustomer), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("postCustomer", {
                        status: postCustomerError.status,
                        statusText: getErrorStatusText(postCustomerError.status, errorCode),
                        url: ""
                    }, backendError, errorCode, backendMessage)
                })
            }));
        } else if (isSuccessPostCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                customerInfo: {
                    isError: false,
                    isSuccess: false,
                    isLoading: true,
                    data: undefined,
                    error: undefined
                },
                postCustomer: _object_spread_props(_object_spread({}, customerFlow.postCustomer), {
                    data: postCustomerData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
            setCustomerId(postCustomerData.id);
        }
    }, [
        isSuccessPostCustomer,
        isErrorPostCustomer,
        isLoadingPostCustomer
    ]);
    useEffect(function() {
        if (isLoadingPatchCustomer) {
            var alreadyDoneAssociateCustomer = getFromLocalStorageByKey("associateCustomer");
            setCustomerFlow(_object_spread_props(_object_spread(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost
            }), alreadyDoneAssociateCustomer && {
                associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                    isSuccess: false
                })
            }), {
                patchCustomer: _object_spread_props(_object_spread({}, customerFlow.patchCustomer), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorPatchCustomer) {
            var ref, ref1, ref2;
            var headerErrorCode = (patchCustomerError === null || patchCustomerError === void 0 ? void 0 : (ref = patchCustomerError.headers) === null || ref === void 0 ? void 0 : ref.get("ERR-errorCode")) || "";
            var backendError = (patchCustomerError === null || patchCustomerError === void 0 ? void 0 : (ref1 = patchCustomerError.headers) === null || ref1 === void 0 ? void 0 : ref1.get("ERR-backendErrorCode")) || "";
            var backendMessage = ((ref2 = patchCustomerError.headers) === null || ref2 === void 0 ? void 0 : ref2.get("ERR-backendErrorMessage")) || "";
            var errorCode = headerErrorCode === ErrorCodes.DIGITAL_CRM_CODE ? handleCRMErrorCodes(backendError) : headerErrorCode;
            LoggerInstance.log("PatchCustomer", headerErrorCode, backendError, backendMessage, errorCode);
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                patchCustomer: _object_spread_props(_object_spread({}, customerFlow.patchCustomer), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("patchCustomer", {
                        status: patchCustomerError.status,
                        statusText: getErrorStatusText(patchCustomerError.status, errorCode),
                        url: ""
                    }, backendError, errorCode, backendMessage)
                })
            }));
        } else if (isSuccessPatchCustomer) {
            setCustomerFlow(_object_spread_props(_object_spread(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost
            }), !isAlreadyDoneAssociateCustomer() && {
                associateCustomer: {
                    data: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                }
            }, isAlreadyDoneAssociateCustomer() && {
                associateCustomer: _object_spread_props(_object_spread({}, customerFlow.associateCustomer), {
                    isSuccess: true
                })
            }), {
                patchCustomer: _object_spread_props(_object_spread({}, customerFlow.patchCustomer), {
                    data: patchCustomerData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                }),
                customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                    data: patchCustomerData === null || patchCustomerData === void 0 ? void 0 : patchCustomerData[0],
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
            localStorage.setItem("customerData", JSON.stringify([
                patchCustomerData
            ]));
            if (!isAlreadyDoneAssociateCustomer()) associateCustomerMutate();
        }
    }, [
        isSuccessPatchCustomer,
        isErrorPatchCustomer,
        isLoadingPatchCustomer
    ]);
    useEffect(function() {
        if (isLoadingFindcaller) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                findcaller: _object_spread_props(_object_spread({}, customerFlow.findcaller), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorFindcaller) {
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                findcaller: _object_spread_props(_object_spread({}, customerFlow.findcaller), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("findcaller", findcallerError)
                })
            }));
        } else if (isSuccessFindcaller) {
            var ref;
            setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                customerInfo: {
                    isError: false,
                    isSuccess: false,
                    isLoading: true,
                    data: undefined,
                    error: undefined
                },
                findcaller: _object_spread_props(_object_spread({}, customerFlow.findcaller), {
                    data: findcallerData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                    error: undefined
                })
            }));
            if ((findcallerData === null || findcallerData === void 0 ? void 0 : findcallerData.customerHasActiveSubs) && (findcallerData === null || findcallerData === void 0 ? void 0 : (ref = findcallerData.customerRef) === null || ref === void 0 ? void 0 : ref.id)) {
                setCustomerId(findcallerData.customerRef.id);
            }
        }
    }, [
        isSuccessFindcaller,
        isErrorFindcaller,
        isLoadingFindcaller
    ]);
    var resetPatchAndPost = function(stateCustomerFlow) {
        var ref;
        if (stateCustomerFlow === null || stateCustomerFlow === void 0 ? void 0 : (ref = stateCustomerFlow.postCustomer) === null || ref === void 0 ? void 0 : ref.isError) {
            localStorage.removeItem("postCustomer");
            setCustomerFlow(_object_spread_props(_object_spread({}, stateCustomerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                postCustomer: {
                    error: undefined,
                    data: undefined,
                    isLoading: false,
                    isError: false,
                    isSuccess: false
                }
            }));
        } else if (stateCustomerFlow === null || stateCustomerFlow === void 0 ? void 0 : stateCustomerFlow.patchCustomer.isError) {
            localStorage.removeItem("patchCustomer");
            setCustomerFlow(_object_spread_props(_object_spread({}, stateCustomerFlow), {
                resetPatchAndPost: resetPatchAndPost,
                patchCustomer: {
                    error: undefined,
                    data: undefined,
                    isLoading: false,
                    isError: false,
                    isSuccess: false
                }
            }));
        }
    };
    var createCustomer = function() {
        var cfData = computeInverse(owningData.owningIndividual.fiscalCode);
        postCustomerMutate({
            owningData: owningData,
            cfInverse: cfData
        });
    };
    var checkPrecondition = function() {
        if (isNextActive) {
            refetchFindcaller();
        } else if (isLogged) {
            if (!isAlreadyDoneAssociateCustomer()) {
                var ref;
                var customerLocal = (ref = getFromLocalStorageByKey("customerData")) === null || ref === void 0 ? void 0 : ref[0];
                setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
                    resetPatchAndPost: resetPatchAndPost,
                    associateCustomer: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        data: undefined,
                        error: undefined
                    },
                    customerInfo: _object_spread_props(_object_spread({}, customerFlow.customerInfo), {
                        data: customerLocal,
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    })
                }));
                associateCustomerMutate();
            }
        } else if (currentStepKey !== ID_FLOWS.CONTACT_CARD) {
            var isNextActiveInactive = getCurrentUserType() === IUserType.NEXT_USER_INACTIVE || getCurrentUserType() === IUserType.NEXT_USER_ACTIVE;
            var alreadyDonePostCustomer = getFromLocalStorageByKey("postCustomer");
            if (alreadyDonePostCustomer || isNextActiveInactive) {
                patchCustomerMutate(getPatchPayload(owningData));
            } else {
                createCustomer();
            }
        }
    };
    useEffect(function() {
        if (isStartCustomerFlow) {
            checkPrecondition();
            setIsStartCustomerFlow(false);
        }
    }, [
        isStartCustomerFlow
    ]);
    useEffect(function() {
        setCustomerFlow(_object_spread_props(_object_spread({}, customerFlow), {
            resetPatchAndPost: resetPatchAndPost
        }));
    }, []);
    return null;
};
