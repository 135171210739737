export var organizeRecognitionEsim = function(data) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18;
    return {
        data: {
            title: (data === null || data === void 0 ? void 0 : (ref = data.personalinfomobile) === null || ref === void 0 ? void 0 : (ref1 = ref.recognitionEsim) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
            description: (data === null || data === void 0 ? void 0 : (ref2 = data.personalinfomobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.recognitionEsim) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
            textBold: (data === null || data === void 0 ? void 0 : (ref4 = data.personalinfomobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.recognitionEsim) === null || ref5 === void 0 ? void 0 : ref5.textBold) || "",
            text: (data === null || data === void 0 ? void 0 : (ref6 = data.personalinfomobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.recognitionEsim) === null || ref7 === void 0 ? void 0 : ref7.text) || ""
        },
        list: {
            title: (data === null || data === void 0 ? void 0 : (ref8 = data.personalinfomobile) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.recognitionEsim) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.list) === null || ref10 === void 0 ? void 0 : ref10[0].title) || "",
            items: (data === null || data === void 0 ? void 0 : (ref11 = data.personalinfomobile) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.recognitionEsim) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.list) === null || ref13 === void 0 ? void 0 : (ref14 = ref13[0]) === null || ref14 === void 0 ? void 0 : ref14.elements) || [
                {
                    text: "",
                    icon: ""
                }, 
            ],
            portability: (data === null || data === void 0 ? void 0 : (ref15 = data.personalinfomobile) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.recognitionEsim) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.list) === null || ref17 === void 0 ? void 0 : (ref18 = ref17[0]) === null || ref18 === void 0 ? void 0 : ref18.portability) || [
                {
                    text: "",
                    icon: ""
                }, 
            ]
        }
    };
};
