import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { checkIsGoBackSIA, errorMock, useDeliveryAdvanceV1 } from "@vfit/consumer/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
var DEFAULT_CUSTOMER_DELIVERY_ADVANCE_INFO = {
    deliveryAdvance: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        error: undefined,
        deliveryOptions: [],
        identificationDetails: []
    }
};
export var useCustomerDeliveryAdvance = function() {
    var ref = useState(DEFAULT_CUSTOMER_DELIVERY_ADVANCE_INFO), useCustomerDeliveryAdvanceMobileResult = ref[0], setUseCustomerDeliveryAdvanceMobileResult = ref[1];
    var ref1 = useCheckout(), isStartAddress = ref1.isStartAddress, deliveryAdvance = ref1.deliveryAdvance, recognitionCode = ref1.recognitionCode, setIsStartAddress = ref1.setIsStartAddress, setDeliveryAdvance = ref1.setDeliveryAdvance;
    var ref2 = useState(""), recognitionCodePosted = ref2[0], setRecognitionCodePosted = ref2[1];
    var ref3 = useDeliveryAdvanceV1(recognitionCode, {
        enabled: false,
        keyDependency: [
            "deliveryDetailsIsAdvance"
        ]
    }), fetchDeliveryAdvance = ref3.refetch, deliveryAdvanceData = ref3.data, deliveryAdvanceError = ref3.error, isSuccessDeliveryAdvance = ref3.isSuccess, isLoadingDeliveryAdvance = ref3.isLoading, isErrorDeliveryAdvance = ref3.isError;
    var saveDeliveryState = function() {
        setDeliveryAdvance(_object_spread_props(_object_spread({}, deliveryAdvance), {
            isLoading: isLoadingDeliveryAdvance,
            isSuccess: isSuccessDeliveryAdvance,
            isError: isErrorDeliveryAdvance,
            error: errorMock("deliveryAdvance", deliveryAdvanceError),
            deliveryOptions: (deliveryAdvanceData === null || deliveryAdvanceData === void 0 ? void 0 : deliveryAdvanceData.deliveryDetails) || [],
            identificationDetails: (deliveryAdvanceData === null || deliveryAdvanceData === void 0 ? void 0 : deliveryAdvanceData.identificationDetails) || []
        }));
    };
    useEffect(function() {
        if (isStartAddress) setIsStartAddress(false);
    }, [
        isStartAddress
    ]);
    useEffect(function() {
        saveDeliveryState();
    }, [
        isErrorDeliveryAdvance,
        isSuccessDeliveryAdvance,
        isLoadingDeliveryAdvance
    ]);
    useEffect(function() {
        if (isLoadingDeliveryAdvance) {
            setUseCustomerDeliveryAdvanceMobileResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    deliveryAdvance: _object_spread_props(_object_spread({}, prevState.deliveryAdvance), {
                        isLoading: true
                    })
                });
            });
        } else if (isErrorDeliveryAdvance) {
            setUseCustomerDeliveryAdvanceMobileResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    deliveryAdvance: _object_spread_props(_object_spread({}, prevState.deliveryAdvance), {
                        isLoading: false,
                        isError: true
                    })
                });
            });
        } else if (isSuccessDeliveryAdvance) {
            setUseCustomerDeliveryAdvanceMobileResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    deliveryAdvance: _object_spread_props(_object_spread({}, prevState.deliveryAdvance), {
                        isLoading: false,
                        isSuccess: true
                    })
                });
            });
        }
    }, [
        isLoadingDeliveryAdvance,
        isSuccessDeliveryAdvance,
        isErrorDeliveryAdvance
    ]);
    useEffect(function() {
        if (isStartAddress && recognitionCode && recognitionCodePosted !== recognitionCode && !checkIsGoBackSIA()) {
            setRecognitionCodePosted(recognitionCode);
            fetchDeliveryAdvance();
        }
    }, [
        isStartAddress
    ]);
    return useCustomerDeliveryAdvanceMobileResult;
};
