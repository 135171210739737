import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding-top: 3.75rem;\n  width: 85%;\n  margin: 0 auto;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var Skeleton = styled.div.withConfig({
    componentId: "sc-e30092d6-0"
})(_templateObject());
