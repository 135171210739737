import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { retrieveInTestByOffer, useGetShoppingCartSilentUpdate } from "@vfit/consumer/data-access";
import { useEffect } from "react";
import { ECodeIdentification, IUpdateProductPriceType } from "@vfit/shared/models";
import { resetData } from "@vfit/shared/providers";
import { useQueryClient } from "react-query";
import { useCheckout } from "../../../iBuyFixed.context";
import { updateProductPrice } from "../../../ShoppingCart/shoppingCart.utils";
import { useDeliveryBaseAdvanceFlow } from "../UseDeliveryBaseAdvance/useDeliveryBaseAdvance";
export var useCustomerDeliveryBase = function() {
    var ref = useCheckout(), product = ref.product, isStartRecognition = ref.isStartRecognition, isStartDeliveryBase = ref.isStartDeliveryBase, customerDeliveryBaseInfo = ref.customerDeliveryBaseInfo, setIsStartDeliveryBase = ref.setIsStartDeliveryBase, setProduct = ref.setProduct, setCustomerDeliveryBaseInfo = ref.setCustomerDeliveryBaseInfo;
    var queryClient = useQueryClient();
    var ref1 = useDeliveryBaseAdvanceFlow({
        enabled: isStartDeliveryBase
    }), isDeliverySuccess = ref1.isSuccess, isDeliveryLoading = ref1.isLoading, isDeliveryError = ref1.isError, deliveryErrorMessage = ref1.errorMessage, deliveryError = ref1.error, deliveryOptions = ref1.deliveryOptions, identificationDetails = ref1.identificationDetails;
    var ref2 = useGetShoppingCartSilentUpdate(undefined, {
        enabled: false
    }), isSuccessShoppingCart = ref2.isSuccess, refetchShoppingCart = ref2.refetch;
    /**
   * Save delivery, first get. Enable VR only in ABtest
   */ var saveDeliveryState = function() {
        var ref;
        var identificationDetailsFiltered = [];
        var isEnabledVR = (product === null || product === void 0 ? void 0 : product.enableVR) ? true : retrieveInTestByOffer();
        if (isEnabledVR) {
            identificationDetailsFiltered = identificationDetails;
        } else if (Array.isArray(identificationDetails)) {
            identificationDetailsFiltered = identificationDetails === null || identificationDetails === void 0 ? void 0 : identificationDetails.filter(function(el) {
                return (el === null || el === void 0 ? void 0 : el.code) !== ECodeIdentification.VR;
            });
        } else if (((ref = identificationDetails) === null || ref === void 0 ? void 0 : ref.code) === ECodeIdentification.VR) {
            identificationDetailsFiltered = {};
        }
        if (isDeliverySuccess) {
            refetchShoppingCart();
        }
        setCustomerDeliveryBaseInfo(_object_spread_props(_object_spread({}, customerDeliveryBaseInfo), {
            deliveryBase: _object_spread_props(_object_spread({}, customerDeliveryBaseInfo.deliveryBase), {
                isLoading: isDeliveryLoading,
                isSuccess: isDeliverySuccess,
                isError: isDeliveryError,
                errorMessage: deliveryErrorMessage,
                error: deliveryError,
                deliveryOptions: deliveryOptions,
                identificationDetails: identificationDetailsFiltered
            })
        }));
    };
    useEffect(function() {
        if (isSuccessShoppingCart) {
            setProduct(_object_spread({}, product, updateProductPrice(product, IUpdateProductPriceType.EDIT)));
        }
    }, [
        isSuccessShoppingCart
    ]);
    useEffect(function() {
        if (isStartDeliveryBase) setIsStartDeliveryBase(false);
    }, [
        isStartDeliveryBase
    ]);
    useEffect(function() {
        saveDeliveryState();
    }, [
        isDeliveryError,
        isDeliverySuccess,
        isDeliveryLoading,
        deliveryOptions,
        identificationDetails, 
    ]);
    useEffect(function() {
        if (isStartRecognition) {
            var ref;
            resetData(queryClient, [
                "deliveryDetailsIsBase"
            ]);
            setCustomerDeliveryBaseInfo(_object_spread_props(_object_spread({}, customerDeliveryBaseInfo), {
                deliveryBase: _object_spread_props(_object_spread({}, customerDeliveryBaseInfo.deliveryBase), {
                    isSuccess: customerDeliveryBaseInfo === null || customerDeliveryBaseInfo === void 0 ? void 0 : (ref = customerDeliveryBaseInfo.deliveryBase) === null || ref === void 0 ? void 0 : ref.isSuccess,
                    isError: false,
                    error: undefined,
                    // this loading is true because after modify in called delivery base
                    // and this loading is for remove lag for cards
                    isLoading: true
                })
            }));
        }
    }, [
        isStartRecognition
    ]);
    return null;
};
