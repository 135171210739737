import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n  }\n\n  button {\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n  ",
        ";\n  margin-top: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-top: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n  ",
        "\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n\n  div {\n    position: relative;\n    left: 120px;\n    top: 11px;\n\n    svg {\n      width: 25px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      left: 155px;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var CardFrame = styled.section.withConfig({
    componentId: "sc-ef7fe7a7-0"
})(_templateObject(), pxToRem(32, 20, 16), breakpoints.tablet, pxToRem(32, 40), breakpoints.desktop, pxToRem(32, 54), pxToRem(32));
export var Title = styled.h2.withConfig({
    componentId: "sc-ef7fe7a7-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), pxToRem(32), breakpoints.tablet, pxToCssFont(42, 52), pxToRem(60), breakpoints.desktop, pxToCssFont(36, 45));
export var Message = styled.p.withConfig({
    componentId: "sc-ef7fe7a7-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), pxToRem(0, 0, 32), breakpoints.tablet, pxToCssFont(20, 30));
export var Loading = styled.div.withConfig({
    componentId: "sc-ef7fe7a7-3"
})(_templateObject3(), breakpoints.tablet);
