/* eslint-disable no-param-reassign */ import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { CART_TYPE, getCostFromShoppingCartByType, getHubPath, getOneTimeAmountFromShoppingCart, updateProduct } from "@vfit/consumer/data-access";
import { ECodeIdentification, IUpdateProductPriceType } from "@vfit/shared/models";
import { checkWindow } from "@vfit/shared/data-access";
import { retrieveProduct } from "../iBuyMobile.utils";
export var ENABLE_DEBUG = true;
/**
 * Method to update mobile product price
 */ export var updateProductPrice = function(product, updateType) {
    var ref, ref1;
    var oneTimeAmountCost = getOneTimeAmountFromShoppingCart();
    var recurringAmountCost = getCostFromShoppingCartByType(CART_TYPE.RECURRING);
    var shippingCost = getCostFromShoppingCartByType(CART_TYPE.SHIPPING);
    var shippingCostFormattedPrice = shippingCost === null || shippingCost === void 0 ? void 0 : shippingCost.formattedPrice;
    var oneTimeAmount = (oneTimeAmountCost === null || oneTimeAmountCost === void 0 ? void 0 : (ref = oneTimeAmountCost.formattedPrice) === null || ref === void 0 ? void 0 : ref.toString()) || 0;
    var recurringAmount = (recurringAmountCost === null || recurringAmountCost === void 0 ? void 0 : (ref1 = recurringAmountCost.formattedPrice) === null || ref1 === void 0 ? void 0 : ref1.toString()) || 0;
    var recognitionMethod = localStorage.getItem("recognition") || "";
    var isEsim = localStorage.getItem("isEsim") === "1";
    var isEsimFullyDigital = isEsim && recognitionMethod === ECodeIdentification.VR;
    var PLACEHOLDERS_ONETIME_RECURRENCE = [
        CART_TYPE.PLACEHOLDER_ONETIME,
        CART_TYPE.PLACEHOLDER_RECURRENCE, 
    ];
    var PLACEHOLDERS_ONETIME_RECURRENCE_SHIPPING = [
        CART_TYPE.PLACEHOLDER_ONETIME,
        CART_TYPE.PLACEHOLDER_RECURRENCE,
        CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, 
    ];
    var PLACEHOLDERS_ACTIVATION_RECURRING = [
        CART_TYPE.PLACEHOLDER_RECURRING,
        CART_TYPE.PLACEHOLDER_ONETIME,
        CART_TYPE.PLACEHOLDER_RECURRENCE, 
    ];
    var PLACEHOLDERS_ACTIVATION_RECURRING_SHIPPING = [
        CART_TYPE.PLACEHOLDER_RECURRING,
        CART_TYPE.PLACEHOLDER_ONETIME,
        CART_TYPE.PLACEHOLDER_RECURRENCE,
        CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, 
    ];
    var PLACEHOLDERS_RECURRING_RECURRENCE = [
        CART_TYPE.PLACEHOLDER_RECURRING,
        CART_TYPE.PLACEHOLDER_RECURRENCE, 
    ];
    var PLACEHOLDERS_RECURRING_RECURRENCE_SHIPPING = [
        CART_TYPE.PLACEHOLDER_RECURRING,
        CART_TYPE.PLACEHOLDER_RECURRENCE,
        CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, 
    ];
    var havePlaceholder = function(text, placeholders) {
        var checkPlaceholder = false;
        placeholders.forEach(function(el) {
            checkPlaceholder = checkPlaceholder || text.includes(el);
        });
        return checkPlaceholder;
    };
    if (product) {
        // region ONE TIME AND RECURRING
        if (oneTimeAmount && recurringAmount) {
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.priceActivationRecurring) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref2, ref3, ref4;
                product.price = ((ref4 = (ref3 = (ref2 = product.priceActivationRecurring) === null || ref2 === void 0 ? void 0 : ref2.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref3 === void 0 ? void 0 : ref3.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref4 === void 0 ? void 0 : ref4.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.subpriceActivationRecurring) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref5, ref6, ref7;
                product.subprice = ((ref7 = (ref6 = (ref5 = product.subpriceActivationRecurring) === null || ref5 === void 0 ? void 0 : ref5.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref6 === void 0 ? void 0 : ref6.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref7 === void 0 ? void 0 : ref7.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (shippingCostFormattedPrice && havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionActivationRecurringShipping) || "", PLACEHOLDERS_ACTIVATION_RECURRING_SHIPPING)) {
                var ref8, ref9, ref10, ref11;
                product.description = ((ref11 = (ref10 = (ref9 = (ref8 = product.descriptionActivationRecurringShipping) === null || ref8 === void 0 ? void 0 : ref8.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref9 === void 0 ? void 0 : ref9.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref10 === void 0 ? void 0 : ref10.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) === null || ref11 === void 0 ? void 0 : ref11.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, (shippingCostFormattedPrice === null || shippingCostFormattedPrice === void 0 ? void 0 : shippingCostFormattedPrice.toString()) || "")) || "";
            } else if (havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionActivationRecurring) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref12, ref13, ref14;
                product.description = ((ref14 = (ref13 = (ref12 = product.descriptionActivationRecurring) === null || ref12 === void 0 ? void 0 : ref12.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref13 === void 0 ? void 0 : ref13.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref14 === void 0 ? void 0 : ref14.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
        } else if (oneTimeAmount && !recurringAmount) {
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.priceActivation) || "", PLACEHOLDERS_ONETIME_RECURRENCE)) {
                var ref15, ref16;
                product.price = ((ref16 = (ref15 = product.priceActivation) === null || ref15 === void 0 ? void 0 : ref15.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref16 === void 0 ? void 0 : ref16.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.subpriceActivation) || "", PLACEHOLDERS_ONETIME_RECURRENCE)) {
                var ref17, ref18;
                product.subprice = ((ref18 = (ref17 = product.subpriceActivation) === null || ref17 === void 0 ? void 0 : ref17.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref18 === void 0 ? void 0 : ref18.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (shippingCostFormattedPrice && havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionActivationShipping) || "", PLACEHOLDERS_ONETIME_RECURRENCE_SHIPPING)) {
                var ref19, ref20, ref21;
                product.description = ((ref21 = (ref20 = (ref19 = product.descriptionActivationShipping) === null || ref19 === void 0 ? void 0 : ref19.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref20 === void 0 ? void 0 : ref20.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, (shippingCostFormattedPrice === null || shippingCostFormattedPrice === void 0 ? void 0 : shippingCostFormattedPrice.toString()) || "")) === null || ref21 === void 0 ? void 0 : ref21.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            } else if (havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionActivation) || "", PLACEHOLDERS_ONETIME_RECURRENCE)) {
                var ref22, ref23;
                product.description = ((ref23 = (ref22 = product.descriptionActivation) === null || ref22 === void 0 ? void 0 : ref22.replace(CART_TYPE.PLACEHOLDER_ONETIME, "".concat(oneTimeAmount, "€") || "")) === null || ref23 === void 0 ? void 0 : ref23.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
        } else if (!oneTimeAmount && recurringAmount) {
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.priceRecurring) || "", PLACEHOLDERS_RECURRING_RECURRENCE)) {
                var ref24, ref25;
                product.price = ((ref25 = (ref24 = product.priceRecurring) === null || ref24 === void 0 ? void 0 : ref24.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref25 === void 0 ? void 0 : ref25.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.subpriceRecurring) || "", PLACEHOLDERS_RECURRING_RECURRENCE)) {
                var ref26, ref27;
                product.subprice = ((ref27 = (ref26 = product.subpriceRecurring) === null || ref26 === void 0 ? void 0 : ref26.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref27 === void 0 ? void 0 : ref27.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (shippingCostFormattedPrice && havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionRecurringShipping) || "", PLACEHOLDERS_RECURRING_RECURRENCE_SHIPPING)) {
                var ref28, ref29, ref30;
                product.description = ((ref30 = (ref29 = (ref28 = product.descriptionRecurringShipping) === null || ref28 === void 0 ? void 0 : ref28.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref29 === void 0 ? void 0 : ref29.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, (shippingCostFormattedPrice === null || shippingCostFormattedPrice === void 0 ? void 0 : shippingCostFormattedPrice.toString()) || "")) === null || ref30 === void 0 ? void 0 : ref30.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            } else if (havePlaceholder((product === null || product === void 0 ? void 0 : product.descriptionRecurring) || "", PLACEHOLDERS_RECURRING_RECURRENCE)) {
                var ref31, ref32;
                product.description = ((ref32 = (ref31 = product.descriptionRecurring) === null || ref31 === void 0 ? void 0 : ref31.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref32 === void 0 ? void 0 : ref32.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
        } else {
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.price) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref33, ref34, ref35;
                product.price = ((ref35 = (ref34 = (ref33 = product.price) === null || ref33 === void 0 ? void 0 : ref33.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref34 === void 0 ? void 0 : ref34.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || "")) === null || ref35 === void 0 ? void 0 : ref35.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.subprice) || "", PLACEHOLDERS_ACTIVATION_RECURRING)) {
                var ref36, ref37, ref38;
                product.subprice = ((ref38 = (ref37 = (ref36 = product.subprice) === null || ref36 === void 0 ? void 0 : ref36.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref37 === void 0 ? void 0 : ref37.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || "")) === null || ref38 === void 0 ? void 0 : ref38.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
            if (havePlaceholder((product === null || product === void 0 ? void 0 : product.description) || "", PLACEHOLDERS_ACTIVATION_RECURRING_SHIPPING)) {
                var ref39, ref40, ref41, ref42;
                product.description = ((ref42 = (ref41 = (ref40 = (ref39 = product.description) === null || ref39 === void 0 ? void 0 : ref39.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref40 === void 0 ? void 0 : ref40.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || "")) === null || ref41 === void 0 ? void 0 : ref41.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, (shippingCostFormattedPrice === null || shippingCostFormattedPrice === void 0 ? void 0 : shippingCostFormattedPrice.toString()) || "")) === null || ref42 === void 0 ? void 0 : ref42.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
            }
        }
        // endregion
        // region E-SIM FULLY DIGITAL
        if (isEsimFullyDigital) {
            var ref43, ref44, ref45;
            product.description = ((ref45 = (ref44 = (ref43 = product.descriptionESimFD) === null || ref43 === void 0 ? void 0 : ref43.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || "")) === null || ref44 === void 0 ? void 0 : ref44.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || "")) === null || ref45 === void 0 ? void 0 : ref45.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || "")) || "";
        }
        // endregion
        product.recurringCost = (recurringAmountCost === null || recurringAmountCost === void 0 ? void 0 : recurringAmountCost.price) || 0;
        product.oneTimeCost = (oneTimeAmountCost === null || oneTimeAmountCost === void 0 ? void 0 : oneTimeAmountCost.price) || 0;
        product.shippingCost = (shippingCost === null || shippingCost === void 0 ? void 0 : shippingCost.price) || 0;
        switch(updateType){
            case IUpdateProductPriceType.EDIT_WALLET:
                product.backupOfferDetails = JSON.parse(JSON.stringify(product.offerDetails));
                product.backupConditions = JSON.parse(JSON.stringify(product.conditions));
                product.offerDetails = product.offerDetailsWallet;
                product.conditions = product.conditionsWallet;
                break;
            case IUpdateProductPriceType.RESET:
                if (product.backupOfferDetails) {
                    product.offerDetails = product.backupOfferDetails;
                    delete product.backupOfferDetails;
                }
                if (product.backupConditions) {
                    product.conditions = product.backupConditions;
                    delete product.backupConditions;
                }
                break;
            default:
                break;
        }
        product.isUpdatedPriceWallet = updateType === IUpdateProductPriceType.EDIT_WALLET;
        updateProduct(product);
    }
    return product;
};
/**
 * Method to update shoppingCartUrl
 */ export var updateProductShoppingCartUrl = function(page) {
    var hub = page ? getHubPath(page) : "";
    var product = retrieveProduct(true);
    if (product && checkWindow()) {
        updateProduct(_object_spread_props(_object_spread({}, product, hub && {
            hubPath: hub
        }), {
            shoppingCartUrl: window.location.href
        }));
    }
};
