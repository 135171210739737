import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { checkBypassApiCallFromSIA, CMS_CONFIG, useAuthorizeMutation, useCmsConfig, useGetPaymean, usePayment, useReserveEquipmentMutation } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey, PAGES } from "@vfit/shared/data-access";
export var usePaymentAuthorizeFlow = function(selectedItem, siaFlowType, authorizationOperationMethod) {
    var queryClient = useQueryClient();
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(""), errorMessage = ref3[0], setErrorMessage = ref3[1];
    var newPayMeanEnabler = !!selectedItem;
    var globalConfig = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG);
    // const findCallerData: any | IAPIError | undefined = queryClient.getQueryData('findCaller');
    // const hasPayMean: boolean = !!findCallerData?.paymean
    var reserveEquipmentData = queryClient.getQueryData("reserveEquipment");
    var authorizeData = queryClient.getQueryData("authorize");
    var ref4 = usePayment({
        enabled: !checkBypassApiCallFromSIA()
    }), paymentData = ref4.data, paymentIsSuccess = ref4.isSuccess, paymentIsLoading = ref4.isLoading, paymentIsError = ref4.isError;
    var ref5 = useGetPaymean({
        enabled: paymentIsSuccess && !checkBypassApiCallFromSIA()
    }, globalConfig), payMeanData = ref5.data, payMeanIsSuccess = ref5.isSuccess, payMeanIsError = ref5.isError;
    var reserveEquipmentMutation = useReserveEquipmentMutation();
    var authorizeMutation = useAuthorizeMutation();
    useEffect(function() {
        if (paymentIsLoading) {
            setIsLoading(true);
        }
    }, [
        paymentIsLoading
    ]);
    useEffect(function() {
        // IMPORTANT: FIX TO UPDATE THE LOGIC FOR INACTIVE NEXT RECOGNIZED
        var findCaller = getFromLocalStorageByKey("findCaller");
        LoggerInstance.debug("AUTHORIZE payMeanData :", payMeanData);
        LoggerInstance.debug("AUTHORIZE payMeanData.payMeans :", payMeanData === null || payMeanData === void 0 ? void 0 : payMeanData.payMeans);
        if ((payMeanData === null || payMeanData === void 0 ? void 0 : payMeanData.payMeans) && (findCaller === null || findCaller === void 0 ? void 0 : findCaller.customerHasActiveSubs) && payMeanIsSuccess && !reserveEquipmentMutation.isSuccess && !newPayMeanEnabler && !authorizeData && selectedItem) {
            // || hasPayMean
            reserveEquipmentMutation.mutate();
        }
        if ((payMeanData === null || payMeanData === void 0 ? void 0 : payMeanData.payMeans) && reserveEquipmentMutation.isSuccess && !authorizeMutation.isSuccess && selectedItem) {
            // || hasPayMean
            authorizeMutation.mutate(_object_spread({
                payMeanType: selectedItem
            }, authorizationOperationMethod && {
                authorizationOperation: authorizationOperationMethod
            }, siaFlowType && {
                siaFlowType: siaFlowType
            }));
        }
    }, [
        payMeanIsSuccess,
        reserveEquipmentMutation.isSuccess,
        authorizeMutation.isSuccess,
        newPayMeanEnabler,
        Boolean(payMeanData === null || payMeanData === void 0 ? void 0 : payMeanData.payMeans), 
    ]);
    useEffect(function() {
        if (newPayMeanEnabler) {
            authorizeMutation.mutate(_object_spread({
                payMeanType: selectedItem
            }, authorizationOperationMethod && {
                authorizationOperation: authorizationOperationMethod
            }, siaFlowType && {
                siaFlowType: siaFlowType
            }));
            setIsLoading(true);
        }
    }, [
        newPayMeanEnabler,
        selectedItem
    ]);
    useEffect(function() {
        if (paymentIsError && paymentData) {
            setIsLoading(false);
            setIsError(true);
            setErrorMessage("ERROR - payment");
        }
        if (payMeanIsError && payMeanData) {
            setIsLoading(false);
            setIsError(true);
            setErrorMessage("ERROR - payMean");
        }
        if (reserveEquipmentMutation.isError && reserveEquipmentData) {
            setIsLoading(false);
            setIsError(true);
            setErrorMessage(reserveEquipmentData === null || reserveEquipmentData === void 0 ? void 0 : reserveEquipmentData.errorMessage);
        }
        if (authorizeMutation.isError && authorizeData) {
            setIsLoading(false);
            setIsError(true);
            setErrorMessage(authorizeData === null || authorizeData === void 0 ? void 0 : authorizeData.errorMessage);
        }
    }, [
        paymentIsError,
        payMeanIsError,
        reserveEquipmentMutation.isError,
        authorizeMutation.isError,
        paymentData,
        payMeanData,
        reserveEquipmentData,
        authorizeData, 
    ]);
    useEffect(function() {
        if (authorizeMutation.isSuccess && !!authorizeData) {
            setIsLoading(false);
            setIsSuccess(true);
        }
    }, [
        authorizeMutation.isSuccess,
        authorizeData
    ]);
    useEffect(function() {
        if (payMeanIsSuccess) {
            setIsLoading(false);
        }
    }, [
        payMeanIsSuccess
    ]);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        errorMessage: errorMessage,
        payMeanIsSuccess: payMeanIsSuccess,
        payMeanIsError: payMeanIsError,
        paymentIsError: paymentIsError
    };
};
