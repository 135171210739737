import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect } from "react";
import { checkIsGoBackSIA, CMS_CONFIG, errorMock, useCmsConfig, useSimTypeSelectionService } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey, PAGES } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
export var useCartAsyncInformation = function() {
    var ref = useCheckout(), isLoadingCart = ref.isLoadingCart, setSimOptions = ref.setSimOptions, setCartAsyncInfo = ref.setCartAsyncInfo, cartAsyncInfo = ref.cartAsyncInfo, setSimTypeSelectedOption = ref.setSimTypeSelectedOption;
    var simDataFromCms = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_PERSONAL_DATA_MOBILE);
    var ref1 = useSimTypeSelectionService(), mutateSim = ref1.mutate, isErrorSim = ref1.isError, isLoadingSim = ref1.isLoading, isSuccessSim = ref1.isSuccess, dataSim = ref1.data, dataSimError = ref1.error;
    // region SIM TYPE SELECTION
    var updateSimOptions = function() {
        var ref, ref1, ref2, ref3;
        var simProduct = dataSim === null || dataSim === void 0 ? void 0 : (ref = dataSim.orderItems) === null || ref === void 0 ? void 0 : ref.find(function(orderItem) {
            var ref, ref1;
            return (orderItem === null || orderItem === void 0 ? void 0 : (ref = orderItem.product) === null || ref === void 0 ? void 0 : (ref1 = ref.type) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) === "mobile";
        });
        var allSimOptions = (simProduct === null || simProduct === void 0 ? void 0 : (ref1 = simProduct.product) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.childProduct) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.format) || [];
        var simOptionsData = {
            simOptions: (allSimOptions === null || allSimOptions === void 0 ? void 0 : allSimOptions.map(function(simOption) {
                var ref, ref1, ref2, ref3, ref4, ref5;
                var isEsim = (ref1 = simOption === null || simOption === void 0 ? void 0 : (ref = simOption.name) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === null || ref1 === void 0 ? void 0 : ref1.includes("esim");
                if (isEsim) {
                    var ref6, ref7, ref8, ref9;
                    return _object_spread_props(_object_spread({}, simOption), {
                        title: (simDataFromCms === null || simDataFromCms === void 0 ? void 0 : (ref6 = simDataFromCms.personalinfomobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.simType) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.choice) === null || ref8 === void 0 ? void 0 : (ref9 = ref8[0]) === null || ref9 === void 0 ? void 0 : ref9.title) || "Si, voglio una eSIM",
                        value: "eSim"
                    });
                }
                return _object_spread_props(_object_spread({}, simOption), {
                    title: (simDataFromCms === null || simDataFromCms === void 0 ? void 0 : (ref2 = simDataFromCms.personalinfomobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.simType) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.choice) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[1]) === null || ref5 === void 0 ? void 0 : ref5.title) || "No, mantengo la SIM fisica",
                    value: "not eSim"
                });
            })) || []
        };
        setSimOptions(simOptionsData);
        var selectedOption = allSimOptions === null || allSimOptions === void 0 ? void 0 : allSimOptions.find(function(el) {
            return el.selection;
        });
        if (selectedOption) {
            setSimTypeSelectedOption(selectedOption);
        }
    };
    useEffect(function() {
        if (isSuccessSim && dataSim) {
            updateSimOptions();
        }
    }, [
        dataSim,
        isSuccessSim
    ]);
    useEffect(function() {
        if (isLoadingSim) {
            setCartAsyncInfo(_object_spread_props(_object_spread({}, cartAsyncInfo), {
                simTypeSelection: {
                    error: undefined,
                    isLoading: true,
                    isError: false,
                    isSuccess: false
                }
            }));
        } else if (isErrorSim) {
            setCartAsyncInfo(_object_spread_props(_object_spread({}, cartAsyncInfo), {
                simTypeSelection: {
                    error: errorMock("simSelection", dataSimError),
                    isLoading: false,
                    isError: true,
                    isSuccess: false
                }
            }));
        } else if (isSuccessSim) {
            setCartAsyncInfo(_object_spread_props(_object_spread({}, cartAsyncInfo), {
                simTypeSelection: {
                    error: undefined,
                    isLoading: false,
                    isError: false,
                    isSuccess: true
                }
            }));
        }
    }, [
        isErrorSim,
        isLoadingSim,
        isSuccessSim
    ]);
    useEffect(function() {
        if (!isLoadingCart && !checkIsGoBackSIA()) {
            var shoppingCart = getFromLocalStorageByKey("shoppingCart");
            var simSelection = getFromLocalStorageByKey("simSelection");
            if (!simSelection && (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id)) {
                mutateSim({
                    cartId: shoppingCart.id
                });
            }
        }
    }, [
        isLoadingCart
    ]);
    // endregion
    return null;
};
