import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useEffect } from "react";
import { useCheckout } from "../../../iBuyMobile.context";
import { getEsimItem, isAlreadySelectedEsim } from "./useOnlyEsimFlow.utils";
export var useOnlyEsimFlow = function() {
    var ref = useCheckout(), product = ref.product, simOptions = ref.simOptions, portabilityStatus = ref.portabilityStatus, currentStepKey = ref.currentStepKey, setSimTypeSelectedOption = ref.setSimTypeSelectedOption, setSimOptions = ref.setSimOptions;
    var handleSelectEsim = function(item) {
        var ref, ref1;
        if (!item) return;
        var modifyMobileSimDeviceId = getFromLocalStorageByKey("modifyMobileSimDeviceId");
        var selectedOption = simOptions === null || simOptions === void 0 ? void 0 : (ref = simOptions.simOptions) === null || ref === void 0 ? void 0 : ref.find(function(el) {
            return el.value === item.value;
        });
        if (selectedOption && (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.id) !== (modifyMobileSimDeviceId === null || modifyMobileSimDeviceId === void 0 ? void 0 : modifyMobileSimDeviceId.simOptionId) && !(portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isLoadingCheck)) {
            setSimTypeSelectedOption(selectedOption);
        }
        setSimOptions(_object_spread_props(_object_spread({}, simOptions), {
            simOptions: simOptions === null || simOptions === void 0 ? void 0 : (ref1 = simOptions.simOptions) === null || ref1 === void 0 ? void 0 : ref1.map(function(sO) {
                if (sO.value === (item === null || item === void 0 ? void 0 : item.value)) {
                    return _object_spread_props(_object_spread({}, sO), {
                        selection: true
                    });
                }
                return _object_spread_props(_object_spread({}, sO), {
                    selection: false
                });
            })
        }));
    };
    useEffect(function() {
        if ((product === null || product === void 0 ? void 0 : product.isEsimOnly) && !isAlreadySelectedEsim(simOptions)) {
            var esimItem = getEsimItem(simOptions);
            if (esimItem) handleSelectEsim({
                title: (esimItem === null || esimItem === void 0 ? void 0 : esimItem.title) || "",
                value: (esimItem === null || esimItem === void 0 ? void 0 : esimItem.value) || ""
            });
        }
    }, [
        currentStepKey
    ]);
    return null;
};
