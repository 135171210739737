export var getRecognitionPrice = function(price) {
    var ref;
    var shippingPriceParsed = (ref = price === null || price === void 0 ? void 0 : price.toString()) === null || ref === void 0 ? void 0 : ref.replace(".", ",");
    if (shippingPriceParsed && shippingPriceParsed != "0") {
        var ref1;
        var splitted = shippingPriceParsed.split(",");
        if ((splitted === null || splitted === void 0 ? void 0 : splitted.length) > 0 && (splitted === null || splitted === void 0 ? void 0 : (ref1 = splitted[1]) === null || ref1 === void 0 ? void 0 : ref1.length) === 1) {
            shippingPriceParsed = "".concat(splitted[0], ",").concat(splitted[1], "0");
        }
    }
    return shippingPriceParsed == "0" ? 0 : shippingPriceParsed;
};
