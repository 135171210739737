import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { checkBypassApiCallFromSIA, errorMock, useDeliveryAdvanceV2, useDeliveryBaseV2 } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
import { useCheckout } from "../../../iBuyFixed.context";
import { getDeliveryCode, getIdentificationMethod } from "./useDeliveryBaseAdvance.utils";
var DEFAULT_CUSTOMER_DELIVERY_ADVANCE_INFO = {
    deliveryAdvance: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        error: undefined,
        deliveryOptions: [],
        identificationDetails: []
    }
};
export var useDeliveryBaseAdvanceFlow = function(param) {
    var enabled = param.enabled;
    var ref = useCheckout(), shippingAddress = ref.shippingAddress, deliveryAdvance = ref.deliveryAdvance, setDeliveryAdvance = ref.setDeliveryAdvance;
    var ref1 = useState(DEFAULT_CUSTOMER_DELIVERY_ADVANCE_INFO), useCustomerDeliveryAdvanceResult = ref1[0], setUseCustomerDeliveryAdvanceResult = ref1[1];
    var ref2 = useState(false), isSuccess = ref2[0], setIsSuccess = ref2[1];
    var ref3 = useState(false), isLoading = ref3[0], setIsLoading = ref3[1];
    var ref4 = useState(false), isError = ref4[0], setIsError = ref4[1];
    var ref5 = useState(""), errorMessage = ref5[0], setErrorMessage = ref5[1];
    var ref6 = useState(undefined), error = ref6[0], setError = ref6[1];
    var ref7 = useState([]), deliveryOptions = ref7[0], setDeliveryOptions = ref7[1];
    var ref8 = useState(""), lastDeliveryAddress = ref8[0], setLastDeliveryAddress = ref8[1];
    var ref9 = useState([]), identificationDetails = ref9[0], setIdentificationDetails = ref9[1];
    var ref10 = useDeliveryBaseV2({
        enabled: false,
        keyDependency: [
            "deliveryDetailsIsBase"
        ]
    }), deliveryBaseData = ref10.data, deliveryBaseError = ref10.error, deliveryBaseIsSuccess = ref10.isSuccess, deliveryBaseIsLoading = ref10.isLoading, deliveryBaseIsError = ref10.isError, refetchDeliveryBaseV2 = ref10.refetch;
    var ref11 = useDeliveryAdvanceV2(getIdentificationMethod(identificationDetails), (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.id) || "", getDeliveryCode(deliveryOptions) || "", {
        enabled: false,
        keyDependency: [
            "deliveryDetailsIsAdvance"
        ]
    }), fetchDeliveryAdvance = ref11.refetch, deliveryAdvanceData = ref11.data, deliveryAdvanceError = ref11.error, isSuccessDeliveryAdvance = ref11.isSuccess, isLoadingDeliveryAdvance = ref11.isLoading, isErrorDeliveryAdvance = ref11.isError;
    // region DELIVERY ADVANCE
    useEffect(function() {
        if (isLoadingDeliveryAdvance) {
            setUseCustomerDeliveryAdvanceResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    deliveryAdvance: _object_spread_props(_object_spread({}, prevState.deliveryAdvance), {
                        isLoading: true
                    })
                });
            });
            setDeliveryAdvance(_object_spread_props(_object_spread({}, deliveryAdvance), {
                isLoading: true,
                isSuccess: false,
                isError: false,
                error: undefined,
                deliveryOptions: [],
                identificationDetails: []
            }));
        } else if (isErrorDeliveryAdvance) {
            setUseCustomerDeliveryAdvanceResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    deliveryAdvance: _object_spread_props(_object_spread({}, prevState.deliveryAdvance), {
                        isLoading: false,
                        isError: true
                    })
                });
            });
            setDeliveryAdvance(_object_spread_props(_object_spread({}, deliveryAdvance), {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: errorMock("deliveryAdvance", deliveryAdvanceError)
            }));
        } else if (isSuccessDeliveryAdvance) {
            var ref, ref1;
            setUseCustomerDeliveryAdvanceResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    deliveryAdvance: _object_spread_props(_object_spread({}, prevState.deliveryAdvance), {
                        isLoading: false,
                        isSuccess: true
                    })
                });
            });
            setDeliveryAdvance(_object_spread_props(_object_spread({}, deliveryAdvance), {
                isLoading: false,
                isSuccess: true,
                isError: false,
                error: undefined,
                deliveryOptions: Array.isArray(deliveryAdvanceData) ? (deliveryAdvanceData === null || deliveryAdvanceData === void 0 ? void 0 : (ref = deliveryAdvanceData[0]) === null || ref === void 0 ? void 0 : ref.deliveryItems) || [] : (deliveryAdvanceData === null || deliveryAdvanceData === void 0 ? void 0 : deliveryAdvanceData.deliveryItems) || [],
                identificationDetails: Array.isArray(deliveryAdvanceData) ? (deliveryAdvanceData === null || deliveryAdvanceData === void 0 ? void 0 : (ref1 = deliveryAdvanceData[0]) === null || ref1 === void 0 ? void 0 : ref1.identificationDetails) || [] : (deliveryAdvanceData === null || deliveryAdvanceData === void 0 ? void 0 : deliveryAdvanceData.identificationDetails) || []
            }));
        }
    }, [
        isLoadingDeliveryAdvance,
        isSuccessDeliveryAdvance,
        isErrorDeliveryAdvance
    ]);
    useEffect(function() {
        if ((shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.id) && lastDeliveryAddress !== (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.id) && isSuccess) {
            setLastDeliveryAddress(shippingAddress.id);
            fetchDeliveryAdvance();
        }
    }, [
        shippingAddress,
        isSuccess
    ]);
    // endregion
    // region DELIVERY BASE
    useEffect(function() {
        if (deliveryBaseIsLoading) {
            setIsSuccess(false);
            setIsLoading(true);
            setIsError(false);
        } else if (deliveryBaseIsSuccess && !isSuccess && !checkBypassApiCallFromSIA()) {
            var ref, ref1;
            setDeliveryOptions(Array.isArray(deliveryBaseData) ? (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref = deliveryBaseData[0]) === null || ref === void 0 ? void 0 : ref.deliveryDetails) || [] : (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : deliveryBaseData.deliveryDetails) || []);
            setIdentificationDetails(Array.isArray(deliveryBaseData) ? (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : (ref1 = deliveryBaseData[0]) === null || ref1 === void 0 ? void 0 : ref1.identificationDetails) || {} : (deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : deliveryBaseData.identificationDetails) || {});
            setIsLoading(false);
            setIsSuccess(true);
        }
    }, [
        deliveryBaseIsSuccess,
        deliveryBaseIsLoading,
        deliveryOptions
    ]);
    useEffect(function() {
        if (deliveryBaseIsError) {
            setIsLoading(false);
            setIsError(true);
            setError(errorMock("deliveryBase", deliveryBaseError));
            setErrorMessage(deliveryBaseData === null || deliveryBaseData === void 0 ? void 0 : deliveryBaseData.errorMessage);
        }
    }, [
        deliveryBaseIsError,
        deliveryBaseData
    ]);
    // endregion
    useEffect(function() {
        if (enabled && !checkBypassApiCallFromSIA() && !isError) {
            refetchDeliveryBaseV2();
        }
    }, [
        enabled
    ]);
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        errorMessage: errorMessage,
        deliveryOptions: deliveryOptions,
        identificationDetails: identificationDetails,
        error: error,
        useCustomerDeliveryAdvanceResult: useCustomerDeliveryAdvanceResult
    };
};
