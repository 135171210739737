import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { CMS_CONFIG, ErrorCodes, errorMock, getCurrentUserType, IUserType, useCmsConfig, useGetCustomerReference } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { API, getFromLocalStorageByKey, openPopup, PAGES } from "@vfit/shared/data-access";
import { resetData } from "@vfit/shared/providers";
import { useEffect } from "react";
import { retrieveOwningIndividual } from "../../checkout.utils";
import { useCheckout } from "../../../iBuyFixed.context";
import { isAppLockin, updateProductPrice } from "../../../ShoppingCart/shoppingCart.utils";
export var usePostOtpFlow = function(props) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17;
    var handleOnClose = props.handleOnClose;
    var ref18 = useCheckout(), product = ref18.product, findCaller = ref18.findCaller, customerFlow = ref18.customerFlow, customerAsyncInfo = ref18.customerAsyncInfo, owningData = ref18.owningData, checkoutErrors = ref18.checkoutErrors, isModalButtonSticky = ref18.isModalButtonSticky, lockInMMFlow = ref18.lockInMMFlow, setIsShowCheckout = ref18.setIsShowCheckout, setProduct = ref18.setProduct, setIsStartLockInMMFlow = ref18.setIsStartLockInMMFlow, setIsDisabledBackButton = ref18.setIsDisabledBackButton, setCustomerId = ref18.setCustomerId, setOwningData = ref18.setOwningData, setIsStartCustomerFlow = ref18.setIsStartCustomerFlow, setFindCaller = ref18.setFindCaller;
    // if the product is lockin, calls do not need to be repeated
    var alreadyFetchedInfo = product === null || product === void 0 ? void 0 : product.isLockInMMProduct;
    var queryClient = useQueryClient();
    var countries = getFromLocalStorageByKey("getCountry");
    var otpVerifyResponse = {
        id: findCaller.verifyOtp
    };
    var ref19 = useGetCustomerReference(), isReferenceSuccess = ref19.isSuccess, isReferenceLoading = ref19.isLoading, isReferenceError = ref19.isError, errorCustomerReference = ref19.error;
    var associateCustomer = customerFlow.associateCustomer;
    var genericError = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GENERIC_ERROR);
    var ref20 = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_ORDER_ERROR), errors = ref20.errorcomponent.errors;
    var blockedCustomer = errors === null || errors === void 0 ? void 0 : errors.find(function(item) {
        return (item === null || item === void 0 ? void 0 : item.error) === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED;
    });
    var isLoadingLockin = (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref = lockInMMFlow.campaigns) === null || ref === void 0 ? void 0 : ref.isLoading) || (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref1 = lockInMMFlow.accept) === null || ref1 === void 0 ? void 0 : ref1.isLoading) || (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref2 = lockInMMFlow.shoppingCart) === null || ref2 === void 0 ? void 0 : ref2.isLoading);
    var isErrorLockin = (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref3 = lockInMMFlow.campaigns) === null || ref3 === void 0 ? void 0 : ref3.isError) || (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref4 = lockInMMFlow.campaigns) === null || ref4 === void 0 ? void 0 : ref4.isSuccess) && !(lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref5 = lockInMMFlow.checkEligibility) === null || ref5 === void 0 ? void 0 : ref5.eligible) || (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref6 = lockInMMFlow.campaigns) === null || ref6 === void 0 ? void 0 : ref6.isSuccess) && !(lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref7 = lockInMMFlow.checkEligibility) === null || ref7 === void 0 ? void 0 : ref7.lockInProduct) || (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref8 = lockInMMFlow.accept) === null || ref8 === void 0 ? void 0 : ref8.isError) || (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref9 = lockInMMFlow.shoppingCart) === null || ref9 === void 0 ? void 0 : ref9.isError);
    var isSuccessLockin = (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref10 = lockInMMFlow.campaigns) === null || ref10 === void 0 ? void 0 : ref10.isSuccess) && (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref11 = lockInMMFlow.accept) === null || ref11 === void 0 ? void 0 : ref11.isSuccess) && (lockInMMFlow === null || lockInMMFlow === void 0 ? void 0 : (ref12 = lockInMMFlow.shoppingCart) === null || ref12 === void 0 ? void 0 : ref12.isSuccess);
    var isLoadingCustomerInfo = isReferenceLoading || !isReferenceSuccess || !!(otpVerifyResponse === null || otpVerifyResponse === void 0 ? void 0 : otpVerifyResponse.id) && !associateCustomer.isSuccess || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref13 = customerFlow.billingAccount) === null || ref13 === void 0 ? void 0 : ref13.isLoading) || (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref14 = customerAsyncInfo.customerInfo) === null || ref14 === void 0 ? void 0 : ref14.isLoading) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref15 = customerFlow.paymean) === null || ref15 === void 0 ? void 0 : ref15.isLoading) || (customerFlow === null || customerFlow === void 0 ? void 0 : (ref16 = customerFlow.payment) === null || ref16 === void 0 ? void 0 : ref16.isLoading) || (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref17 = customerAsyncInfo.termsAndConditions) === null || ref17 === void 0 ? void 0 : ref17.isLoading);
    var preconditionCustomer = function() {
        if (associateCustomer.isSuccess || associateCustomer.isError || isReferenceError) {
            var ref, ref1, ref2, ref3, ref4, ref5;
            setFindCaller(_object_spread_props(_object_spread({}, findCaller, (customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.findcaller) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : ref1.paymean) && {
                paymean: customerFlow === null || customerFlow === void 0 ? void 0 : (ref2 = customerFlow.findcaller) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.data) === null || ref3 === void 0 ? void 0 : ref3.paymean
            }), {
                showOtp: false,
                verifyOtp: "",
                isVerified: true
            }));
            if (associateCustomer.isError && ((ref4 = associateCustomer.error) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.error) === null || ref5 === void 0 ? void 0 : ref5.statusText) === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED) {
                var ref6, ref7, ref8;
                var handleClose = function() {
                    if (handleOnClose) {
                        resetData(queryClient);
                        handleOnClose();
                    }
                };
                var goBackAndReset = function() {
                    var ref;
                    if (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref = blockedCustomer.button) === null || ref === void 0 ? void 0 : ref.urlForRedirect) {
                        var ref1;
                        openPopup((blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref1 = blockedCustomer.button) === null || ref1 === void 0 ? void 0 : ref1.urlForRedirect) || "", function() {
                            if (handleOnClose) {
                                resetData(queryClient);
                                handleOnClose();
                            }
                        });
                    } else if (handleOnClose) {
                        resetData(queryClient);
                        handleOnClose();
                    }
                };
                checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref8 = checkoutErrors.showAndTrackError) === null || ref8 === void 0 ? void 0 : ref8.call(checkoutErrors, genericError, function() {
                    return handleClose();
                }, (associateCustomer === null || associateCustomer === void 0 ? void 0 : associateCustomer.isError) ? associateCustomer.error : undefined, (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : blockedCustomer.title) || "Attenzione", (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : blockedCustomer.message) || "I seguenti articoli non sono attivabili dal cliente selezionato", function() {
                    return goBackAndReset();
                }, (blockedCustomer === null || blockedCustomer === void 0 ? void 0 : (ref6 = blockedCustomer.button) === null || ref6 === void 0 ? void 0 : ref6.title) || "Ti richiamiamo", function() {
                    var ref;
                    return openPopup((product === null || product === void 0 ? void 0 : (ref = product.genericErrorCallMeNow) === null || ref === void 0 ? void 0 : ref.url) || "", handleClose);
                }, product === null || product === void 0 ? void 0 : (ref7 = product.genericErrorCallMeNow) === null || ref7 === void 0 ? void 0 : ref7.label, isModalButtonSticky);
            } else if (associateCustomer.isError || isReferenceError) {
                var ref9, ref10, ref11, ref12;
                var error;
                if (isReferenceError && errorCustomerReference) {
                    error = errorMock(ErrorCodes.REFERENCE_CUSTOMER, errorCustomerReference);
                }
                if (associateCustomer.isError && (customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref9 = customerAsyncInfo.customerInfo) === null || ref9 === void 0 ? void 0 : ref9.isError)) {
                    var ref13;
                    error = customerAsyncInfo === null || customerAsyncInfo === void 0 ? void 0 : (ref13 = customerAsyncInfo.customerInfo) === null || ref13 === void 0 ? void 0 : ref13.error;
                }
                checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref12 = checkoutErrors.showAndTrackError) === null || ref12 === void 0 ? void 0 : ref12.call(checkoutErrors, genericError, handleOnClose, _object_spread_props(_object_spread({}, error), {
                    isBlocking: true
                }), "", "", handleOnClose, (genericError === null || genericError === void 0 ? void 0 : (ref10 = genericError.genericerror) === null || ref10 === void 0 ? void 0 : ref10.buttonLabel) || "", function() {
                    var ref;
                    return openPopup((product === null || product === void 0 ? void 0 : (ref = product.genericErrorCallMeNow) === null || ref === void 0 ? void 0 : ref.url) || "", function() {
                        return handleOnClose;
                    });
                }, product === null || product === void 0 ? void 0 : (ref11 = product.genericErrorCallMeNow) === null || ref11 === void 0 ? void 0 : ref11.label, isModalButtonSticky);
            }
        }
    };
    var checkSuccessLockIn = function() {
        if (isSuccessLockin && !isLoadingCustomerInfo && !isLoadingLockin && !alreadyFetchedInfo && lockInMMFlow.checkEligibility.lockInProduct) {
            localStorage.setItem("tProd_backUp", JSON.stringify(product));
            setProduct(_object_spread_props(_object_spread({}, lockInMMFlow.checkEligibility.lockInProduct, updateProductPrice(lockInMMFlow.checkEligibility.lockInProduct)), {
                slug: product === null || product === void 0 ? void 0 : product.slug,
                shoppingCartUrl: product === null || product === void 0 ? void 0 : product.shoppingCartUrl,
                isLockInMMProduct: true
            }));
            setIsShowCheckout(false);
        }
    };
    var checkStartLockInMMFlow = function() {
        var userType = getCurrentUserType();
        return !isAppLockin() && (userType === IUserType.NEXT_USER_ACTIVE || userType === IUserType.LOGGED_USER) && product.enableLockInFlow;
    };
    useEffect(function() {
        preconditionCustomer();
        if (associateCustomer.isSuccess) {
            var ref;
            var customer = (ref = customerAsyncInfo.customerInfo.data) === null || ref === void 0 ? void 0 : ref[0];
            if (customer) {
                var retrivedIndividual = retrieveOwningIndividual(customer, countries);
                setOwningData(_object_spread_props(_object_spread({}, owningData), {
                    owningIndividual: _object_spread({}, owningData.owningIndividual, retrivedIndividual)
                }));
                setCustomerId(customer.id);
            }
        }
    }, [
        associateCustomer,
        isReferenceError,
        customerAsyncInfo
    ]);
    useEffect(function() {
        var ref, ref1, ref2;
        if ((isErrorLockin || isSuccessLockin) && ((ref = customerFlow.findcaller) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.customerRef) === null || ref2 === void 0 ? void 0 : ref2.id)) {
            var ref3, ref4, ref5;
            setCustomerId((ref3 = customerFlow.findcaller) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.data) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.customerRef) === null || ref5 === void 0 ? void 0 : ref5.id);
        }
    }, [
        isErrorLockin,
        isSuccessLockin
    ]);
    useEffect(function() {
        setIsDisabledBackButton(isLoadingCustomerInfo || isLoadingLockin);
        checkSuccessLockIn();
    }, [
        isLoadingLockin,
        isLoadingCustomerInfo
    ]);
    useEffect(function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
        var userType = getCurrentUserType();
        var isActiveUser = customerFlow.findcaller && ((ref = customerFlow.findcaller) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : ref1.customerHasActiveSubs) && ((ref2 = customerFlow.findcaller) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.data) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.customerRef) === null || ref4 === void 0 ? void 0 : ref4.id);
        if (checkStartLockInMMFlow() && (userType === IUserType.LOGGED_USER || isActiveUser)) {
            setIsStartLockInMMFlow(true);
        } else if (isActiveUser && ((ref5 = customerFlow.findcaller) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.data) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.customerRef) === null || ref7 === void 0 ? void 0 : ref7.id)) {
            var ref8, ref9, ref10;
            setCustomerId((ref8 = customerFlow.findcaller) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.data) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.customerRef) === null || ref10 === void 0 ? void 0 : ref10.id);
        }
    }, [
        customerFlow.findcaller
    ]);
    useEffect(function() {
        setFindCaller(_object_spread_props(_object_spread({}, findCaller), {
            showOtp: false
        }));
        // If Is not already lockin start customer flow
        if (!alreadyFetchedInfo) setIsStartCustomerFlow(true);
    }, []);
    return {
        isLoading: alreadyFetchedInfo ? false : isLoadingLockin || isLoadingCustomerInfo
    };
};
