import { CART_TYPE, getOneTimeAmountForUpfront } from "@vfit/consumer/data-access";
import { purify } from "@vfit/shared/themes";
export var DEFAULT_CREDIT_CARD = {
    displayBrand: "*********",
    object_type: "*****",
    id: "***********",
    type: "*****",
    accountNumber: "*******",
    brand: "********",
    lastFourDigits: "****",
    holderName: "*********",
    expiryMonth: "**",
    expiryYear: "****"
};
export var organizePaymentMethod = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4;
    return {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.paymentsmobile) === null || ref === void 0 ? void 0 : ref.title) || "",
        subtitle: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref1 = dataFromCms.paymentsmobile) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.userRegistered) === null || ref2 === void 0 ? void 0 : ref2.description) || "",
        buttonLabel: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.paymentsmobile) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.userRegistered) === null || ref4 === void 0 ? void 0 : ref4.changeLabel) || ""
    };
};
export var organizePaymentMethodError = function(paymentCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17;
    return {
        titlePaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref = paymentCMS.paymentsmobile) === null || ref === void 0 ? void 0 : (ref1 = ref.paymentSelectionError) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        messagePaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref2 = paymentCMS.paymentsmobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.paymentSelectionError) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        messageSelectedPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref4 = paymentCMS.paymentsmobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.paymentError) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.notFound) === null || ref6 === void 0 ? void 0 : ref6.message) || "Siamo spiacenti ma si \xe8 verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.",
        titleSelectedPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref7 = paymentCMS.paymentsmobile) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.paymentError) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.notFound) === null || ref9 === void 0 ? void 0 : ref9.title) || "Ops!",
        titleErrorErrorRetriveCart: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref10 = paymentCMS.paymentsmobile) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.errorRetriveCart) === null || ref11 === void 0 ? void 0 : ref11.title) || "",
        messageErrorRetriveCart: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref12 = paymentCMS.paymentsmobile) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.errorRetriveCart) === null || ref13 === void 0 ? void 0 : ref13.description) || "",
        titleErrorUpdatePaymentRecharge: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref14 = paymentCMS.paymentsmobile) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.errorUpdatePaymentRecharge) === null || ref15 === void 0 ? void 0 : ref15.title) || "",
        messageErrorUpdatePaymentRecharge: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref16 = paymentCMS.paymentsmobile) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.errorUpdatePaymentRecharge) === null || ref17 === void 0 ? void 0 : ref17.description) || ""
    };
};
export var organizeUpfrontSelectionCard = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30;
    var oneTimeAmount = getOneTimeAmountForUpfront(true);
    var escapeRegExp = function(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };
    var replacePlaceHolderWithData = function(str, placeHolder, data) {
        var ref;
        return ((ref = purify([
            str.replace(new RegExp(escapeRegExp(placeHolder), "g"), "".concat(data || "")) || ""
        ])) === null || ref === void 0 ? void 0 : ref[0]) || "";
    }; // Escaped RegEx from $ needed for multiple placeholder occurrences in the template
    return {
        titleUpFront: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.paymentsmobile) === null || ref === void 0 ? void 0 : (ref1 = ref.upFrontPayment) === null || ref1 === void 0 ? void 0 : ref1.title) || "Contributo iniziale",
        descriptionCC: replacePlaceHolderWithData(dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref2 = dataFromCms.paymentsmobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.upFrontPayment) === null || ref3 === void 0 ? void 0 : ref3.descriptionCC, CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount),
        descriptionCreditRemaning: replacePlaceHolderWithData(dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref4 = dataFromCms.paymentsmobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.upFrontPayment) === null || ref5 === void 0 ? void 0 : ref5.descriptionCreditRemaining, CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount),
        paymentMethod: [
            {
                title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref6 = dataFromCms.paymentsmobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.upFrontPayment) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.paymentMethod) === null || ref8 === void 0 ? void 0 : (ref9 = ref8[0]) === null || ref9 === void 0 ? void 0 : ref9.title) || "Carta di credito",
                description: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref10 = dataFromCms.paymentsmobile) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.upFrontPayment) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.paymentMethod) === null || ref12 === void 0 ? void 0 : (ref13 = ref12[0]) === null || ref13 === void 0 ? void 0 : ref13.description) || "",
                value: "creditCard"
            },
            {
                title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref14 = dataFromCms.paymentsmobile) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.upFrontPayment) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.paymentMethod) === null || ref16 === void 0 ? void 0 : (ref17 = ref16[1]) === null || ref17 === void 0 ? void 0 : ref17.title) || "Paypal",
                description: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref18 = dataFromCms.paymentsmobile) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.upFrontPayment) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.paymentMethod) === null || ref20 === void 0 ? void 0 : (ref21 = ref20[1]) === null || ref21 === void 0 ? void 0 : ref21.description) || "",
                value: "creditCardAndPaypal"
            }, 
        ],
        bottomModalConfig: {
            textLink: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref22 = dataFromCms.paymentsmobile) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.upFrontPayment) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.modal) === null || ref24 === void 0 ? void 0 : ref24.textLink) || "",
            titleModal: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref25 = dataFromCms.paymentsmobile) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.upFrontPayment) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.modal) === null || ref27 === void 0 ? void 0 : ref27.title) || "",
            descriptionModal: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref28 = dataFromCms.paymentsmobile) === null || ref28 === void 0 ? void 0 : (ref29 = ref28.upFrontPayment) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.modal) === null || ref30 === void 0 ? void 0 : ref30.description) || ""
        }
    };
};
