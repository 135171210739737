import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { checkIsGoBackSIA, errorMock, useAuthorizeMutation } from "@vfit/consumer/data-access";
import { useEffect } from "react";
import { useCheckout } from "../../../iBuyFixed.context";
export var useAuthorizeFlow = function() {
    var ref = useCheckout(), authorizeFlow = ref.authorizeFlow, setAuthorizeFlow = ref.setAuthorizeFlow;
    var ref1 = useAuthorizeMutation(), authorizeData = ref1.data, errorAuthorize = ref1.error, isSuccessAuthorize = ref1.isSuccess, isLoadingAuthorize = ref1.isLoading, isErrorAuthorize = ref1.isError, mutateAuthorize = ref1.mutate;
    var resetReserveAuthorization = function() {
        setAuthorizeFlow(_object_spread_props(_object_spread({}, authorizeFlow), {
            isStartAuthorizeFlow: false,
            resetReserveAuthorization: resetReserveAuthorization,
            authorize: {
                data: undefined,
                error: undefined,
                isSuccess: false,
                isLoading: false,
                isError: false
            }
        }));
    };
    useEffect(function() {
        if (isLoadingAuthorize) {
            setAuthorizeFlow(_object_spread_props(_object_spread({}, authorizeFlow), {
                authorize: _object_spread_props(_object_spread({}, authorizeFlow.authorize), {
                    error: undefined,
                    isError: false,
                    isSuccess: false,
                    isLoading: true
                })
            }));
        } else if (isErrorAuthorize) {
            setAuthorizeFlow(_object_spread_props(_object_spread({}, authorizeFlow), {
                authorize: _object_spread_props(_object_spread({}, authorizeFlow.authorize), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("authorize", errorAuthorize)
                })
            }));
        } else if (isSuccessAuthorize) {
            setAuthorizeFlow(_object_spread_props(_object_spread({}, authorizeFlow), {
                authorize: {
                    isLoading: false,
                    isError: false,
                    isSuccess: true,
                    data: authorizeData
                }
            }));
        }
    }, [
        isSuccessAuthorize,
        isLoadingAuthorize,
        isErrorAuthorize
    ]);
    useEffect(function() {
        if ((authorizeFlow === null || authorizeFlow === void 0 ? void 0 : authorizeFlow.isStartAuthorizeFlow) && !checkIsGoBackSIA()) {
            setAuthorizeFlow(_object_spread_props(_object_spread({}, authorizeFlow), {
                resetReserveAuthorization: resetReserveAuthorization,
                isStartAuthorizeFlow: false
            }));
            if (authorizeFlow === null || authorizeFlow === void 0 ? void 0 : authorizeFlow.selectedMethod) {
                mutateAuthorize({
                    siaFlowType: (authorizeFlow === null || authorizeFlow === void 0 ? void 0 : authorizeFlow.siaFlowType) || undefined,
                    payMeanType: authorizeFlow === null || authorizeFlow === void 0 ? void 0 : authorizeFlow.selectedMethod,
                    authorizationOperation: authorizeFlow === null || authorizeFlow === void 0 ? void 0 : authorizeFlow.authorizationOperationMethod
                });
            }
        } else {
            setAuthorizeFlow(_object_spread_props(_object_spread({}, authorizeFlow), {
                resetReserveAuthorization: resetReserveAuthorization
            }));
        }
    }, [
        authorizeFlow === null || authorizeFlow === void 0 ? void 0 : authorizeFlow.isStartAuthorizeFlow
    ]);
    useEffect(function() {
        setAuthorizeFlow(_object_spread_props(_object_spread({}, authorizeFlow), {
            resetReserveAuthorization: resetReserveAuthorization
        }));
    }, []);
    return null;
};
