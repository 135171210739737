import { useEffect, useState } from "react";
import { useAcceptCampaign, useGetCampaignDetails, useGetShoppingCartSilentUpdate, useGetSubscriberByResource } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { resetData } from "@vfit/shared/providers";
import { isMockWinback } from "./useWinback.utils";
export var useWinback = function(phoneNumber) {
    var queryClient = useQueryClient();
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var campaignId = localStorage.getItem("targetCampaignId") || "";
    var ref3 = useGetSubscriberByResource(phoneNumber, false), subscriberData = ref3.data, isSuccessGetSubscriber = ref3.isSuccess, isLoadingGetSubscriber = ref3.isLoading, isErrorGetSubscriber = ref3.isError, refetchGetSubscriberByResource = ref3.refetch;
    var ref4 = useGetCampaignDetails(campaignId, false), isSuccessCampaignDetails = ref4.isSuccess, isLoadingCampaignDetails = ref4.isLoading, isErrorCampaignDetails = ref4.isError, refetchCampaignDetails = ref4.refetch;
    var ref5 = useAcceptCampaign(), mutateAcceptCampaign = ref5.mutate, acceptCampaignData = ref5.data, isSuccessAcceptCampaign = ref5.isSuccess, isLoadingAcceptCampaign = ref5.isLoading, isErrorAcceptCampaign = ref5.isError;
    var ref6 = useGetShoppingCartSilentUpdate(acceptCampaignData === null || acceptCampaignData === void 0 ? void 0 : acceptCampaignData.orderId, {
        enabled: !!(acceptCampaignData === null || acceptCampaignData === void 0 ? void 0 : acceptCampaignData.orderId)
    }), isSuccessShoppingCart = ref6.isSuccess, isLoadingShoppingCart = ref6.isLoading, isErrorShoppingCart = ref6.isError;
    var handleAcceptCampaign = function(enable) {
        if (!enable) return;
        if (subscriberData) {
            mutateAcceptCampaign({
                phoneNumber: phoneNumber,
                campaignId: campaignId
            });
        }
    };
    var resetState = function() {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(false);
        resetData(queryClient, [
            "getShoppingCart",
            "getSubscriberByResource",
            "getCampaignDetails",
            "acceptCampaign", 
        ]);
    };
    useEffect(function() {
        if (isSuccessGetSubscriber) {
            setIsError(!subscriberData);
            if (!isError) refetchCampaignDetails();
        }
    }, [
        isSuccessGetSubscriber
    ]);
    useEffect(function() {
        handleAcceptCampaign(isSuccessCampaignDetails);
    }, [
        isSuccessCampaignDetails
    ]);
    useEffect(function() {
        var loading = [
            isLoadingGetSubscriber,
            isLoadingCampaignDetails,
            isLoadingAcceptCampaign,
            isLoadingShoppingCart, 
        ].some(Boolean);
        setIsLoading(loading);
    }, [
        isLoadingGetSubscriber,
        isLoadingCampaignDetails,
        isLoadingAcceptCampaign,
        isLoadingShoppingCart, 
    ]);
    useEffect(function() {
        var success = [
            isSuccessGetSubscriber,
            isSuccessCampaignDetails,
            isSuccessAcceptCampaign,
            isSuccessShoppingCart, 
        ].every(Boolean);
        setIsSuccess(success);
    }, [
        isSuccessGetSubscriber,
        isSuccessCampaignDetails,
        isSuccessAcceptCampaign,
        isSuccessShoppingCart, 
    ]);
    useEffect(function() {
        var error = [
            isErrorGetSubscriber,
            isErrorCampaignDetails,
            isErrorAcceptCampaign,
            isErrorShoppingCart, 
        ].some(Boolean);
        setIsError(error);
    }, [
        isErrorGetSubscriber,
        isErrorCampaignDetails,
        isErrorAcceptCampaign,
        isErrorShoppingCart
    ]);
    var refetchFlowStart = function() {
        if (isMockWinback()) {
            setIsSuccess(true);
        } else {
            refetchGetSubscriberByResource();
        }
    };
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        refetchFlowStart: refetchFlowStart,
        resetState: resetState
    };
};
