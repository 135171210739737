import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  overflow-y: scroll;\n  padding-top: 24px;\n  padding-bottom: 69px;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  margin: auto;\n  width: 327px;\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n  scrollbar-width: none;\n\n  ::-webkit-scrollbar {\n    display: none; /* for Chrome, Safari, and Opera */\n  }\n\n  @media (min-width: ",
        ") {\n    width: 446px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 515px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  position: absolute;\n  filter: blur(60px);\n  top: 0;\n  left: 0;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background-color: #000;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  padding-bottom: 64px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-57685094-0"
})(_templateObject());
export var Content = styled.div.withConfig({
    componentId: "sc-57685094-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-57685094-2"
})(_templateObject2());
export var ImgBlurred = styled.div.withConfig({
    componentId: "sc-57685094-3"
})(_templateObject3());
export var DownloadContent = styled.div.withConfig({
    componentId: "sc-57685094-4"
})(_templateObject4());
