import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useQueryClient } from "react-query";
import { checkBypassApiCallFromSIA, useCreateAddress, useLinkContactAndCustomer, useIndividual, getAddressValidatedInDeliveryByCoords, usePostDeliveryV1, useDeliveryEvolvedV1, useValidateAddress, errorMock } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
import { resetData } from "@vfit/shared/providers";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
var DEFAULT_DELIVERY_POST = {
    individual: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    linkContact: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    validateAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    createAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    deliveryEvolved: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    postDelivery: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var useDeliveryPostFlow = function(param) {
    var pickupPoint = param.pickupPoint, shippingAddress = param.shippingAddress;
    var queryClient = useQueryClient();
    var ref = useState(DEFAULT_DELIVERY_POST), postReturn = ref[0], setPostReturn = ref[1];
    var ref1 = useState({
        shippingAddress: undefined,
        pickupPoint: undefined
    }), deliveryPayload = ref1[0], setDeliveryPayload = ref1[1];
    var deliveryBase = getFromLocalStorageByKey("deliveryDetailsIsBaseMobile");
    var ref2 = usePostDeliveryV1(), mutatePostDelivery = ref2.mutate, postDeliveryError = ref2.error, isErrorPostDelivery = ref2.isError, isSuccessPostDelivery = ref2.isSuccess, isLoadingPostDelivery = ref2.isLoading;
    var ref3 = useDeliveryEvolvedV1(deliveryPayload === null || deliveryPayload === void 0 ? void 0 : deliveryPayload.shippingAddress, deliveryPayload === null || deliveryPayload === void 0 ? void 0 : deliveryPayload.pickupPoint, {
        enabled: false
    }), refetchDeliveryEvolved = ref3.refetch, deliveryEvolvedError = ref3.error, isErrorDeliveryEvolved = ref3.isError, isSuccessDeliveryEvolved = ref3.isSuccess, isLoadingDeliveryEvolved = ref3.isLoading;
    var ref4 = useIndividual({
        enabled: false
    }), isSuccessIndividual = ref4.isSuccess, individualError = ref4.error, isErrorIndivdual = ref4.isError, isLoadingIndividual = ref4.isLoading, refetchIndividual = ref4.refetch;
    var ref5 = useValidateAddress(), validateAddressMutation = ref5.mutate, validateAddressError = ref5.error, validatedShippingAddress = ref5.data, isLoadingValidateAddress = ref5.isLoading, isSuccessValidateAddress = ref5.isSuccess, isErrorValidateAddress = ref5.isError;
    var ref6 = useCreateAddress(), createAddressMutation = ref6.mutate, createAddressError = ref6.error, isSuccessCreateAddress = ref6.isSuccess, isErrorCreateAddress = ref6.isError, isLoadingCreateAddress = ref6.isLoading, createdAddressData = ref6.data;
    var ref7 = useLinkContactAndCustomer(), linkContactMutate = ref7.mutate, linkContactError = ref7.error, isSuccessLinkContact = ref7.isSuccess, isErrorLinkContact = ref7.isError, isLoadingLinkContact = ref7.isLoading;
    var callLinkContact = function() {
        var addressId = createdAddressData === null || createdAddressData === void 0 ? void 0 : createdAddressData.id;
        linkContactMutate(addressId);
    };
    var checkIndividual = function() {
        var individual = getFromLocalStorageByKey("individual");
        return !!individual;
    };
    useEffect(function() {
        if (isLoadingPostDelivery) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    postDelivery: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        error: undefined
                    }
                });
            });
        } else if (isErrorPostDelivery) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    postDelivery: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: errorMock("postDelivery", postDeliveryError)
                    }
                });
            });
        } else if (isSuccessPostDelivery) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    postDelivery: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    }
                });
            });
            resetData(queryClient, [
                "deliveryDetailsIsEvolvedMobile"
            ]);
        }
    }, [
        isErrorPostDelivery,
        isLoadingPostDelivery,
        isSuccessPostDelivery
    ]);
    useEffect(function() {
        if (isLoadingDeliveryEvolved) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    deliveryEvolved: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        error: undefined
                    }
                });
            });
        } else if (isErrorDeliveryEvolved) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    deliveryEvolved: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: errorMock("deliveryEvolved", deliveryEvolvedError)
                    }
                });
            });
        } else if (isSuccessDeliveryEvolved) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    deliveryEvolved: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    }
                });
            });
            mutatePostDelivery({
                shippingAddress: deliveryPayload === null || deliveryPayload === void 0 ? void 0 : deliveryPayload.shippingAddress,
                pickupPoint: deliveryPayload === null || deliveryPayload === void 0 ? void 0 : deliveryPayload.pickupPoint
            });
        }
    }, [
        isSuccessDeliveryEvolved,
        isErrorDeliveryEvolved,
        isLoadingDeliveryEvolved
    ]);
    useEffect(function() {
        if ((deliveryPayload === null || deliveryPayload === void 0 ? void 0 : deliveryPayload.shippingAddress) || (deliveryPayload === null || deliveryPayload === void 0 ? void 0 : deliveryPayload.pickupPoint)) {
            refetchDeliveryEvolved();
        }
    }, [
        deliveryPayload
    ]);
    useEffect(function() {
        if (isLoadingLinkContact) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    linkContact: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        error: undefined
                    }
                });
            });
        } else if (isErrorLinkContact) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    linkContact: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: errorMock("linkContact", linkContactError)
                    }
                });
            });
        } else if (isSuccessLinkContact) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    linkContact: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    },
                    postDelivery: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        error: undefined
                    }
                });
            });
            setDeliveryPayload({
                shippingAddress: createdAddressData,
                pickupPoint: pickupPoint
            });
        }
    }, [
        isSuccessLinkContact,
        isErrorLinkContact,
        isLoadingLinkContact
    ]);
    useEffect(function() {
        if (isLoadingIndividual) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    individual: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        error: undefined
                    }
                });
            });
        } else if (isErrorIndivdual) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    individual: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: errorMock("individual", individualError)
                    }
                });
            });
        } else if (isSuccessIndividual && isSuccessCreateAddress && !isSuccessPostDelivery && !isLoadingPostDelivery) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    linkContact: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        error: undefined
                    },
                    individual: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false,
                        error: undefined
                    }
                });
            });
            callLinkContact();
        }
    }, [
        isSuccessIndividual,
        isErrorIndivdual,
        isLoadingIndividual
    ]);
    useEffect(function() {
        if (isLoadingCreateAddress) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    createAddress: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        error: undefined
                    }
                });
            });
        } else if (isErrorCreateAddress) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    createAddress: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: errorMock("createAddress", createAddressError)
                    }
                });
            });
        } else if (isSuccessCreateAddress) {
            if (checkIndividual()) {
                setPostReturn(function(prevState) {
                    return _object_spread_props(_object_spread({}, prevState), {
                        linkContact: {
                            isLoading: true,
                            isError: false,
                            error: undefined,
                            isSuccess: false
                        },
                        individual: {
                            isLoading: false,
                            isSuccess: true,
                            isError: false,
                            error: undefined
                        },
                        createAddress: {
                            isLoading: false,
                            isSuccess: true,
                            isError: false,
                            error: undefined
                        }
                    });
                });
                callLinkContact();
            } else {
                setPostReturn(function(prevState) {
                    return _object_spread_props(_object_spread({}, prevState), {
                        individual: {
                            isLoading: true,
                            isSuccess: false,
                            isError: false,
                            error: undefined
                        },
                        createAddress: {
                            isLoading: false,
                            isSuccess: true,
                            isError: false,
                            error: undefined
                        }
                    });
                });
                refetchIndividual();
            }
        }
    }, [
        isSuccessCreateAddress,
        isErrorCreateAddress,
        isLoadingCreateAddress
    ]);
    useEffect(function() {
        if (isLoadingValidateAddress) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    validateAddress: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false,
                        error: undefined
                    }
                });
            });
        } else if (isErrorValidateAddress) {
            setPostReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    validateAddress: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: errorMock("validateAddress", validateAddressError)
                    }
                });
            });
        } else if (isSuccessValidateAddress) {
            var ref;
            if (!(validatedShippingAddress === null || validatedShippingAddress === void 0 ? void 0 : (ref = validatedShippingAddress.validAddressList) === null || ref === void 0 ? void 0 : ref[0])) {
                setPostReturn(function(prevState) {
                    return _object_spread_props(_object_spread({}, prevState), {
                        validateAddress: {
                            isLoading: false,
                            isSuccess: false,
                            isError: true,
                            error: errorMock("validateAddress")
                        }
                    });
                });
            } else {
                var ref1;
                setPostReturn(function(prevState) {
                    return _object_spread_props(_object_spread({}, prevState), {
                        validateAddress: {
                            isLoading: false,
                            isSuccess: true,
                            isError: false,
                            error: undefined
                        }
                    });
                });
                createAddressMutation({
                    address: validatedShippingAddress === null || validatedShippingAddress === void 0 ? void 0 : (ref1 = validatedShippingAddress.validAddressList) === null || ref1 === void 0 ? void 0 : ref1[0],
                    isDelivery: true
                });
            }
        }
    }, [
        isSuccessValidateAddress,
        isErrorValidateAddress,
        isLoadingValidateAddress
    ]);
    var checkCustomerShippingAddress = function() {
        var customerAddressInDeliveryByCoords = getAddressValidatedInDeliveryByCoords(deliveryBase, shippingAddress);
        if ((pickupPoint === null || pickupPoint === void 0 ? void 0 : pickupPoint.storeId) || customerAddressInDeliveryByCoords) {
            setDeliveryPayload({
                shippingAddress: customerAddressInDeliveryByCoords,
                pickupPoint: pickupPoint
            });
        } else {
            validateAddressMutation({
                placeObject: shippingAddress,
                isDelivery: true
            });
        }
    };
    useEffect(function() {
        if (!checkBypassApiCallFromSIA()) {
            checkCustomerShippingAddress();
        }
    }, []);
    return postReturn;
};
