import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { errorMock, useCampaignAccept, useCampaignsInfo, useGetShoppingCartSilentUpdate } from "@vfit/consumer/data-access";
import { usePageProvider } from "@vfit/consumer/providers";
import { useCheckout } from "../../../iBuyFixed.context";
import { getEligibilityProduct } from "./useLockInMMFlow.utils";
/**
 * Lockin Flow
 * campaigns
 * accept
 * getCart
 */ export var useLockInMMFlow = function() {
    var ref, ref1;
    var page = usePageProvider().page;
    var allProducts = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || [];
    var ref2 = useState(""), acceptOrderId = ref2[0], setAcceptOrderId = ref2[1];
    var ref3 = useCheckout(), product = ref3.product, isStartLockInMMFlow = ref3.isStartLockInMMFlow, lockInMMFlow = ref3.lockInMMFlow, setLockInMMFlow = ref3.setLockInMMFlow, setIsStartLockInMMFlow = ref3.setIsStartLockInMMFlow;
    // if the product is lockin, calls do not need to be repeated
    var alreadyFetchedInfo = product === null || product === void 0 ? void 0 : product.isLockInMMProduct;
    var ref4 = useCampaignsInfo(), campaignsData = ref4.data, campaignsError = ref4.error, isLoadingCampaigns = ref4.isLoading, isSuccessCampaigns = ref4.isSuccess, isErrorCampaigns = ref4.isError, refetchCampaigns = ref4.refetch;
    var ref5 = useCampaignAccept(), acceptData = ref5.data, acceptError = ref5.error, isLoadingAccept = ref5.isLoading, isSuccessAccept = ref5.isSuccess, isErrorAccept = ref5.isError, mutateAccept = ref5.mutate;
    var ref6 = useGetShoppingCartSilentUpdate(acceptOrderId, {
        enabled: false,
        keyDependency: [
            "getShoppingCartLockIn"
        ]
    }), getShoppingCartData = ref6.data, getShoppingCartError = ref6.error, isLoadingShoppingCart = ref6.isLoading, isSuccessShoppingCart = ref6.isSuccess, isErrorShoppingCart = ref6.isError, refetchShoppingCart = ref6.refetch;
    useEffect(function() {
        if (isLoadingShoppingCart) {
            setLockInMMFlow(_object_spread_props(_object_spread({}, lockInMMFlow), {
                shoppingCart: _object_spread_props(_object_spread({}, lockInMMFlow.shoppingCart), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorShoppingCart) {
            setLockInMMFlow(_object_spread_props(_object_spread({}, lockInMMFlow), {
                shoppingCart: _object_spread_props(_object_spread({}, lockInMMFlow.shoppingCart), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("shoppingCart", getShoppingCartError)
                })
            }));
        } else if (isSuccessShoppingCart) {
            setLockInMMFlow(_object_spread_props(_object_spread({}, lockInMMFlow), {
                shoppingCart: _object_spread_props(_object_spread({}, lockInMMFlow.shoppingCart), {
                    data: getShoppingCartData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                })
            }));
        }
    }, [
        isSuccessShoppingCart,
        isErrorShoppingCart,
        isLoadingShoppingCart
    ]);
    useEffect(function() {
        if (acceptOrderId) refetchShoppingCart();
    }, [
        acceptOrderId
    ]);
    useEffect(function() {
        if (isLoadingAccept) {
            setLockInMMFlow(_object_spread_props(_object_spread({}, lockInMMFlow), {
                accept: _object_spread_props(_object_spread({}, lockInMMFlow.accept), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorAccept) {
            setLockInMMFlow(_object_spread_props(_object_spread({}, lockInMMFlow), {
                accept: _object_spread_props(_object_spread({}, lockInMMFlow.accept), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("accept", acceptError)
                })
            }));
        } else if (isSuccessAccept) {
            setLockInMMFlow(_object_spread_props(_object_spread({}, lockInMMFlow, !alreadyFetchedInfo && {
                shoppingCart: {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    data: undefined,
                    error: undefined
                }
            }), {
                accept: _object_spread_props(_object_spread({}, lockInMMFlow.accept), {
                    data: acceptData,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                })
            }));
            setAcceptOrderId((acceptData === null || acceptData === void 0 ? void 0 : acceptData.orderId) || "");
        }
    }, [
        isSuccessAccept,
        isErrorAccept,
        isLoadingAccept
    ]);
    useEffect(function() {
        if (isLoadingCampaigns) {
            setLockInMMFlow(_object_spread_props(_object_spread({}, lockInMMFlow), {
                campaigns: _object_spread_props(_object_spread({}, lockInMMFlow.campaigns), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false,
                    error: undefined
                })
            }));
        } else if (isErrorCampaigns) {
            setLockInMMFlow(_object_spread_props(_object_spread({}, lockInMMFlow), {
                campaigns: _object_spread_props(_object_spread({}, lockInMMFlow.campaigns), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("campaigns", campaignsError)
                })
            }));
        } else if (isSuccessCampaigns && !alreadyFetchedInfo) {
            var ref = getEligibilityProduct(allProducts, campaignsData), campaignEligibility = ref.campaignEligibility, lockInProduct = ref.lockInProduct;
            var isEligibleOffer = (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : campaignEligibility.eligible) && (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : campaignEligibility.lockInOffer) && lockInProduct;
            setLockInMMFlow(_object_spread_props(_object_spread({}, lockInMMFlow, isEligibleOffer && {
                accept: {
                    isLoading: true,
                    data: undefined,
                    error: undefined,
                    isError: false,
                    isSuccess: false
                }
            }), {
                campaigns: _object_spread_props(_object_spread({}, lockInMMFlow.campaigns), {
                    data: campaignsData,
                    error: undefined,
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                }),
                checkEligibility: _object_spread({}, campaignEligibility, lockInProduct && {
                    lockInProduct: _object_spread_props(_object_spread({}, lockInProduct), {
                        isLockInMMProduct: true
                    })
                })
            }));
            if (isEligibleOffer) {
                var ref1, ref2;
                mutateAccept({
                    campaignId: (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : (ref1 = campaignEligibility.lockInOffer) === null || ref1 === void 0 ? void 0 : ref1.id) || "",
                    sessionId: (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : (ref2 = campaignEligibility.lockInOffer) === null || ref2 === void 0 ? void 0 : ref2.sessionId) || ""
                });
            }
        }
    }, [
        isSuccessCampaigns,
        isErrorCampaigns,
        isLoadingCampaigns
    ]);
    useEffect(function() {
        if (isStartLockInMMFlow) {
            setIsStartLockInMMFlow(false);
        }
    }, [
        isStartLockInMMFlow
    ]);
    useEffect(function() {
        if (isStartLockInMMFlow && !alreadyFetchedInfo) {
            refetchCampaigns();
        }
    }, [
        isStartLockInMMFlow
    ]);
    return null;
};
