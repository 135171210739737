import { useTracking } from "@vfit/shared/providers";
import { setTrackLink } from "@vfit/shared/data-access";
import { getUserInfo, getTagging } from "../../../../checkout.utils";
export var useVoucherInsertionCardTagging = function() {
    var taggingMobile = getTagging("voucher input");
    var trackView = useTracking({
        event: [
            "checkout_step8.2"
        ],
        event_label: "insert voucher",
        opts: taggingMobile === null || taggingMobile === void 0 ? void 0 : taggingMobile.opts,
        cartProduct: taggingMobile === null || taggingMobile === void 0 ? void 0 : taggingMobile.trackingProduct,
        pageProduct: taggingMobile === null || taggingMobile === void 0 ? void 0 : taggingMobile.pageProductsInfo,
        visitorTrackingOpts: getUserInfo()
    }).trackView;
    setTrackLink(trackView, "voucher input");
    return null;
};
