var ref;
import { ENCODE_VALUE_PREFIX, getFromLocalStorageByKey, isJSON } from "@vfit/shared/data-access";
import { errorManager, ErrorService } from "@vfit/shared/providers";
import { CONTEXT_KEY } from "./iBuyMobile.context.data";
export var OVERRIDE_DEBUG_MODE = ((ref = process.env) === null || ref === void 0 ? void 0 : ref["NX_ENABLE_IBUY_MOBILE_DEBUG"]) === "TRUE" || false;
export var ENABLE_DEBUG = OVERRIDE_DEBUG_MODE || "production" === "development";
var retrieveProduct = function() {
    var isReset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    var product = getFromLocalStorageByKey("tProd");
    var product_backUp = getFromLocalStorageByKey("tProd_backUp");
    if (isReset && product_backUp) {
        localStorage.setItem("tProd", JSON.stringify(product_backUp));
        localStorage.removeItem("tProd_backUp");
        return product_backUp || null;
    }
    return product || null;
};
var getProduct = function(page) {
    var isReset = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var ref, ref1;
    return retrieveProduct(isReset) || (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.product);
};
/**
 * This method is used to get the serialized context in local storage in base64
 */ var getSerializeContext = function() {
    var serializedContext = localStorage.getItem(CONTEXT_KEY);
    if (!serializedContext) return null;
    var valueSplitted = serializedContext === null || serializedContext === void 0 ? void 0 : serializedContext.split(ENCODE_VALUE_PREFIX);
    var valueToAnalyze = (valueSplitted === null || valueSplitted === void 0 ? void 0 : valueSplitted.length) > 1 ? decodeURIComponent(atob(valueSplitted === null || valueSplitted === void 0 ? void 0 : valueSplitted[1])) || "" : null;
    if (valueToAnalyze && isJSON(valueToAnalyze)) return JSON.parse(valueToAnalyze);
    return valueToAnalyze;
};
var productNotFound = function() {
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: "Ops!",
        message: "Si \xe8 verificato un errore durante il recupero del prodotto. Riprova pi\xf9 tardi",
        actionEvent: function() {
            localStorage.removeItem("shoppingCart");
            window.location.reload();
        },
        actionText: "Riprova",
        disableTrack: true
    });
};
export { getProduct, getSerializeContext, productNotFound, retrieveProduct };
