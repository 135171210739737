import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 0 20px;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 492px;\n  gap: 16px;\n\n  @media (min-width: ",
        ") {\n    padding: 0 40px;\n    height: 737px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 0 74px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 30px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  font-style: normal;\n  text-align: center;\n  ",
        "\n  color: #00697C;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  font-style: normal;\n  text-align: center;\n  ",
        "\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-75ad46ae-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var TextLoaderEmpty = styled.div.withConfig({
    componentId: "sc-75ad46ae-1"
})(_templateObject1(), breakpoints.tablet);
export var TextContainer = styled.div.withConfig({
    componentId: "sc-75ad46ae-2"
})(_templateObject2());
export var Title = styled.div.withConfig({
    componentId: "sc-75ad46ae-3"
})(_templateObject3(), fonts.exbold, pxToCssFont(35, 38));
export var Subtitle = styled.div.withConfig({
    componentId: "sc-75ad46ae-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(24, 28));
