import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  body {\n    overflow: hidden;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n  }\n\n  header {\n    display: none;\n  }\n\n  #sticky-offer-product {\n    display: none;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  overflow-y: scroll;\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n  scrollbar-width: none;\n\n  ::-webkit-scrollbar {\n    display: none; /* for Chrome, Safari, and Opera */\n  }\n\n  top: 0;\n  z-index: 3;\n  width: 100%;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle } from "styled-components";
export var OverlayGlobalStyle = createGlobalStyle(_templateObject());
export var CheckoutModalContainer = styled.div.withConfig({
    componentId: "sc-12130238-0"
})(_templateObject1());
export var LoadingPage = styled.div.withConfig({
    componentId: "sc-12130238-1"
})(_templateObject2());
