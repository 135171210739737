import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { checkIsGoBackSIA, errorMock, useModifyMobileSimDeviceService } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
import { ECodeIdentification } from "@vfit/shared/models";
import { resetData } from "@vfit/shared/providers";
import { useQueryClient } from "react-query";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
import { useDeliveryBaseFlow } from "../UseDeliveryBaseFlow/useDeliveryBaseFlow";
export var useCustomerDeliveryBase = function() {
    var ref, ref1;
    var ref2 = useCheckout(), product = ref2.product, isLoadingCart = ref2.isLoadingCart, customerId = ref2.customerId, isStartRecognition = ref2.isStartRecognition, isStartDeliveryBase = ref2.isStartDeliveryBase, simTypeSelectedOption = ref2.simTypeSelectedOption, customerDeliveryBaseInfo = ref2.customerDeliveryBaseInfo, simOptions = ref2.simOptions, setIsStartDeliveryBase = ref2.setIsStartDeliveryBase, setCustomerDeliveryBaseInfo = ref2.setCustomerDeliveryBaseInfo;
    var queryClient = useQueryClient();
    var ref3 = useState(false), isStartDelivery = ref3[0], setIsStartDelivery = ref3[1];
    var ref4 = useState(""), simTypeSelectedOptionPosted = ref4[0], setSimTypeSelectedOptionPosted = ref4[1];
    var selectedSimOption = simOptions === null || simOptions === void 0 ? void 0 : (ref = simOptions.simOptions) === null || ref === void 0 ? void 0 : ref.find(function(simOption) {
        return simOption === null || simOption === void 0 ? void 0 : simOption.selection;
    });
    var ref5 = useDeliveryBaseFlow({
        enabled: isStartDelivery,
        isEsim: (selectedSimOption === null || selectedSimOption === void 0 ? void 0 : (ref1 = selectedSimOption.value) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) === "esim" || false
    }), isDeliverySuccess = ref5.isSuccess, isDeliveryLoading = ref5.isLoading, isDeliveryError = ref5.isError, deliveryErrorMessage = ref5.errorMessage, deliveryError = ref5.error, deliveryOptions = ref5.deliveryOptions, identificationDetails = ref5.identificationDetails;
    var ref6 = useModifyMobileSimDeviceService(), modifySimError = ref6.error, mutateModifySim = ref6.mutate, isLoadingModifySim = ref6.isLoading, isSuccessModifySim = ref6.isSuccess, isErrorModifySim = ref6.isError;
    /**
   * Save delivery, first get. Enable VR only in ABtest
   */ var saveDeliveryState = function() {
        var ref;
        var identificationDetailsFiltered = [];
        var IS_ENABLED_VR = product === null || product === void 0 ? void 0 : product.enableVR;
        if (IS_ENABLED_VR) {
            identificationDetailsFiltered = identificationDetails;
        } else if (Array.isArray(identificationDetails)) {
            identificationDetailsFiltered = identificationDetails === null || identificationDetails === void 0 ? void 0 : identificationDetails.filter(function(el) {
                return (el === null || el === void 0 ? void 0 : el.code) !== ECodeIdentification.VR;
            });
        } else if (((ref = identificationDetails) === null || ref === void 0 ? void 0 : ref.code) === ECodeIdentification.VR) {
            identificationDetailsFiltered = [];
        }
        setCustomerDeliveryBaseInfo(_object_spread_props(_object_spread({}, customerDeliveryBaseInfo), {
            deliveryBase: _object_spread_props(_object_spread({}, customerDeliveryBaseInfo.deliveryBase), {
                isLoading: isDeliveryLoading,
                isSuccess: isDeliverySuccess,
                isError: isDeliveryError,
                errorMessage: deliveryErrorMessage,
                error: deliveryError,
                deliveryOptions: deliveryOptions,
                identificationDetails: identificationDetailsFiltered
            })
        }));
    };
    var successModifySim = function() {
        var isWithoutModifySim = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        var ref;
        setSimTypeSelectedOptionPosted((simTypeSelectedOption === null || simTypeSelectedOption === void 0 ? void 0 : simTypeSelectedOption.id) || "");
        setCustomerDeliveryBaseInfo(_object_spread_props(_object_spread({}, customerDeliveryBaseInfo), {
            deliveryBase: _object_spread_props(_object_spread({}, customerDeliveryBaseInfo.deliveryBase), {
                isSuccess: isWithoutModifySim && (customerDeliveryBaseInfo === null || customerDeliveryBaseInfo === void 0 ? void 0 : (ref = customerDeliveryBaseInfo.deliveryBase) === null || ref === void 0 ? void 0 : ref.isSuccess),
                isError: false,
                error: undefined,
                // this loading is true because after modify in called delivery base
                // and this loading is for remove lag for cards
                isLoading: true
            }),
            modifySimDevice: {
                error: undefined,
                isLoading: false,
                isError: false,
                isSuccess: true
            }
        }));
    };
    var updateSimTypeSelectionDefault = function() {
        var ref, ref1, ref2, ref3;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var simProductCharacteristics = (shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.sim) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.mobileSIMProduct) === null || ref3 === void 0 ? void 0 : ref3[0].productCharacteristic) || [];
        var simSKU = simProductCharacteristics.find(function(el) {
            var ref;
            return (el === null || el === void 0 ? void 0 : (ref = el.displayName) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "sku";
        });
        if (simSKU === null || simSKU === void 0 ? void 0 : simSKU.value) setSimTypeSelectedOptionPosted(simSKU.value);
    };
    useEffect(function() {
        if (isStartDeliveryBase) setIsStartDeliveryBase(false);
    }, [
        isStartDeliveryBase
    ]);
    useEffect(function() {
        if (isStartDelivery) setIsStartDelivery(false);
    }, [
        isStartDelivery
    ]);
    useEffect(function() {
        saveDeliveryState();
    }, [
        isDeliveryError,
        isDeliverySuccess,
        isDeliveryLoading,
        deliveryOptions,
        identificationDetails, 
    ]);
    useEffect(function() {
        var ref;
        if (customerId && (customerDeliveryBaseInfo === null || customerDeliveryBaseInfo === void 0 ? void 0 : (ref = customerDeliveryBaseInfo.modifySimDevice) === null || ref === void 0 ? void 0 : ref.isSuccess)) setIsStartDelivery(true);
    }, [
        customerId,
        customerDeliveryBaseInfo,
        isStartDeliveryBase
    ]);
    useEffect(function() {
        if (isLoadingModifySim) {
            setCustomerDeliveryBaseInfo(_object_spread_props(_object_spread({}, customerDeliveryBaseInfo), {
                modifySimDevice: {
                    error: undefined,
                    isError: false,
                    isSuccess: false,
                    isLoading: true
                }
            }));
        } else if (isErrorModifySim) {
            setCustomerDeliveryBaseInfo(_object_spread_props(_object_spread({}, customerDeliveryBaseInfo), {
                modifySimDevice: {
                    error: errorMock("modifySim", modifySimError),
                    isLoading: false,
                    isError: true,
                    isSuccess: false
                }
            }));
        } else if (isSuccessModifySim) {
            resetData(queryClient, [
                "deliveryDetailsIsBaseMobile"
            ]);
            successModifySim();
        }
    }, [
        isLoadingModifySim,
        isSuccessModifySim,
        isErrorModifySim
    ]);
    useEffect(function() {
        if (isStartRecognition && simTypeSelectedOption) {
            if (simTypeSelectedOptionPosted !== simTypeSelectedOption.id) mutateModifySim({
                simOption: simTypeSelectedOption
            });
            else {
                successModifySim(true);
            }
        }
    }, [
        isStartRecognition
    ]);
    useEffect(function() {
        if (!isLoadingCart && !checkIsGoBackSIA()) {
            updateSimTypeSelectionDefault();
        }
    }, [
        isLoadingCart
    ]);
    return null;
};
