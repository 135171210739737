import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 40px;\n  margin-top: 16px;\n  font-family: ",
        ";\n  font-weight: bold;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;margin-bottom: 40px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n\n  @media (max-width: ",
        ") {\n    position: absolute;\n    bottom: 10px;\n    max-width: 80%;\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    justify-content: center;\n\n    div {\n      width: 300px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  .rec {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 8px;\n\n    .rec {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-top: 8px;\n    margin-bottom: 8px;\n\n    p,\n    span {\n      ",
        "\n      color: ",
        ";\n      margin:0;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin: 0;\n      margin-top: 4px;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ListDiv = styled.div.withConfig({
    componentId: "sc-deb24463-0"
})(_templateObject(), fonts.exbold);
export var BottomContainer = styled.div.withConfig({
    componentId: "sc-deb24463-1"
})(_templateObject1());
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-deb24463-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.tablet);
export var ParagraphContainer = styled.p.withConfig({
    componentId: "sc-deb24463-3"
})(_templateObject3());
export var Title = styled(ParagraphContainer).withConfig({
    componentId: "sc-deb24463-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(30, 38), colors.$262626, breakpoints.desktop, pxToCssFont(36, 45));
export var OfferPrice = styled(Title).withConfig({
    componentId: "sc-deb24463-5"
})(_templateObject5(), pxToCssFont(24, 28), breakpoints.desktop, pxToCssFont(24, 30));
export var OfferDescription = styled.div.withConfig({
    componentId: "sc-deb24463-6"
})(_templateObject6(), pxToCssFont(16, 22), colors.$262626, breakpoints.desktop);
