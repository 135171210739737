import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  overflow-y: scroll;\n  top: 0;\n  z-index: 2;\n  width: 100%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var CheckOrderModalContainer = styled.div.withConfig({
    componentId: "sc-9de62555-0"
})(_templateObject());
