import { useEffect } from "react";
import { checkIsGoBackSIA, COOKIE_CART, useDeleteShoppingCartMutation } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey, getItemCookie, setItemCookie } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
export var useCookieDeleteCart = function() {
    var isLoadingCart = useCheckout().isLoadingCart;
    var ref = useDeleteShoppingCartMutation(), deleteShoppingCartMutation = ref.mutate, isLoadingDeleteCart = ref.isLoading, isSuccessDeleteCart = ref.isSuccess;
    var createCartCookie = function() {
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartCookie = {
            cartId: shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id,
            token: sessionStorage.getItem("uxfauthorization")
        };
        setItemCookie(COOKIE_CART, cartCookie, 2, false, "privati.vodafone.it");
    };
    var checkCartCookie = function() {
        var cookie = getItemCookie(COOKIE_CART);
        if (!cookie || cookie && !(cookie === null || cookie === void 0 ? void 0 : cookie.cartId) || cookie && !(cookie === null || cookie === void 0 ? void 0 : cookie.token)) {
            createCartCookie();
        } else {
            var shoppingCart = getFromLocalStorageByKey("shoppingCart");
            var currentCartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
            if ((cookie === null || cookie === void 0 ? void 0 : cookie.cartId) && currentCartId && (cookie === null || cookie === void 0 ? void 0 : cookie.cartId) !== currentCartId && !isSuccessDeleteCart && !isLoadingDeleteCart) {
                deleteShoppingCartMutation({
                    shoppingCartId: cookie.cartId,
                    customOptions: {
                        headers: {
                            Authorization: cookie === null || cookie === void 0 ? void 0 : cookie.token
                        }
                    }
                });
                setItemCookie(COOKIE_CART, "", 0, false, "privati.vodafone.it");
                createCartCookie();
            }
        }
    };
    useEffect(function() {
        if (!isLoadingCart && !checkIsGoBackSIA()) {
            checkCartCookie();
        }
    }, [
        isLoadingCart
    ]);
    return null;
};
