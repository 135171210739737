import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { useSearchPickUpLocations, useLocateStore, hasPostalOfficeOption, hasPickupStoreOption } from "@vfit/consumer/data-access";
import { IAddressType } from "@vfit/shared/models";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
var DEFAULT_DELIVERY_LOCATE_STORE = {
    startDeliveryLocateAndPickupPoint: function() {},
    global: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    searchPickupLocations: {
        isLoading: false,
        isError: false,
        isSuccess: false
    },
    searchLocateStore: {
        isLoading: false,
        isError: false,
        isSuccess: false
    },
    searchPostalOffice: {
        isLoading: false,
        isError: false,
        isSuccess: false
    }
};
export var useDeliveryLocateAndPickupPoint = function() {
    var ref = useState(DEFAULT_DELIVERY_LOCATE_STORE), useDeliveryLocateAndPickupPointResult = ref[0], setUseDeliveryLocateAndPickupPointResult = ref[1];
    var ref1 = useState(false), isSuccessPickupPoint = ref1[0], setIsSuccessPickupPoint = ref1[1];
    var ref2 = useState(false), isSuccessPostalOffice = ref2[0], setIsSuccessPostalOffice = ref2[1];
    var ref3 = useState(false), isSuccessLocateStore = ref3[0], setIsSuccessLocateStore = ref3[1];
    var ref4 = useSearchPickUpLocations(), searchPickupLocationMutation = ref4.mutate, isSuccessSearchPickupLocationApi = ref4.isSuccess, isLoadingSearchPickupLocation = ref4.isLoading, isErrorSearchPickupLocation = ref4.isError;
    var ref5 = useSearchPickUpLocations(), searchPostalOfficeMutation = ref5.mutate, isSuccessPostalOfficeApi = ref5.isSuccess, isLoadingPostalOffice = ref5.isLoading, isErrorPostalOffice = ref5.isError;
    var ref6 = useLocateStore(), locateStoreMutation = ref6.mutate, isSuccessLocateStoreApi = ref6.isSuccess, isLoadingLocateStore = ref6.isLoading, isErrorLocateStore = ref6.isError;
    var initRetrieveData = function() {
        var ref, ref1, ref2;
        var deliveryAdvance = getFromLocalStorageByKey("deliveryDetailsIsAdvance");
        var deliveryDetails = (deliveryAdvance === null || deliveryAdvance === void 0 ? void 0 : (ref = deliveryAdvance[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.deliveryItems) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.details) || [];
        var searchPostalOfficeData = getFromLocalStorageByKey("searchPickUpLocations");
        var searchPickUpPointData = getFromLocalStorageByKey("searchPickUpStoreLocations");
        var locateStoreData = getFromLocalStorageByKey("locateStore");
        if (!deliveryDetails || (deliveryDetails === null || deliveryDetails === void 0 ? void 0 : deliveryDetails.length) === 0) {
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    global: _object_spread_props(_object_spread({}, prevState.global), {
                        isLoading: false,
                        isError: false,
                        isSuccess: true
                    })
                });
            });
        }
        if (hasPostalOfficeOption(deliveryDetails) && !searchPostalOfficeData) {
            if (!isSuccessPostalOfficeApi) {
                searchPostalOfficeMutation({
                    options: IAddressType.PO
                });
            }
        } else {
            setIsSuccessPostalOffice(true);
        }
        if (hasPickupStoreOption(deliveryDetails) && !searchPickUpPointData) {
            if (!isSuccessSearchPickupLocationApi) {
                searchPickupLocationMutation({
                    options: IAddressType.PP
                });
            }
        } else {
            setIsSuccessPickupPoint(true);
        }
        if (hasPickupStoreOption(deliveryDetails) && !locateStoreData) {
            if (!isSuccessLocateStoreApi) {
                locateStoreMutation(undefined);
            }
        } else {
            setIsSuccessLocateStore(true);
        }
    };
    useEffect(function() {
        if (isSuccessPickupPoint && isSuccessLocateStore && isSuccessPostalOffice) {
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    global: _object_spread_props(_object_spread({}, prevState.global), {
                        isSuccess: true,
                        isLoading: false,
                        isError: false
                    })
                });
            });
        }
    }, [
        isSuccessPickupPoint,
        isSuccessPostalOffice,
        isSuccessLocateStore
    ]);
    useEffect(function() {
        if (isLoadingPostalOffice) {
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    searchPostalOffice: _object_spread_props(_object_spread({}, prevState.searchPostalOffice), {
                        isLoading: true
                    })
                });
            });
        } else if (isErrorPostalOffice) {
            setIsSuccessPostalOffice(true);
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    searchPostalOffice: _object_spread_props(_object_spread({}, prevState.searchPostalOffice), {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    })
                });
            });
        } else if (isSuccessPostalOfficeApi) {
            setIsSuccessPostalOffice(true);
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    searchPostalOffice: _object_spread_props(_object_spread({}, prevState.searchPostalOffice), {
                        isLoading: false,
                        isError: false,
                        isSuccess: true
                    })
                });
            });
        }
    }, [
        isErrorPostalOffice,
        isLoadingPostalOffice,
        isSuccessPostalOfficeApi
    ]);
    useEffect(function() {
        if (isLoadingSearchPickupLocation) {
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    searchPickupLocations: _object_spread_props(_object_spread({}, prevState.searchPickupLocations), {
                        isLoading: true
                    })
                });
            });
        } else if (isErrorSearchPickupLocation) {
            setIsSuccessPickupPoint(true);
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    searchPickupLocations: _object_spread_props(_object_spread({}, prevState.searchPickupLocations), {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    })
                });
            });
        } else if (isSuccessSearchPickupLocationApi) {
            setIsSuccessPickupPoint(true);
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    searchPickupLocations: _object_spread_props(_object_spread({}, prevState.searchPickupLocations), {
                        isLoading: false,
                        isError: false,
                        isSuccess: true
                    })
                });
            });
        }
    }, [
        isErrorSearchPickupLocation,
        isLoadingSearchPickupLocation,
        isSuccessSearchPickupLocationApi, 
    ]);
    useEffect(function() {
        if (isLoadingLocateStore) {
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    searchLocateStore: _object_spread_props(_object_spread({}, prevState.searchLocateStore), {
                        isLoading: true
                    })
                });
            });
        } else if (isErrorLocateStore) {
            setIsSuccessLocateStore(true);
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    searchLocateStore: _object_spread_props(_object_spread({}, prevState.searchLocateStore), {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    })
                });
            });
        } else if (isSuccessLocateStoreApi) {
            setIsSuccessLocateStore(true);
            setUseDeliveryLocateAndPickupPointResult(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    searchLocateStore: _object_spread_props(_object_spread({}, prevState.searchLocateStore), {
                        isLoading: false,
                        isError: false,
                        isSuccess: true
                    })
                });
            });
        }
    }, [
        isErrorLocateStore,
        isLoadingLocateStore,
        isSuccessLocateStoreApi
    ]);
    useEffect(function() {
        if (useDeliveryLocateAndPickupPointResult.global.isLoading) {
            initRetrieveData();
        }
        if (useDeliveryLocateAndPickupPointResult.global.isSuccess) {
            setIsSuccessLocateStore(false);
            setIsSuccessPickupPoint(false);
            setIsSuccessPostalOffice(false);
        }
    }, [
        useDeliveryLocateAndPickupPointResult.global
    ]);
    var startDeliveryLocateAndPickupPoint = function() {
        setUseDeliveryLocateAndPickupPointResult(_object_spread_props(_object_spread({}, useDeliveryLocateAndPickupPointResult), {
            global: {
                isError: false,
                isSuccess: false,
                isLoading: true
            }
        }));
    };
    return _object_spread_props(_object_spread({}, useDeliveryLocateAndPickupPointResult), {
        startDeliveryLocateAndPickupPoint: startDeliveryLocateAndPickupPoint
    });
};
