var organizePortabilityNumberCard = function(portabilityNumberCardCMS) {
    var keyPortability = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "portability";
    var ref, ref1, ref2, ref3, ref4, ref5;
    return {
        title: (portabilityNumberCardCMS === null || portabilityNumberCardCMS === void 0 ? void 0 : (ref = portabilityNumberCardCMS[keyPortability]) === null || ref === void 0 ? void 0 : (ref1 = ref.telephone) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        description: (portabilityNumberCardCMS === null || portabilityNumberCardCMS === void 0 ? void 0 : (ref2 = portabilityNumberCardCMS[keyPortability]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.telephone) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        label: (portabilityNumberCardCMS === null || portabilityNumberCardCMS === void 0 ? void 0 : (ref4 = portabilityNumberCardCMS[keyPortability]) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.selectInput) === null || ref5 === void 0 ? void 0 : ref5.telephone) || ""
    };
};
var organizePortabilityError = function(portabilityNumberCardCMS) {
    var ref, ref1, ref2, ref3;
    return {
        titleError: (portabilityNumberCardCMS === null || portabilityNumberCardCMS === void 0 ? void 0 : (ref = portabilityNumberCardCMS.portabilitymobile) === null || ref === void 0 ? void 0 : (ref1 = ref.errorModal) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        messageError: (portabilityNumberCardCMS === null || portabilityNumberCardCMS === void 0 ? void 0 : (ref2 = portabilityNumberCardCMS.portabilitymobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.errorModal) === null || ref3 === void 0 ? void 0 : ref3.message) || ""
    };
};
export { organizePortabilityError, organizePortabilityNumberCard };
