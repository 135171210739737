import { replaceShippingCost } from "@vfit/consumer/data-access";
export var organizeRecognitionSimCourier = function(data, isEsim, price, isPortability) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    var cmsConfig = isEsim ? data === null || data === void 0 ? void 0 : (ref = data.personalinfomobile) === null || ref === void 0 ? void 0 : ref.recognitionCourierEsim : data === null || data === void 0 ? void 0 : (ref1 = data.personalinfomobile) === null || ref1 === void 0 ? void 0 : ref1.recognitionCourier;
    return {
        data: {
            title: replaceShippingCost((cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.title) || "", price),
            description: replaceShippingCost((cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.description) || "", price),
            textBold: replaceShippingCost(isPortability ? cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.textMnp : (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.textBold) || "", price),
            text: replaceShippingCost((cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.text) || "", price)
        },
        list: {
            title: replaceShippingCost((cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref2 = cmsConfig.list) === null || ref2 === void 0 ? void 0 : ref2[0].title) || "", price),
            items: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref3 = cmsConfig.list) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.elements) || [
                {
                    text: "",
                    icon: ""
                }, 
            ],
            portabilityItems: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref5 = cmsConfig.list) === null || ref5 === void 0 ? void 0 : ref5[0].portability) || [
                {
                    text: "",
                    icon: ""
                }, 
            ]
        }
    };
};
