import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { CMS_CONFIG, errorMock, SELECTED_PAYMENT, useAuthorizationOrder, useCmsConfig, useGetPaymean, useLightCreditVettingMutation, useNotify, usePaymentDistributionFixed, useReserveEquipmentMutation, useSubmitFixedMutation, useSubmitWithPaymentFixed, useValidateIbansFixed } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { EMethodCode, Path } from "@vfit/shared/models";
import { API, getFromLocalStorageByKey, PAGES } from "@vfit/shared/data-access";
import { retrieveProduct } from "../../../iBuyFixed.utils";
import { useCheckout } from "../../../iBuyFixed.context";
// API FLOW
// Authorization
// Notify
// Paymean
// Payment distribution
// validate iban only if is IBAN
// reserve equipment
// light credit vetting
// submit / submitWithPayment
export var useCheckoutFlow = function(param) {
    var ignoreQueryString = param.ignoreQueryString;
    var queryString = window.location.search;
    var product = retrieveProduct();
    var ref = useCheckout(), portability = ref.portability, portabilityStatus = ref.portabilityStatus, setPortabilityStatus = ref.setPortabilityStatus;
    var globalConfig = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG);
    // setting internal hook state
    var ref1 = useState(false), isSuccess = ref1[0], setIsSuccess = ref1[1];
    var ref2 = useState(true), isLoading = ref2[0], setIsLoading = ref2[1];
    var ref3 = useState(false), isError = ref3[0], setIsError = ref3[1];
    var ref4 = useState({
        errorMessage: "",
        error: undefined
    }), error = ref4[0], setError = ref4[1];
    var queryClient = useQueryClient();
    var authorizationError = queryClient.getQueryData("authorizationError");
    var paymentDistributionError = queryClient.getQueryData("paymentDistribution");
    var reserveEquipmentError = queryClient.getQueryData("reserveEquipment");
    var lightCreditVettingError = queryClient.getQueryData("lightCreditVetting");
    var validateIbanError = queryClient.getQueryData("validateIbansQuery");
    var submitError = queryClient.getQueryData("submitQuery");
    // (1) calling hook mutation
    var ref5 = useAuthorizationOrder(), authorizationOrderData = ref5.data, isSuccessAuthorizationOrder = ref5.isSuccess, isErrorAuthorizationOrder = ref5.isError, isLoadingAuthorizationOrder = ref5.isLoading;
    var ref6 = useValidateIbansFixed(), isLoadingValidateIban = ref6.isLoading, mutateValidateIban = ref6.mutate, isErrorValidateIban = ref6.isError, isSuccessValidateIban = ref6.isSuccess;
    var ref7 = useGetPaymean(undefined, globalConfig), isErrorPayMean = ref7.isError, isLoadingPayMean = ref7.isLoading;
    // (1) calling notify
    var notifyMutation = useNotify();
    // (2) calling hook mutation
    var paymentDistributionMutation = usePaymentDistributionFixed();
    var selectedPaymentMethod = localStorage.getItem(SELECTED_PAYMENT);
    // (3) calling hook mutation
    var reserveEquipmentMutation = useReserveEquipmentMutation();
    // (4) calling hook mutation or lightCreditVettingMutation
    var lightCreditVettingMutation = useLightCreditVettingMutation();
    // (6) calling hook mutation final
    var submitCCMutation = useSubmitFixedMutation();
    var submitWithPaymentMutation = useSubmitWithPaymentFixed();
    var submitMutation = (product === null || product === void 0 ? void 0 : product.enableRecurringPayment) ? submitWithPaymentMutation : submitCCMutation;
    var isIban = (selectedPaymentMethod === null || selectedPaymentMethod === void 0 ? void 0 : selectedPaymentMethod.toLowerCase()) === EMethodCode.BANK_ACCOUNT.toLowerCase();
    /**
   * PaymentDistribution mutation initializer conditioned by authorizationOrderData
   */ useEffect(function() {
        LoggerInstance.debug("queryString", queryString);
        if ((queryString === null || queryString === void 0 ? void 0 : queryString.includes(Path.URL_FORWARD)) || ignoreQueryString) {
            if (isSuccessAuthorizationOrder && !isLoadingAuthorizationOrder) {
                if (authorizationError === null || authorizationError === void 0 ? void 0 : authorizationError.error) {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setIsError(true);
                    setError(authorizationError);
                } else {
                    LoggerInstance.debug("useCheckoutFlow authorizationOrderData : ", authorizationOrderData);
                    LoggerInstance.debug("useCheckoutFlow calling paymentDistributionMutation.mutate()");
                    notifyMutation.mutate();
                    paymentDistributionMutation.mutate();
                }
            } else if (isErrorPayMean && !isLoadingPayMean || isErrorAuthorizationOrder && !isLoadingAuthorizationOrder) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                var paymeanError = getFromLocalStorageByKey("payMean");
                setError(paymeanError);
            }
        }
    }, [
        isLoadingAuthorizationOrder,
        isErrorAuthorizationOrder,
        isSuccessAuthorizationOrder,
        isErrorPayMean, 
    ]);
    useEffect(function() {
        if (isErrorValidateIban) {
            setIsLoading(false);
            setIsSuccess(false);
            setIsError(true);
            setError(validateIbanError);
        } else if (isSuccessValidateIban) {
            reserveEquipmentMutation.mutate();
        }
    }, [
        isSuccessValidateIban,
        isLoadingValidateIban,
        isErrorValidateIban
    ]);
    /**
   * ReserveEquipment mutation initializer conditioned by paymentDistributionMutation.data
   */ useEffect(function() {
        if (!paymentDistributionMutation.isLoading) {
            if (paymentDistributionMutation.isSuccess) {
                var ref;
                if ((ref = paymentDistributionMutation.data) === null || ref === void 0 ? void 0 : ref.error) {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setIsError(true);
                    setError(paymentDistributionMutation.data);
                } else if (isIban) {
                    mutateValidateIban();
                } else {
                    reserveEquipmentMutation.mutate();
                }
            } else if (paymentDistributionMutation.isError && (paymentDistributionError === null || paymentDistributionError === void 0 ? void 0 : paymentDistributionError.error)) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                setError(paymentDistributionError);
            }
        }
    }, [
        paymentDistributionMutation.isLoading,
        paymentDistributionMutation.isSuccess,
        paymentDistributionMutation.isError,
        paymentDistributionError, 
    ]);
    /**
   * handlerPaymentType hook conditioned by reserveEquipmentMutation.data
   */ useEffect(function() {
        if (!reserveEquipmentMutation.isLoading) {
            if (reserveEquipmentMutation.isSuccess) {
                var ref;
                if ((reserveEquipmentMutation === null || reserveEquipmentMutation === void 0 ? void 0 : (ref = reserveEquipmentMutation.data) === null || ref === void 0 ? void 0 : ref.status) === "KO") {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setIsError(true);
                    setError(errorMock("reserveEquipment", {
                        status: "200",
                        statusText: "KO",
                        url: API.RESERVE_EQUIPMENT
                    }, "KO"));
                } else {
                    lightCreditVettingMutation.mutate();
                }
            } else if (reserveEquipmentMutation.isError && reserveEquipmentError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                setError(reserveEquipmentError);
            }
        }
    }, [
        reserveEquipmentMutation.isSuccess,
        reserveEquipmentMutation.isError,
        reserveEquipmentMutation.isLoading,
        reserveEquipmentError, 
    ]);
    /**
   * LightCreditVetting
   */ useEffect(function() {
        if (!lightCreditVettingMutation.isLoading) {
            if (lightCreditVettingMutation.isSuccess) {
                LoggerInstance.debug("useCheckoutFlow lightCreditVettingMutation.data", lightCreditVettingMutation.data);
                if ((portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isSuccessCheck) && (portability === null || portability === void 0 ? void 0 : portability.portabilityWant)) {
                    setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                        isStartFinalPortIn: true
                    }));
                } else if (!(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isError)) {
                    submitMutation.mutate();
                }
            } else if (lightCreditVettingMutation.isError && lightCreditVettingError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                setError(lightCreditVettingError);
            }
        }
    }, [
        lightCreditVettingMutation.isLoading,
        lightCreditVettingMutation.isSuccess,
        lightCreditVettingMutation.isError,
        lightCreditVettingError,
        portability, 
    ]);
    useEffect(function() {
        if (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isStartFinalPortIn) {
            if ((portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isSuccess) && (lightCreditVettingMutation === null || lightCreditVettingMutation === void 0 ? void 0 : lightCreditVettingMutation.isSuccess) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isSuccess) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isLoading) && !(submitMutation === null || submitMutation === void 0 ? void 0 : submitMutation.isError)) {
                submitMutation.mutate();
            } else if (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                setError({
                    errorMessage: portabilityStatus.errorMessage
                });
            }
        }
    }, [
        portabilityStatus,
        lightCreditVettingMutation === null || lightCreditVettingMutation === void 0 ? void 0 : lightCreditVettingMutation.isSuccess
    ]);
    /**
   * After Submit hook to finalize the flow
   * setting internal state isLoading=false && isSuccess=true
   */ useEffect(function() {
        if (!submitMutation.isLoading) {
            if (submitMutation.isSuccess) {
                setIsError(false);
                setIsLoading(false);
                setIsSuccess(true);
            } else if (submitMutation.isError && submitError) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                setError(submitError);
            }
        }
    }, [
        submitMutation.isSuccess,
        submitMutation.isLoading,
        submitMutation.isError,
        submitError
    ]);
    return {
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,
        error: error
    };
};
