import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { useAuthorizeMutation, errorMock } from "@vfit/consumer/data-access";
import { DEFAULT_AUTHORIZE_AUTHORIZATION_FLOW } from "../../../iBuyFixed.context.data";
export var useAuthorizeAuthorizationFlow = function(selectedItem, siaFlowType, authorizationOperationMethod) {
    var ref = useState(DEFAULT_AUTHORIZE_AUTHORIZATION_FLOW), authorizeAuthorizationFlow = ref[0], setAuthorizeAuthorizationFlow = ref[1];
    var ref1 = useState(false), isStartAuthorizeAuthorization = ref1[0], setIsStartAuthorizeAuthorization = ref1[1];
    var ref2 = useAuthorizeMutation(), authorizeError = ref2.error, isLoadingAuthorize = ref2.isLoading, isErrorAuthorize = ref2.isError, isSuccessAuthorize = ref2.isSuccess, authorizeMutation = ref2.mutate, resetAuthorize = ref2.reset;
    useEffect(function() {
        if (isLoadingAuthorize) {
            setAuthorizeAuthorizationFlow(_object_spread_props(_object_spread({}, authorizeAuthorizationFlow), {
                authorize: _object_spread_props(_object_spread({}, authorizeAuthorizationFlow.authorize), {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorAuthorize) {
            setAuthorizeAuthorizationFlow(_object_spread_props(_object_spread({}, authorizeAuthorizationFlow), {
                authorize: _object_spread_props(_object_spread({}, authorizeAuthorizationFlow.authorize), {
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    error: errorMock("authorize", authorizeError)
                })
            }));
        } else if (isSuccessAuthorize) {
            setAuthorizeAuthorizationFlow(_object_spread_props(_object_spread({}, authorizeAuthorizationFlow), {
                authorize: _object_spread_props(_object_spread({}, authorizeAuthorizationFlow.authorize), {
                    isLoading: false,
                    isSuccess: true,
                    isError: false
                })
            }));
        }
    }, [
        isSuccessAuthorize,
        isLoadingAuthorize,
        isErrorAuthorize
    ]);
    var startFlow = function() {
        authorizeMutation(_object_spread({
            payMeanType: selectedItem || ""
        }, authorizationOperationMethod && {
            authorizationOperation: authorizationOperationMethod
        }, siaFlowType && {
            siaFlowType: siaFlowType
        }));
    };
    useEffect(function() {
        if (isStartAuthorizeAuthorization) {
            setIsStartAuthorizeAuthorization(false);
            startFlow();
        }
    }, [
        isStartAuthorizeAuthorization
    ]);
    useEffect(function() {
        if (selectedItem && !isStartAuthorizeAuthorization) {
            resetAuthorize();
            setIsStartAuthorizeAuthorization(true);
        }
    }, [
        selectedItem
    ]);
    return authorizeAuthorizationFlow;
};
